import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DataShareService } from "src/app/shared/api/datashare.service";
import { PricesheetTypeService } from "src/app/shared/api/pricesheettype.service";
import { PricesheetType } from "src/app/shared/Models/pricesheetType";

@Component({
    selector: 'pricesheet-dialog',
    templateUrl: 'standard-pricesheet-dialog.html'
})
export class StandardPricesheetDialog {
    name: string = '';
    date: any;
    dialogData = <any>{};

    constructor(private datePipe: DatePipe, public dialogRef: MatDialogRef<any>, private pricesheetTypeService: PricesheetTypeService, private data: DataShareService) {
        this.date = new Date();
        this.date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    }

    save() {
        this.dialogData = { name: this.name, date: this.date };
        this.dialogRef.close(this.dialogData);
    }

    close() {
        this.dialogRef.close();
    }

}

export interface DialogDataNew {
    name: string;
    date: Date;
    type: PricesheetType
}