import { Injectable } from "@angular/core";
import { ProductPrice } from "../Models/productPrice";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})
export class ProductPriceService {
    constructor(private api: ApiService) {}

async getProductPricesByCustomerId(productPrice: ProductPrice){
    return await this.api.post("/productprice/GetByCustomerId", productPrice).toPromise();
}    

async save(productPrice: ProductPrice){
    return await this.api.post("/productprice/saveorupdate", productPrice).toPromise();
}

async saveAll(productPrices: any[]){
    return await this.api.post("/productprice/saveorupdateAll", productPrices).toPromise();
}

async saveOrUpdateCustomerProducts(productPrices: any[]){
    return await this.api.post("/productprice/saveOrUpdateCustomerProducts", productPrices).toPromise();
}

}