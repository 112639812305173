<div style="display:flex; flex-direction: row; align-items: flex-start;">
    <div style="width:65%; margin:1%;display:flex;flex-direction: column;">
        <div style="display:flex; justify-content: space-between; margin:1%; align-items: center;">
            <h2 style="margin:0px;" class="header">{{'OMEX' | translate}}</h2>
            <button mat-raised-button style="background-color: var(--primary-blue); color:white; border-radius: 10px;" (click)="newOmex()">Ny Omex</button>
            <button mat-raised-button (click)="saveAll()"
                style="border-radius: 10px; background-color: var(--primary-blue); color: white;">
                {{'SAVEALL' | translate}}
            </button>
        </div>
        <div style="border-radius: 6px;" class="mat-elevation-z20 table-responsive">
            <table style="width:100%;" mat-table [dataSource]="omexesDataSource">
                <!-- TERMINAL ↓ -->
                <ng-container matColumnDef="terminal">
                    <th class="centText" mat-header-cell *matHeaderCellDef>{{'TERMINAL'|translate}}
                    </th>
                    <td class="centText" mat-cell *matCellDef="let omex" [ngClass]="{'chosenOmex': omex == activeOmex}">
                        <mat-form-field style="width:150;" floatLabel='never'>
                            <mat-label>{{'TERMINAL' | translate}}</mat-label>
                            <mat-select (ngModelChange)="checkData(omex)" [(ngModel)]="omex.terminal" name="terminal"
                                [compareWith]="compareByOptionId">
                                <mat-option *ngFor="let term of terminals" [value]="term">
                                    {{term.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </ng-container>
                <!-- PICKUP ↓ -->
                <ng-container matColumnDef="pickup">
                    <th class="centText" mat-header-cell *matHeaderCellDef>{{'PICKUP'|translate}}</th>
                    <td class="centText" mat-cell *matCellDef="let omex" [ngClass]="{'chosenOmex': omex == activeOmex}">
                        <input placeholder="Afhentning" type="text" matInput (ngModelChange)="checkData(omex)"
                            [(ngModel)]="omex.pickup">
                    </td>
                </ng-container>
                <!-- VIA ↓ -->
                <ng-container matColumnDef="via">
                    <th class="centText" mat-header-cell *matHeaderCellDef>{{'VIA'|translate}}</th>
                    <td class="centText" mat-cell *matCellDef="let omex" [ngClass]="{'chosenOmex': omex == activeOmex}">
                        <input placeholder="Via" type="text" matInput (ngModelChange)="checkData(omex)"
                            [(ngModel)]="omex.via">
                    </td>
                </ng-container>
                <!-- DELIVERY ↓ -->
                <ng-container matColumnDef="delivery">
                    <th class="centText" mat-header-cell *matHeaderCellDef>{{'DELIVERY'|translate}}</th>
                    <td class="centText" mat-cell *matCellDef="let omex" [ngClass]="{'chosenOmex': omex == activeOmex}">
                        <input placeholder="Levering" type="text" matInput (ngModelChange)="checkData(omex)"
                            [(ngModel)]="omex.delivery">
                    </td>
                </ng-container>
                <!-- DELETE/ACTION ↓ -->
                <ng-container matColumnDef="action">
                    <th class="centText" mat-header-cell *matHeaderCellDef></th>
                    <td class="centText" mat-cell *matCellDef="let omex" [ngClass]="{'chosenOmex': omex == activeOmex}">
                        <div *ngIf="!omex.id || omex.editable">
                            <button matTooltip="Gem Omex"
                                style="border-radius: 10px;background-color: var(--primary-blue); color:white;"
                                mat-raised-button (click)="updateOmex(omex)">
                                <mat-icon>save</mat-icon>
                            </button>
                        </div>
                        <div *ngIf="omex.id && !omex.editable">
                            <button matTooltip="Slet Omex" style="border-radius: 10px;" mat-raised-button color="warn"
                                (click)="deleteOmex(omex)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
                <tr style="background-color:var(--primary-blue);" mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky:true;"></tr>
                <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
                    (click)="handleOmex(row)"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 15, 25]" showFirstLastButtons aria-label="Vælg Side"></mat-paginator>
        </div>
    </div>
    <div
        style="display:flex; flex-direction: column; min-width:35%; max-width:35%; align-items: stretch; margin-top:30px; overflow-y:auto;">
        <div style="display:flex; flex-direction: row; justify-content: space-between; align-items: flex-start;">
            <h2 class="header">{{'OMEXPRODUCTS' | translate}}</h2>
            <button mat-raised-button *ngIf="activeOmex" (click)="saveOmexProducts()"
                style="border-radius: 10px;background-color: var(--primary-blue); color:white;">{{'SAVEOMEXPRODUCTS' |
                translate}}</button>
        </div>
        <div style="display:flex; flex-direction: row; justify-content: center;">
            <mat-form-field appearance="outline" style="width:400px;" *ngIf="activeOmex">
                <mat-label>{{'OMEXPRODUCTS' | translate}}</mat-label>
                <mat-select>
                    <mat-option *ngFor="let prod of products" (click)="selectProduct(prod)">
                        {{prod.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div style="display:flex; flex-direction: column; align-items: center; max-height: 45vh;" *ngIf="activeOmex">
            <ol class="ol-cards">
                <li *ngFor="let omexProd of activeOmexProducts" style="--accent-color: #f44336">
                    <div class="icon" *ngIf="omexProd.id" style="cursor:pointer"><i class="fas fa-trash-alt"
                            (click)="removeOmexProduct(omexProd)"></i></div>
                    <div class="icon" style="font-size:20px;" *ngIf="!omexProd.id" style="cursor:pointer"></div>
                    <div class="title">
                        <h2 [ngClass]="{'newlyAdded': omexProd.id == null}">{{omexProd.product!.name}}</h2>
                    </div>
                    <mat-form-field class="descr">
                        <mat-label>{{'CUSTOMER' | translate}}</mat-label>
                        <mat-select [(ngModel)]="omexProd.customer" name="customer" disableOptionCentering
                            panelClass="testClass">
                            <mat-option *ngFor="let cust of customers" (click)="selectCustomer(cust, omexProd)"
                                [value]="cust">
                                {{cust.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </li>
            </ol>
        </div>
    </div>
</div>

<app-mat-spinner-overlay *ngIf="loading === true" [overlay]="true">
</app-mat-spinner-overlay>