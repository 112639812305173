import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PricesheetZone, PricesheetZoneViewModel } from '../Models/pricesheetZone';
import { Results } from '../Models/results';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PricesheetzonesService {

  private API = environment.api_url;
  private root_API = this.API + "/pricesheetzone";

  constructor(private http: HttpClient, private api: ApiService) { }

  getPricesheetZones(pricesheetId: number, pricesheetPrice?: PricesheetZone): Observable<Results> {
    const headers = { 'Authorization': 'E5B93DDF-CA5C-4C4B-975B-9BBDD95DD2D0' };
    const body = pricesheetPrice;
    return this.http.post(this.root_API + "/getbypricesheet?pricesheetId=" + pricesheetId, body, { headers });
  }

  getPricesheetZonesView(pricesheetId: number, pricesheetPrice?: PricesheetZoneViewModel): Observable<Results> {
    const headers = { 'Authorization': 'E5B93DDF-CA5C-4C4B-975B-9BBDD95DD2D0' };
    const body = pricesheetPrice;
    return this.http.post(this.root_API + "/getallview?pricesheetId=" + pricesheetId, body, { headers });
  }


  async postPriceSheetZone(pricesheetZone: any){
    return await this.api.post("/pricesheetzone", pricesheetZone).toPromise();
  }

  async saveOrUpdate(pricesheetZones: any){
    return await this.api.post("/pricesheetzone/saveorupdate", pricesheetZones).toPromise();
  }

  async saveOrUpdateStandard(pricesheetZones: any){
    return await this.api.post("/pricesheetzone/saveorupdatestandard", pricesheetZones).toPromise();
  }

  async getAll(pricesheetZone: any){
    return await this.api.post("/pricesheetzone/getall", pricesheetZone).toPromise();
  }


  async putPriceSheetZone(priceSheetZone: any){
    return await this.api.put("/pricesheetzone", priceSheetZone).toPromise();
  }
}
