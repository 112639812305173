<div class="outerRow">
    <div class="col">
        <div class="innerRow">
            <form [formGroup]="formGroup!" style="margin-left:2%;">
                <mat-form-field style="width:23vw; margin-top:1%;" appearance="outline">
                    <mat-label>{{'SEARCHCUSTOMER' | translate}}</mat-label>
                    <input class="juicyText" type="text" autofocus aria-label="Kunde" matInput
                        formControlName="customer" [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let cust of (customers$ | async)" [value]="(cust.number + ' | ' + cust.name)"
                            (click)="selectCustomer(cust)">
                            {{cust.number}} - {{cust.name}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon class="primBlue" matSuffix>account_circle</mat-icon>
                </mat-form-field>
            </form>
        </div>
    </div>
    <div class="col">
        <div *ngIf="selectedCustomer">
            <div *ngFor="let pricesheet of pricesheets" class="btnPlacement">
                <button mat-raised-button (click)="togglePricesheet(pricesheet)"
                    [ngClass]="{'selectedPricesheet': pricesheet.checked}" class="toggleBtn">{{pricesheet.name}}
                    <mat-icon class="white" *ngIf="pricesheet.checked">check_circle_outline</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div style="display:flex;flex-direction: column; margin-right:2%;" *ngIf="selectedCustomer">
        <div style="display:flex; flex-direction: row;">
        <button style="background-color: var(--primary-blue); color:white;" mat-raised-button (click)="toggleAllOff()" *ngIf="checkedPricesheets()">Ryd valgte</button>
        <button style="background-color: var(--primary-blue); color:white;" mat-raised-button (click)="toggleAllOn()" *ngIf="!checkedPricesheets()">Tilføj alle</button>
    </div>
    </div>
    <div class="col">
        <div *ngIf="selectedCustomer">
            <mat-form-field style="width:14vw; margin-top:1%;" appearance="outline">
                <mat-label>{{'CHOOSEOILGROUP' | translate}}</mat-label>
                <input class="juicyText" type="text" autofocus aria-label="Kunde" matInput [matAutocomplete]="autoOil">
                <mat-autocomplete autoActiveFirstOption #autoOil="matAutocomplete">
                    <mat-option *ngFor="let oilg of oilGroups" [value]="oilg.name" (click)="selectOilGroup(oilg)">
                        {{oilg.name}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon class="largeIcon" matSuffix><i class='fas fa-oil-can'></i>
                </mat-icon>
            </mat-form-field>
            <mat-form-field style="width:14vw;" appearance="outline">
                <mat-label>{{'CAPACITYTAX' | translate}}</mat-label>
                <input autocomplete="off" matInput type="number" [(ngModel)]="selectedCapacityTax!.procent" />
                <mat-icon class="icon" matSuffix><i class="fas fa-percent"></i>
                </mat-icon>
            </mat-form-field>
        </div>
    </div>
    <div class="lastCol">
        <div *ngIf="selectedCustomer">
            <mat-form-field style="margin-top:1%; width:14vw;" appearance="outline">
                <mat-label>{{'CURRENCY' | translate}}</mat-label>
                <input class="juicyText" type="text" autofocus [(ngModel)]="selectedCurrency.isocode" aria-label="Kunde"
                    matInput [matAutocomplete]="autoCurrency">
                <mat-autocomplete autoActiveFirstOption #autoCurrency="matAutocomplete">
                    <mat-option *ngFor="let curr of currencies" [value]="curr.isocode" (click)="selectCurrency(curr)">
                        {{curr.isocode}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon class="largeIcon" matSuffix><i class="far fa-money-bill-alt"></i></mat-icon>
            </mat-form-field>
            <mat-form-field style="width:14vw;" appearance="outline">
                <mat-label>{{'ENVIRONMENTALTAX' | translate}}</mat-label>
                <input autocomplete="off" matInput type="number" [(ngModel)]="environmentalTax" />
                <mat-icon class="icon" matSuffix><i class="fas fa-percent"></i>
                </mat-icon>
            </mat-form-field>
        </div>
    </div>
    <div style="display:flex; flex-direction: column;">
        <div *ngIf="selectedCustomer">
            <button mat-raised-button (click)="saveAll()"
                [disabled]="selectedCurrency == null || selectedCustomer == null" class="saveBtn">{{'SAVE' |
                translate}}</button>
        </div>
    </div>
</div>