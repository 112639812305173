import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColliTypeService } from '../shared/api/collitype.service';
import { CountryService } from '../shared/api/country.service';
import { CurrencyService } from '../shared/api/currency.service';
import { DataShareService } from '../shared/api/datashare.service';
import { HandlingTypeService } from '../shared/api/handlingtype.service';
import { MeasureUnitService } from '../shared/api/measure.service';
import { PriceCalcUnitService } from '../shared/api/pricecalcunit.service';
import { PricesheetService } from '../shared/api/pricesheet.service';
import { PricesheetTypeService } from '../shared/api/pricesheettype.service';
import { ProductService } from '../shared/api/product.service';
import { ColliType } from '../shared/Models/collitype';
import { Country } from '../shared/Models/country';
import { Currency } from '../shared/Models/currency';
import { Customer } from '../shared/Models/customer';
import { MeasureUnit } from '../shared/Models/measureUnit';
import { PriceCalcUnit } from '../shared/Models/priceCalcUnit';
import { Pricesheet } from '../shared/Models/pricesheet';
import { PricesheetPrice } from '../shared/Models/pricesheetPrice';
import { PricesheetType } from '../shared/Models/pricesheetType';
import { HandlingType, PricesheetZone } from '../shared/Models/pricesheetZone';
import { Product } from '../shared/Models/product';
import Utils from '../shared/util/internalMethodHelper';
import { CustomerSettingsDialog } from './customer-settings-dialog/customer-settings.dialog';
import { NewPriceSheetDialog } from './new-pricesheet-dialog/new-pricesheet.dialog';
import { PriceSheetDialog } from './pricesheet-dialog/pricesheet.dialog';

@Component({
  selector: 'app-pricesheet',
  templateUrl: './pricesheet.component.html',
  styleUrls: ['./pricesheet.component.scss']
})
export class PricesheetComponent implements OnInit, OnChanges {

  @Input() customer: Customer = {};
  customerId: number = this.customer?.id!;
  pricesheets: Pricesheet[] = [];
  selectedPricesheet?: Pricesheet;
  selectedDate: any;
  zoneDates = <any[]>[];
  pricesheetChosen: boolean = false;
  filteredZones: PricesheetZone[] = [];
  countryRequest = <Country>{};
  countries: Country[] = [];
  currencyRequest = <Currency>{};
  currencies: Currency[] = [];
  measureUnits: MeasureUnit[] = [];
  colliTypes: ColliType[] = [];
  collitypeRequest = <ColliType>{};
  pricesheetControl = new FormControl();
  loading = false;
  type: any;
  savePricesheetVariable: any;
  handlingTypes: any;
  products: Product[] = [];
  priceCalcUnits: PriceCalcUnit[] = [];

  constructor(private priceSheetService: PricesheetService,
    public data: DataShareService,
    private dialog: MatDialog,
    private countryService: CountryService,
    private currencyService: CurrencyService,
    private measureUnitService: MeasureUnitService,
    private pricesheetTypeService: PricesheetTypeService,
    private handlingTypeService: HandlingTypeService,
    private productService: ProductService,
    private priceCalcUnitService: PriceCalcUnitService,
    private datePipe: DatePipe,
    private _snackBar: MatSnackBar,
    private colliTypeService: ColliTypeService) { }

  ngOnInit(): void {
  }

  deletePricesheet(pricesheet: Pricesheet) {
    if (pricesheet) {
      this.priceSheetService.deleteCustomerRelation(pricesheet.id!, this.customer.id, pricesheet.licenseRef).then((res: any) => {
        this.getPriceSheets();
        this.selectedPricesheet = undefined;
      }).finally(() => {
        this._snackBar.open("Prisark slettet", "Success", {panelClass: ['snack-bar']});
      });
    }
  }

  addPricesheet() {
    const dialogRef = this.dialog.open(NewPriceSheetDialog);

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        var result = Math.max.apply(Math, this.pricesheets.map(function (o) { return o.priority! }));
        let pricesheet: Pricesheet = {
          name: res.name,
          validFrom: res.date.toString(),
          pricesheetType: res.type,
          priority: (result + 10),
          fk_pricesheetTypeId: res.type.id!,
          fk_customerId: this.customer.id,
          licenseRef: this.data.License
        }
        this.priceSheetService.savePricesheet(pricesheet).then((res: any) => {
          if (res) {
            this.getPriceSheets();
          }
        }).finally(() => {
          this._snackBar.open("Prisark Gemt", "Success", {panelClass: ['snack-bar']});
        });
      }
    });
  }


  createNewPriceSheet() {
    const dialogRef = this.dialog.open(PriceSheetDialog);
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.saveNewPricesheet(this.mapTmpPricesheet(res));
      }
    });
  }

  mapTmpPricesheet(data: any): any {
    if (this.selectedPricesheet) {
      const tmpPricesheet = Object.assign({}, this.selectedPricesheet);
      tmpPricesheet.id = undefined;
      tmpPricesheet.name = data.name;
      tmpPricesheet.validFrom = this.datePipe.transform(data.date, 'yyyy-MM-dd')!;
      tmpPricesheet.customerPricesheets = [
        {
          fk_customerId: this.data.selectedCustomer.id,
          customer: this.data.selectedCustomer,
          fk_pricesheetId: this.selectedPricesheet!.id,
          licenseRef: this.selectedPricesheet!.licenseRef
        }];
      return tmpPricesheet;
    }
  }

  selectPriceSheet(pricesheet: Pricesheet): void {
    this.loading = true;

    if (this.selectedPricesheet != pricesheet) {
      this.data.ZoneDates = [];
    }
    this.selectedPricesheet = pricesheet;
    this.selectedPricesheet.fk_customerId = this.customer.id;

    this.data.selectedPricesheet = this.selectedPricesheet;
    this.getPriceSheetZoneDates();
  }

  openCustomerSettings() {
    if (this.customer) {
      const dialogRef = this.dialog.open(CustomerSettingsDialog, { width: '60vw', height: '77vh', data: this.customer });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {

        }
      });
    }
  }


  chooseDate(date: any) {
    this.data.setSelectedDate(date);
    this.selectedDate = date;
  }

  getPriceSheetZoneDates() {
    this.zoneDates = this.data.ZoneDates;
    this.loading = false;
  }

  saveNewPricesheet(pricesheet: Pricesheet) {
    if (this.data.WorkZones && this.data.WorkPrices) {
      this.savePricesheetVariable = pricesheet;
      this.data.startSerialization();
    }
  }

  savePricesheet() {
    if (this.data.WorkZones && this.data.WorkPrices) {
      this.data.startSerialization();
    }
  }

  mapWorkZones() {
    const tmpZoneArray: PricesheetZone[] = [];
    for (let i = 0; i < this.data.WorkZones.length; i++) {

      // Check Country To 
      if (!Utils.isObject(this.data.WorkZones[i].countryTo)) {
        for (let j = 0; j < this.countries.length; j++) {
          if (this.countries[j].isocode == this.data.WorkZones[i].countryTo) {
            this.data.WorkZones[i].countryTo = this.countries[j];
          }
        }
      }
      // Check Country From
      if (!Utils.isObject(this.data.WorkZones[i].countryFrom)) {
        for (let j = 0; j < this.countries.length; j++) {
          if (this.countries[j].isocode == this.data.WorkZones[i].countryFrom) {
            this.data.WorkZones[i].countryFrom = this.countries[j];
          }
        }
      }
      // Check HandlingType
      if (!Utils.isObject(this.data.WorkZones[i].handlingType)) {
        for (let k = 0; k < this.handlingTypes.length; k++) {
          if (this.handlingTypes[k].name == this.data.WorkZones[i].handlingType) {
            this.data.WorkZones[i].handlingType = this.handlingTypes[k];
          }
        }
      }

      const wZone: PricesheetZone = {
        collitypeCategory: this.data.WorkZones[i].collitypeCategory,
        id: this.data.WorkZones[i].id ? this.data.WorkZones[i].id : null,
        fromZipFrom: this.data.WorkZones[i].fromZipFrom?.toString(),
        fromZipTo: this.data.WorkZones[i].fromZipTo?.toString(),
        toZipFrom: this.data.WorkZones[i].toZipFrom?.toString(),
        toZipTo: this.data.WorkZones[i].toZipTo?.toString(),
        zoneNo: this.data.WorkZones[i].zoneNo?.toString(),
        validFrom: this.data.selectedDate!,
        licenseRef: this.selectedPricesheet?.licenseRef,
        fk_pricesheetId: this.selectedPricesheet?.id,
        fk_countryFromId: this.data.WorkZones[i].countryFrom?.id!,
        fk_countryToId: this.data.WorkZones[i].countryTo?.id!,
        handlingType: this.data.WorkZones[i].handlingType,
      };
      tmpZoneArray.push(wZone);
    }
    return tmpZoneArray;
  }

  mapWorkPrices() {
    const tmpPriceArray: PricesheetPrice[] = [];
    for (let j = 0; j < this.data.WorkPrices.length; j++) {
      // Check Currency
      if (!Utils.isObject(this.data.WorkPrices[j].currency)) {
        for (let k = 0; k < this.currencies.length; k++) {
          if (this.currencies[k].isocode == this.data.WorkPrices[j].currency) {
            this.data.WorkPrices[j].currency = this.currencies[k];
          }
        }
      }
      // Check MeasureUnit
      if (!Utils.isObject(this.data.WorkPrices[j].measureUnit)) {
        for (let a = 0; a < this.measureUnits.length; a++) {
          if (this.measureUnits[a].name == this.data.WorkPrices[j].measureUnit) {
            this.data.WorkPrices[j].measureUnit = this.measureUnits[a];
          }
        }
      }
      // Check Product
      if (!Utils.isObject(this.data.WorkPrices[j].product)) {
        for (let b = 0; b < this.products.length; b++) {
          if (this.products[b].name == this.data.WorkPrices[j].product) {
            this.data.WorkPrices[j].product = this.products[b];
          }
        }
      }

      // Check PriceCalcUnit
      if (!Utils.isObject(this.data.WorkPrices[j].priceCalcUnit)) {
        for (let q = 0; q < this.priceCalcUnits.length; q++) {
          if (this.priceCalcUnits[q].name == this.data.WorkPrices[j].priceCalcUnit) {
            this.data.WorkPrices[j].priceCalcUnit = this.priceCalcUnits[q];
          }
        }
      }

      const wPrice: PricesheetPrice = {
        id: this.data.WorkPrices[j].id ? this.data.WorkPrices[j].id : null,
        collitypeRef: this.deserializeColliType(this.data.WorkPrices[j].collitypeRef),
        licenseRef: this.selectedPricesheet?.licenseRef,
        zoneNo: this.data.WorkPrices[j].zoneNo?.toString(),
        intervalFrom: this.data.WorkPrices[j].intervalFrom,
        intervalTo: this.data.WorkPrices[j].intervalTo,
        price: this.data.WorkPrices[j].price,
        hidePrint: false, // Hardcoded to false as it is required in backend
        validFrom: this.data.selectedDate!,
        fk_pricesheetTypeId: 1,
        fk_pricesheetId: this.selectedPricesheet?.id,
        fk_currencyId: this.data.WorkPrices[j].currency?.id!,
        fk_measureUnitId: this.data.WorkPrices[j].measureUnit?.id!,
        fk_productId: this.data.WorkPrices[j].product?.id!,
        product: this.data.WorkPrices[j].product,
        priceCalcUnit: this.data.WorkPrices[j].priceCalcUnit,
        text: this.data.WorkPrices[j].text
      };
      tmpPriceArray.push(wPrice);
    }
    return tmpPriceArray;
  }


  deserializeColliType(colliRef: any) {
    if (this.data.ColliTypes) {
      for (let i = 0; i < this.data.ColliTypes.length; i++) {
        if (colliRef == this.data.ColliTypes[i].name) {
          return this.data.ColliTypes[i].ref;
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedPricesheet = undefined;
    this.getPriceSheets();
    this.getCountries();
    this.getAllCurrencies();
    this.getMeasureUnits();
    this.getHandlingTypes();
    this.getAllProducts();
    this.getAllPriceCalcUnit();
  }

  getAllProducts() {
    const product: Product = {
      name: "",
      erpCode: "",
      licenseRef: this.data.License,
      fk_productUnitID: 0,
      includeProductPrices: true,
      includeProductUnit: true
    };
    this.productService.getAll(product).then((res: any) => {
      for (let i = 0; i < res.modelObject.length; i++) {
        this.products.push({
          id: res.modelObject[i].id,
          name: res.modelObject[i].name,
          erpCode: res.modelObject[i].erpCode
        });
      }
    });
  }


  // Api Calls
  getPriceSheets(): void {
    if (this.customer && this.customer.licenseRef) {
      let priceSheet: Pricesheet = {
        licenseRef: this.customer.licenseRef,
        includeCustomerPricesheets: true
      }
      this.priceSheetService.getPriceSheetsByCustomer(this.customer.id!, priceSheet).then((res: any) => {
        this.pricesheets = res.modelObject;
        this.data.pricesheets = this.pricesheets;
        this.getDefaultPricesheetType();
      });
    }
  }

  getAllPriceCalcUnit() {
    let priceCalcUnit: PriceCalcUnit = {
      licenseRef: this.data.License,
      name: " "
    };
    this.priceCalcUnitService.getAll(priceCalcUnit).then((res) => {
      this.priceCalcUnits = res.modelObject;

    }).finally(() => {
      this.loading = false;
    });
  }

  getDefaultPricesheetType() {
    let priceSheetType: PricesheetType = {
      licenseRef: this.data.License,
      name: ''
    }
    this.pricesheetTypeService.getAll(priceSheetType).then((res) => {
      for (let i = 0; i < res.modelObject.length; i++) {
        if (res.modelObject[i].name && res.modelObject[i].name == "Customer") {
          this.type = res.modelObject[i];
        }
      }
    });
  }

  getCountries() {
    this.countryRequest.licenseRef = this.data.License;
    this.countryService.getCountries(this.countryRequest).then((res: any) => {
      this.countries = res.modelObject;
      this.countries.sort((a, b) => (a.isocode! > b.isocode!) ? 1 : (a.isocode == b.isocode) ? 0 : -1);
    });
  }

  getAllCurrencies() {
    this.currencyRequest.licenseRef = this.data.License;
    this.currencyService.getCurrencies(this.currencyRequest).then((res) => {
      this.currencies = res.modelObject;
      this.currencies.sort((a: any, b: any) => (a.isocode > b.isocode) ? 1 : (a.isocode == b.isocode) ? 0 : -1);
    });
  }

  getMeasureUnits() {
    var licenseRef = this.data.License;
    this.measureUnitService.getMeasureUnits(licenseRef).subscribe((res: any) => {
      this.measureUnits = res.modelObject;
    });
  }

  getHandlingTypes() {
    let handlingType: HandlingType = {
      licenseRef: this.data.License
    };
    this.handlingTypeService.getAll(handlingType).then((res) => {
      this.handlingTypes = res.modelObject;
    });
  }

  getColliTypes() {
    this.colliTypeService.getAllColliTypes(this.collitypeRequest).then((res: any) => {
      if (res && res.modelObject) {
        this.data.ColliTypes = res.modelObject;
        this.colliTypes = res.modelObject;
        this.colliTypes!.sort((a: any, b: any) => (a.name > b.name) ? 1 : (a.name == b.name) ? 0 : -1);
      }
    }).catch((err) => {
      console.error("ERROR Getting Colli Types: ", err);
    });

  }
}

