import { Injectable } from "@angular/core";
import { OilGroup } from "../Models/oilGroup";
import { OilSheet } from "../Models/oilSheet";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})
export class OilService {
    constructor(private api: ApiService) { }

    async getAllOilSheet(oil: OilSheet) {
        return await this.api.post("/oilsheet/getall", oil).toPromise();
    }

    async getAllOilSheetByCustomer(customerId: number, oil: OilSheet) {
        return await this.api.post("/oilsheet/getallByCustomer?customerId=" + customerId, oil).toPromise();
    }

    async getAllOilGroup(oil: OilGroup) {
        return await this.api.post("/oilgroup/getall", oil).toPromise();
    }

    async getAllOilGroupByCustomer(oil: OilGroup) {
        return await this.api.post("/oilgroup/getallbyCustomer", oil).toPromise();
    }

    async getLatest(oil: OilSheet) {
        return await this.api.post("/oilsheet/getlatest", oil).toPromise();
    }

    async saveOrUpdateOilGroup(oil: OilGroup) {
        return await this.api.post("/oilgroup/saveorupdate", oil).toPromise();
    }

    async saveOrUpdateAllOilSheet(oilSheets: OilSheet[]) {
        return await this.api.post("/oilsheet/saveorupdateall", oilSheets).toPromise();
    }

    async SaveOrUpdateOilSheetByOilGroup(oilGroups: OilGroup[]) {
        return await this.api.post("/oilgroup/saveorupdateoilsheet", oilGroups).toPromise();
    }

    async SaveOrUpdateOilSheet(oilSheet: OilSheet) {
        return await this.api.post("/oilsheet/saveorupdate", oilSheet).toPromise();
    }

    async getAllOilSheetsByCustomer(oil: OilSheet) {
        return await this.api.post("/oilsheet/getallbycustomer", oil).toPromise();
    }

}