<!-- Translate Dialog -->
<h1 mat-dialog-title
    style="background-color: var(--primary-blue); padding:10px; margin:-24px; margin-bottom:10px; justify-content: center; display:flex; color:white;">
    {{'NEWOILGROUP' | translate}}</h1>
<mat-dialog-content fxLayout="column">
    <div fxLayout="row">
        <mat-form-field>
            {{'NAME'| translate}}
            <input [(ngModel)]="name" type="text" matInput />
        </mat-form-field>
    </div>
    <div fxLayout="row">
        <mat-form-field>
            {{'PROCENT'| translate}}
            <input [(ngModel)]="procent" type="number" matInput />
        </mat-form-field>
    </div>
    <div fxLayout="row">
        <mat-form-field>
            {{'ERPCODE'| translate}}
            <input [(ngModel)]="erpCode" type="text" matInput />
        </mat-form-field>
    </div>
    <div fxLayout="row">
        <mat-form-field>
            {{'DATE'| translate}}
            <input [(ngModel)]="date" matInput type="date" />
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between;">
    <button mat-raised-button (click)="close()" style="text-shadow: none;">{{'CLOSE' | translate}}</button>
    <button mat-raised-button [disabled]="name.length == 0 || date == null" matTooltip="Navn og Dato skal være udfyldt"
        style="background-color: var(--primary-blue); color:white;" (click)="save()">{{'CREATE' | translate}}</button> <!-- Translate -->
</mat-dialog-actions>