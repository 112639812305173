<div fxLayout="row" style="width:80%; margin:1%;">
  <div style="display:flex;justify-content: space-between; margin:1%; align-items:center;">
    <h2 style="margin:0px;" class="header">{{'PRODUCTS'|translate}}</h2>
    <mat-form-field style="margin-left:1%; margin-top:1%; width:120px;">
      <mat-label>Vælg en Dato</mat-label>
      <mat-select [value]="selectedDate" style="font-weight:450;">
        <mat-option *ngFor="let date of pricesheetDates" [value]="date" (click)="chooseDate(date)">{{ date |
          date: 'dd/MM/yyyy'}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button style="background-color: var(--primary-blue); color:white; border-radius: 10px;" (click)="newProduct()">Nyt Produkt</button>
    <button mat-raised-button style="background-color: var(--primary-blue); color:white; border-radius: 10px;"
      (click)="saveAll()">{{'SAVEALL' | translate}}</button>
  </div>
  <div style="border-radius: 6px;" class="mat-elevation-z20 table-responsive">
    <table #table mat-table [dataSource]="productpricesDataSource">
      <!-- NAME ↓ -->
      <ng-container matColumnDef="name">
        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'NAME'|translate}}</th>
        <td [matTooltipDisabled]="productPrice != productprices[0]" matTooltipPosition="right"
          matTooltip="Navn skal være udfyldt" style="text-align: center !important;" mat-cell
          *matCellDef="let productPrice" [ngClass]="{'redfield': !productPrice.product!.name}">
          <input (ngModelChange)="checkData(productPrice)" #name placeholder="Navn" type="text" matInput
            [(ngModel)]="productPrice.product!.name">
        </td>
      </ng-container>
      <!-- PRICE ↓ -->
      <ng-container matColumnDef="price">
        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'PRICE'|translate}}</th>
        <td mat-cell style="text-align: center !important;" *matCellDef="let productPrice">
          <input (ngModelChange)="checkData(productPrice)" #price placeholder="Pris" type="number" matInput
            [(ngModel)]="productPrice.price">
        </td>
      </ng-container>
      <!-- MinPrice ↓ -->
      <ng-container matColumnDef="minPrice">
        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'MINPRICE'|translate}}</th>
        <td mat-cell style="text-align: center !important;" *matCellDef="let productPrice">
          <input (ngModelChange)="checkData(productPrice)" #price placeholder="Min. Pris" type="number" matInput
            [(ngModel)]="productPrice.minPrice">
        </td>
      </ng-container>
      <!-- PERCENT ↓ -->
      <ng-container matColumnDef="percent">
        <th style="text-align: center !important;" mat-header-cell *matHeaderCellDef>{{'PERCENT'|translate}}</th>
        <td mat-cell style="text-align: center !important;" *matCellDef="let productPrice">
          <input (ngModelChange)="checkData(productPrice)" #price placeholder="Procent" type="number" matInput
            [(ngModel)]="productPrice.percent">
        </td>
      </ng-container>
      <!-- CURRENCY ↓ -->
      <ng-container matColumnDef="valuta">
        <th style="text-align: center;" mat-header-cell *matHeaderCellDef>{{'CURRENCY'|translate}}</th>
        <td mat-cell *matCellDef="let productPrice">
          <mat-form-field style="width:100px;" floatLabel='never'>
            <mat-label>{{'CURRENCY' | translate}}</mat-label>
            <mat-select (ngModelChange)="checkData(productPrice)" #currency [(ngModel)]="productPrice.currency"
              name="currency" [compareWith]="compareByOptionId">
              <mat-option *ngFor="let curr of currencies" [value]="curr">
                {{curr.isocode}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
      <!-- NUMBER ↓ -->
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef>{{'ERPCODE' | translate}}</th>
        <td [matTooltipDisabled]="productPrice != productprices[0]" matTooltipPosition="right"
          matTooltip="Erp Kode skal være udfyldt" mat-cell *matCellDef="let productPrice"
          [ngClass]="{'redfield': !productPrice.product!.erpCode}">
          <input (ngModelChange)="checkData(productPrice)" #number placeholder="ERP Kode" matInput
            [(ngModel)]="productPrice.product.erpCode">
        </td>
      </ng-container>
      <!-- PRODUCTUNIT ↓ -->
      <ng-container matColumnDef="productunit">
        <th mat-header-cell *matHeaderCellDef>{{'PRODUCTUNIT' | translate}}</th>
        <td [matTooltipDisabled]="productPrice != productprices[0]" matTooltipPosition="right"
          matTooltip="Produkt Enhed skal være udfyldt" mat-cell *matCellDef="let productPrice"
          [ngClass]="{'redfield': !productPrice.product!.productUnit}">
          <mat-form-field style="width:100px;" floatLabel='never'>
            <mat-label>{{'UNIT' | translate}}</mat-label>
            <mat-select (ngModelChange)="checkData(productPrice)" #productunit
              [(ngModel)]="productPrice.product.productUnit" name="productunit" [compareWith]="compareByOptionId">
              <mat-option *ngFor="let pu of productUnits" [value]="pu">
                {{pu.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
      <!-- OIL ↓ -->
      <ng-container matColumnDef="oil">
        <th mat-header-cell *matHeaderCellDef>{{'CALCULATE' | translate}} <u>{{'NOT' | translate}}</u> {{'OIL' |
          translate}}</th>
        <td style="text-align: center;" mat-cell *matCellDef="let productPrice">
          <input (ngModelChange)="checkData(productPrice)" #oil placeholder="Olie" type="checkbox"
            [(ngModel)]="productPrice.product.excludeOilTariff">
        </td>
      </ng-container>
      <!-- ENVIRONMENT ↓ -->
      <ng-container matColumnDef="enviroment">
        <th mat-header-cell *matHeaderCellDef>{{'CALCULATE' | translate}} <u>{{'NOT' | translate}}</u>
          {{'ENVIRONMENTFEE' | translate}}</th>
        <td style="text-align: center;" mat-cell *matCellDef="let productPrice">
          <input (ngModelChange)="checkData(productPrice)" #environment placeholder="Miljø afgift" type="checkbox"
            [(ngModel)]="productPrice.product.excludeEnvironmentalTariff">
        </td>
      </ng-container>
      <!-- CAPACITY ↓ -->
      <ng-container matColumnDef="capacity">
        <th mat-header-cell *matHeaderCellDef>{{'CALCULATE' | translate}} <u>{{'NOT' | translate}}</u> {{'CAPACITYFEE' |
          translate}}</th>
        <td style="text-align: center;" mat-cell *matCellDef="let productPrice">
          <input (ngModelChange)="checkData(productPrice)" #capacity placeholder="Kapacitets tillæg" type="checkbox"
            [(ngModel)]="productPrice.product.excludeCapacityTariff">
        </td>
      </ng-container>
      <!-- FREIGHTLINE ↓ -->
      <ng-container matColumnDef="freightLine">
        <th mat-header-cell *matHeaderCellDef>{{'CALCULATE' | translate}} {{'FROM' | translate}} {{'FREIGHTLINES' |
          translate}} </th>
        <td style="text-align: center;" mat-cell *matCellDef="let productPrice">
          <input (ngModelChange)="checkData(productPrice)" #environment placeholder="Beregn Fragt Linjer?"
            type="checkbox" [(ngModel)]="productPrice.product.calcFromFreightLine">
        </td>
      </ng-container>
      <!-- DELETE/ACTION ↓ -->
      <ng-container matColumnDef="action">
        <th style="text-align: center;" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let productPrice">
          <div *ngIf="productPrice.id && !productPrice.editable">
            <button matTooltip="Slet Produkt" style="border-radius: 10px;" mat-raised-button color="warn"
              (click)="deleteProductPrice(productPrice)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <div *ngIf="!productPrice.id || productPrice.editable">
            <button matTooltip="Gem Produkt"
              style="border-radius: 10px;background-color: var(--primary-blue); color:white;" mat-raised-button
              (click)="applyData(productPrice)">
              <mat-icon>save</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr style="background-color:var(--primary-blue);" mat-header-row
        *matHeaderRowDef="displayedColumns; sticky:true;"></tr>
      <tr class="table-row"  mat-row 
        *matRowDef="let row; columns: displayedColumns; let i = index;"></tr> <!-- !productpricesDataSource.paginator!.hasPreviousPage() -->
    </table>
    <mat-paginator [pageSizeOptions]="[10, 15, 25]" showFirstLastButtons aria-label="Vælg Side"></mat-paginator>
  </div>
</div>

<app-mat-spinner-overlay *ngIf="loading === true" [overlay]="true">
</app-mat-spinner-overlay>