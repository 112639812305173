import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerService } from 'src/app/shared/api/customer.service';
import { DataShareService } from 'src/app/shared/api/datashare.service';
import { EnvironmentalTaxService } from 'src/app/shared/api/environmental-tax.service';
import { Customer } from 'src/app/shared/Models/customer';
import { EnvironmentalTax } from 'src/app/shared/Models/enviromentalTax';
import { ConfirmDialogComponent } from 'src/app/shared/util/confirm-dialog/confirm-dialog.component';
import { NewEnvironmentalTaxDialog } from './new-environmental-tax-dialog/new-environmental-tax.dialog';

@Component({
  selector: 'app-environmental-tax',
  templateUrl: './environmental-tax.component.html',
  styleUrls: ['./environmental-tax.component.scss']
})
export class EnvironmentalTaxComponent implements OnInit {
  displayedColumns: string[] = ["customer", "date", "procent", "erpCode", "action"]; // currency
  environmentalTaxes: EnvironmentalTax[] = [];
  environmentalTaxesDataSource!: MatTableDataSource<EnvironmentalTax>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  customers: Customer[] = [];
  loading: any;
  constructor(private environmentalTaxService: EnvironmentalTaxService, private _snackBar: MatSnackBar, private datePipe: DatePipe, private data: DataShareService, private customerService: CustomerService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllEnvironmentalTaxes();
    this.getCustomers();
  }


  getAllEnvironmentalTaxes() {
    this.loading = true;
    let envTax: EnvironmentalTax = {
      licenseRef: this.data.License
    };
    this.environmentalTaxService.getAll(envTax).then((res: any) => {
      this.environmentalTaxes = res.modelObject;
      for (let i = 0; i < this.environmentalTaxes.length; i++) {
        if(this.environmentalTaxes[i].date){
          this.environmentalTaxes[i].date = this.datePipe.transform(this.environmentalTaxes[i].date, 'yyyy-MM-dd')!;
        }
      }
      // this.environmentalTaxes.unshift({ licenseRef: this.data.License });
      this.environmentalTaxesDataSource = new MatTableDataSource(this.environmentalTaxes);
      this.environmentalTaxesDataSource.paginator = this.paginator;
    }).finally(() => this.loading = false);
  }

  compareByOptionId(refirst: any, refSecond: any) {
    return refirst && refSecond && refirst.id == refSecond.id;
  }

  newEnvironmentalTax(){
    // Open Dialog 
    const dialogRef = this.dialog.open(NewEnvironmentalTaxDialog, {disableClose: true});


    dialogRef.afterClosed().subscribe((envTax: EnvironmentalTax) => {
      if(envTax){
        envTax.licenseRef = this.data.License;
        this.environmentalTaxService.saveOrUpdate(envTax).then((res: any) => {
          if(res){

          }
        }).finally(() => {
          this._snackBar.open("Miljø Tillæg Gemt", "Success", {duration: 1500, panelClass: ['snack-bar']});
          this.getAllEnvironmentalTaxes();
        });
      }
    })
    // Save Env Tax
  }

  saveAll() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "16%",
      height: "18%",
      data: {
        title: "Er du sikker?", // Translate 
        message: "Du er ved at gemme :  <b>Alle Miljø Tillæg</b>" // Translate
      }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.loading = true;
        for (let i = 0; i < this.environmentalTaxesDataSource.data.length; i++) {
          if (this.environmentalTaxesDataSource.data[i].date) {
            this.environmentalTaxesDataSource.data[i].date = this.datePipe.transform(this.environmentalTaxesDataSource.data[i].date, 'yyyy-MM-dd')!;
          }
          if (!this.environmentalTaxesDataSource.data[i].fk_customerId) {
            this.environmentalTaxesDataSource.data.splice(i, 1);
          }
          else {
            if (!this.environmentalTaxesDataSource.data[i].id) {

              this.updateEnvironmentalTax(this.environmentalTaxesDataSource.data[i]);
            }
          }
        }
        this.environmentalTaxService.bulkSaveOrUpdate(this.environmentalTaxesDataSource.data).then((res: any) => {
          if (res) {
            this.getAllEnvironmentalTaxes();
          }
        }).finally(() => {
          this.loading = false;
          this._snackBar.open("Alle Miljø Tillæg Gemt", "Success", {duration: 1000, panelClass: ['snack-bar']});
        });
      }
    });
  }

  checkData(enviromentalTax: any) {
    if (enviromentalTax.id > 0) {
      enviromentalTax.editable = true;
    }
  }


  deleteEnvironmentalTax(environmentalTax: EnvironmentalTax) {
    if (environmentalTax) {
      if (!environmentalTax.licenseRef) {
        environmentalTax.licenseRef = this.data.License;
      }
      this.environmentalTaxService.delete(environmentalTax).then((res: any) => {
        if (res) {
          this.getAllEnvironmentalTaxes();
        }
      }).finally(() => {
        this._snackBar.open("Miljø Tillæg Slettet", "Success", {duration: 1000, panelClass: ['snack-bar']});
      });
    }
  }

  updateEnvironmentalTax(enviromentalTax: any) {
    this.loading = true;
    if (enviromentalTax) {
      if (!enviromentalTax.licenseRef) {
        enviromentalTax.licenseRef = this.data.License;
      }
      if (enviromentalTax.date) {
        enviromentalTax.date = this.datePipe.transform(enviromentalTax.date, 'yyyy-MM-dd')!;

      }
      this.environmentalTaxService.saveOrUpdate(enviromentalTax).then((res: any) => {
        if (res) {
          this.getAllEnvironmentalTaxes();
        }
      }).finally(() => {
        this.loading = false;
        this._snackBar.open("Miljø Tillæg Opdateret", "Success", {duration: 1000, panelClass: ['snack-bar']});
      });
    }
  }

  getCustomers(): void {
    this.loading = true;
    let customer: Customer;
    customer = {
      licenseRef: this.data.License,
      includeReturnCalcMethods: true,
      includeCustomerRules: true,
      includeCustomerPricesheets: true
    };

    this.customerService.getCustomers(customer).then((res: any) => {
      let customers = res.modelObject;
      customers.sort((a: Customer, b: Customer) => {
        if (a.clientObject == undefined && b.clientObject == undefined) return 0;
        if (a.clientObject == undefined) return -1;
        if (b.clientObject == undefined) return 1;
        if ((a.clientObject?.name == undefined || a.clientObject?.name == "") &&
          (b.clientObject?.name == undefined || b.clientObject?.name == "")) return 0;
        if (a.clientObject?.name == undefined || a.clientObject?.name == "") return -1;
        if (b.clientObject?.name == undefined || b.clientObject?.name == "") return -1;
        if (a.clientObject?.name!.toLowerCase() > b.clientObject?.name!.toLowerCase()) {
          return -1;
        }
        if (a.clientObject?.name!.toLowerCase() < b.clientObject?.name!.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      for (let i = 0; i < customers.length; i++) {
        if (customers[i].clientObject?.name) {
          customers[i].name = customers[i].clientObject.name;
        }
      }
      this.customers = customers.sort((a: any, b: any) => { if (a.name > b.name) return 1; if (b.name > a.name) return -1; return 0; });
      this.customers.unshift({ name: 'Ingen valgt' });
    }).finally(() => this.loading = false);
  }



}
