import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'pricesheet-dialog',
    templateUrl: 'new-pricesheet-date.dialog.html'
})
export class NewPricesheetDateDialog {
    date: any;
    saveBtnDisabled = true;
    dialogData = <any>{};
    constructor(public dialogRef: MatDialogRef<NewPricesheetDateDialog>) { }

    save() {
        this.dialogData = { date: this.date };
        this.dialogRef.close(this.dialogData);
    }

    close() {
        this.dialogRef.close();
    }

}
