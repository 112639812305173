import { Component, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app.module';
import { NavigationService } from './shared/api/navigation.service';

// platformBrowserDynamic().bootstrapModule(AppModule, {
//   providers: [{provide: LOCALE_ID, useValue: 'da-DK'}]
// });

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  title = 'Price Engine'; // Translate
  loggedIn: boolean = false;
  constructor(private breakpointObserver: BreakpointObserver, private navigation: NavigationService) {
    this.navigation.startSaveHistory();
    // Fetches browsers language
    // var locale = window.navigator.language; // use to set globalization in angular app.
  }

}
