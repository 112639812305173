import { Injectable } from "@angular/core";
import { Country } from "../Models/country";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(private api: ApiService) { }

  async getCountries(country: Country) {
    return await this.api.post("/country/getall", country).toPromise();
  }

}