import { Injectable } from "@angular/core";
import { ProductUnit } from "../Models/productUnit";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})
export class ProductUnitService {
    constructor(private api: ApiService) {}

    async getAll(productunit: ProductUnit){
        return await this.api.post("/productunit/getall", productunit).toPromise();
    }  

}