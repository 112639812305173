import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private API = environment.api_url;
  constructor(private http: HttpClient) {}

  private formatErrors(error: any) {
    return throwError(error);
  }

  get(path: string): Observable<any> {
    return this.http
      .get(`${this.API}${path}`, this.getOptions())
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http
      .put(`${this.API}${path}`, JSON.stringify(body), this.getOptions())
      .pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http
      .post(`${this.API}${path}`, JSON.stringify(body), this.getOptions())
      .pipe(catchError(this.formatErrors));
  }

  delete(path: any): Observable<any> {
    return this.http
      .delete(`${this.API}${path}`, this.getOptions())
      .pipe(catchError(this.formatErrors));
  }

  getOptions() {
    let httpOptions = {
      headers: new HttpHeaders({Accept: 'application/json', "Content-Type": "application/json", "Authorization": 'E5B93DDF-CA5C-4C4B-975B-9BBDD95DD2D0'}), // Remove hardcoded license...
    };
    return httpOptions;
  }

}