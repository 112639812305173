import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { CustomerSettingsComponent } from './customer-settings/customer-settings.component';
import { CustomerComponent } from './customer/customer.component';
import { LoginComponent } from './login/login.component';
import { CapacityTaxComponent } from './setup/capacity-tax/capacity-tax.component';
import { ColliMeasurementComponent } from './setup/colli-measurement/colli-measurement.component';
import { ColliTypeTranslateComponent } from './setup/colli-type-translate/colli-type-translate.component';
import { EnvironmentalTaxComponent } from './setup/environmental-tax/environmental-tax.component';
import { OilComponent } from './setup/oil/oil.component';
import { OmexComponent } from './setup/omex/omex.component';
import { TerminalComponent } from './setup/terminal/terminal.component';
import { StandardPricesheetComponent } from './standard-pricesheet/standard-pricesheet.component';
import { StandardProductsComponent } from './standard-products/standard-products.component';

// AuthGuard Checks if User Has valid LicenseRef (if NOT send user to login).
const routes: Routes = [
  // Simple Login View
  { path: "", component: LoginComponent },
  { path: "prices", component: CustomerComponent, canActivate: [AuthGuard] },
  // Standard Views
  { path: "standard", component: StandardPricesheetComponent, canActivate: [AuthGuard] },
  { path: "standard-products", component: StandardProductsComponent, canActivate: [AuthGuard] },
  // Setup Views
  { path: "setup/terminal", component: TerminalComponent, canActivate: [AuthGuard] },
  { path: "setup/omex", component: OmexComponent, canActivate: [AuthGuard] },
  { path: "setup/oil", component: OilComponent, canActivate: [AuthGuard] },
  { path: "setup/collitype", component: ColliTypeTranslateComponent, canActivate: [AuthGuard] },
  {path: "setup/collimeasurement", component: ColliMeasurementComponent, canActivate: [AuthGuard]},
  {path: "setup/capacityTax", component: CapacityTaxComponent, canActivate: [AuthGuard]},
  {path: "setup/environmentalTax", component: EnvironmentalTaxComponent, canActivate: [AuthGuard]},
  // Customer Views
  { path: "customer", component: CustomerSettingsComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
