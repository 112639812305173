import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Results } from "../Models/results";

@Injectable({
    providedIn: 'root'
})
export class MeasureUnitService {
    private API = environment.api_url;
    private root_API = this.API + "/measureunit";

    constructor(private http: HttpClient){}

    getMeasureUnits(licenseRef: string) : Observable<Results> {
        const headers = { 'Authorization': 'E5B93DDF-CA5C-4C4B-975B-9BBDD95DD2D0' };
        return this.http.get(this.root_API + "/getallview?licenseRef=" + licenseRef, { headers });   
    }

}