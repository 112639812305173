<h1 mat-dialog-title
style="background-color: var(--primary-blue); padding:10px; margin:-24px; margin-bottom:10px; justify-content: center; display:flex; color:white;">
{{'INSERTNEWDATE' | translate}}</h1>
<mat-dialog-content fxLayout="column">
<div fxLayout="row">
<mat-form-field>
  <mat-label>{{'DATE' | translate}}</mat-label>
  <input [(ngModel)]="date" matInput type="date" />
</mat-form-field>
</div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between;">
<button mat-raised-button (click)="close()">{{'CLOSE' | translate}}</button>
<button mat-raised-button style="background-color: var(--primary-blue); color:white;"
(click)="save()">{{'CREATE' | translate}}</button>
</mat-dialog-actions>