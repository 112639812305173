import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Customer } from '../Models/customer';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private API = environment.api_url;
  private root_API = this.API + "/customer";

  constructor(private http: HttpClient, private api: ApiService) { }

  async getCustomers(customer?: Customer) {
    const headers = { 'Authorization': 'E5B93DDF-CA5C-4C4B-975B-9BBDD95DD2D0' };
    const body = customer;
    return await this.http.post(this.root_API + "/getall", body, { headers }).toPromise();
  }

  getAllClientCustomers(customer?: Customer) {
    const headers = { 'Authorization': 'E5B93DDF-CA5C-4C4B-975B-9BBDD95DD2D0' };
    const body = customer;
    return this.http.post(this.root_API + "/getall", body, { headers });
  }

  async postCustomer(customer: Customer) {
    return await this.api.post("/customer", customer).toPromise();
  }

  async saveOrUpdate(customer: Customer){
    return await this.api.post("/customer/saveorupdate", customer).toPromise();
  }

  async updateCustomerOilGroup(customerId: number, oilGroupId: number, licenseRef: string){
    return await this.api.post("/customer/updateCustomerOilGroup?customerId=" + customerId + "&oilGroupId=" + oilGroupId + "&licenseRef=" + licenseRef).toPromise();
  }

  async getClientCustomers(customer: Customer) { 
    return await this.api.post("/customer/getallclientcustomers", customer).toPromise();
  }

  searchClientCustomers(customer: Customer, name: string): Observable<Customer[]> {
    const headers = { 'Authorization': 'E5B93DDF-CA5C-4C4B-975B-9BBDD95DD2D0' };
    return this.http.post<Customer[]>(this.root_API + "/searchclientcustomers?name=" + name, customer, { headers }).pipe(map((customer: any) => customer.modelObject));
  }

  async getClientCustomersAsync(customer: Customer) {
    return this.api.post("/customer/getallclientcustomers", customer).pipe(
      map((res: any[]) => res.map((item: any) => item['name']))
    );
  }
}
