<div fxLayout="row"
    style="background-color: var(--primary-blue); padding:10px; margin:-24px; margin-bottom:10px; justify-content: center; display:flex; color:white;">
    <h1 mat-dialog-title>{{'NEWCOLLITYPETRANSLATE' | translate}}</h1>
</div>
<mat-dialog-content fxLayout="column" style="width:15vw; margin-bottom:6%; overflow:unset;">
    <div style="display:flex; flex-direction: column; align-items: stretch; justify-content: center;">
        <mat-form-field class="centText" floatLabel='never'>
            <mat-label>{{'CUSTOMER' | translate}}</mat-label>
            <mat-select [(ngModel)]="dialogData.fk_customerId" name="customer" [compareWith]="compareByOptionRef">
                <mat-option *ngFor="let cust of customers" [value]="cust.id">
                    {{cust.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div style="display:flex; flex-direction: column; align-items: stretch; justify-content: center;">
        <mat-form-field>
            <input [(ngModel)]="dialogData.date" matInput type="date" />
        </mat-form-field>
    </div>
    <div style="display:flex; flex-direction: column; align-items: stretch; justify-content: center;">
        <mat-form-field>
            <input #procent placeholder="Procent" type="text" matInput [(ngModel)]="dialogData.procent">
        </mat-form-field>
    </div>
    <div style="display:flex; flex-direction: column; align-items: stretch; justify-content: center;">
        <mat-form-field class="centText" floatLabel='never'>
            <mat-label>{{'COLLITYPE' | translate}}</mat-label>
            <mat-select [(ngModel)]="dialogData.collitypeRef" name="collitype" [compareWith]="compareByOptionRef">
                <mat-option *ngFor="let ct of colliTypes" [value]="ct.ref">
                    {{ct.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div style="display:flex; flex-direction: column; align-items: stretch; justify-content: center;">
        <mat-form-field>
            <input #erpCode placeholder="ERP Kode" type="text" matInput [(ngModel)]="dialogData!.erpCode">
        </mat-form-field>
    </div>

</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between;">
    <button mat-raised-button (click)="close()">{{'CLOSE' | translate}}</button>
    <button mat-raised-button style="background-color: var(--primary-blue); color:white;" (click)="save()">{{'CREATE' |
        translate}}</button>
</mat-dialog-actions>