import { Injectable } from "@angular/core";
import { ReturnCalcMethod } from "../Models/returnCalcMethod";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})
export class ReturnCalcService {
    constructor(private api: ApiService) {}

async saveOrUpdate(rcm: ReturnCalcMethod){
    return await this.api.post("/returncalcmethod/saveorupdate", rcm).toPromise();
}


}