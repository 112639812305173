<!-- Translate Dialog -->
  <h1 mat-dialog-title
    style="background-color: var(--primary-blue); padding:10px; margin:-24px; margin-bottom:10px; justify-content: center; display:flex; color:white;">
    {{'NEWPRICESHEET' | translate}}</h1>
<mat-dialog-content fxLayout="column">
  <div fxLayout="row">
    <mat-form-field>
      <mat-label>{{'NAME' | translate}}</mat-label>
      <input [(ngModel)]="name" matInput />
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <mat-form-field>
      <mat-label>{{'TYPE' | translate}}</mat-label>
      <mat-select [(ngModel)]="chosenType">
        <mat-option *ngFor="let type of types" [value]="type">
          {{type.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <mat-form-field>
      <mat-label>{{'DATE' | translate}}</mat-label>
      <input [(ngModel)]="date" matInput type="date" />
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between;">
  <button mat-raised-button (click)="close()">{{'CLOSE' | translate}}</button>
  <button mat-raised-button [disabled]="name.length == 0" style="background-color: var(--primary-blue); color:white;"
    (click)="save()">{{'CREATE' | translate}}</button>
</mat-dialog-actions>