import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable()
// Expand Service with JWT
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false); 
  private license = new BehaviorSubject<string>('');

  get getLicense(){
    return this.license.asObservable();
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private router: Router
  ) {}

  login(license: any){
    if (license) {
      this.license.next(license);
      this.loggedIn.next(true);
      this.router.navigate(['/']);
    }
  }

  logout() {
    this.loggedIn.next(false);
    this.license.next('');
    this.router.navigate(['/login']);
  }
}