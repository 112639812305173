import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DataShareService } from 'src/app/shared/api/datashare.service';
import { OilService } from 'src/app/shared/api/oil.service';
import { OilGroup } from 'src/app/shared/Models/oilGroup';
import { OilSheet } from 'src/app/shared/Models/oilSheet';
import { NewOilDialog } from './new-oil-dialog/new-oil.dialog';

@Component({
  selector: 'app-oil',
  templateUrl: './oil.component.html',
  styleUrls: ['./oil.component.scss']
})
export class OilComponent implements OnInit {

  constructor(private oilService: OilService, private dialog: MatDialog,
    private datePipe: DatePipe,
    private _snackBar: MatSnackBar,
    private data: DataShareService) { }
  oilSheets: OilSheet[] = [];
  oilSheetsDataSource!: MatTableDataSource<OilSheet>;
  originalOilGroups: OilGroup[] = [];
  oilGroups: OilGroup[] = [];
  loading: any;
  displayedColumns: string[] = ["name", "procent", "erpCode", "date"];
  displayedColumnsOilSheet: string[] = ["oilGroupName", "customerNumber", "customerName", "date", "procent", "erpCode"];
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnInit(): void {
    this.fetchData();

  }

  getAllOilGroups() {
    this.loading = true;
    let oilGroup: OilGroup = {
      licenseRef: this.data.License,
      includeOilSheets: true
    }
    this.oilService.getAllOilGroup(oilGroup).then((res) => {
      this.oilGroups = res.modelObject;
      for (let i = 0; i < this.oilGroups.length; i++) {
        this.oilGroups[i].date = this.datePipe.transform(this.oilGroups[i].date, 'yyyy-MM-dd')!;
      }
      this.oilGroups.sort((a, b) => (a.date! > b.date!) ? -1 : (a.date == b.date) ? 0 : 1);
    }).finally(() => this.loading = false);
  }

  getAllOilSheets() {
    this.loading = true;
    let oilSheet: OilSheet = {
      licenseRef: this.data.License,
      includeCustomer: true,
      includeOilsheetGroup: true
    };

    this.oilService.getAllOilSheet(oilSheet).then((res) => {
      if (res) {
        this.oilSheets = res.modelObject;
        this.oilSheets.sort((a, b) => (a.date! > b.date!) ? -1 : (a.date == b.date) ? 0 : 1);
        this.oilSheetsDataSource = new MatTableDataSource(this.oilSheets);
        this.oilSheetsDataSource.paginator = this.paginator;
      }
    }).finally(() => this.loading = false);
  }


  fetchData() {
    this.getAllOilGroups();
    this.getAllOilSheets();
  }

  createNew() {
    const dialogRef = this.dialog.open(NewOilDialog);

    dialogRef.afterClosed().subscribe((res: OilGroup) => {
      if (res) {
        res.date = this.datePipe.transform(res.date, 'yyyy-MM-DD')!;
        res.licenseRef = this.data.License;
        this.oilService.saveOrUpdateOilGroup(res).then((res: any) => {
            this.fetchData();
          
        }).finally(() => {
          this._snackBar.open("Olie Gruppe oprettet", "Success", { duration: 1000, panelClass: ['snack-bar'] });
        });
      }
    })
  }


  updateClick() {
    this.loading = true;
    for (let i = 0; i < this.oilGroups.length; i++) {
      if (this.oilGroups[i].date) {
        this.oilGroups[i].date = this.datePipe.transform(this.oilGroups[i].date, 'yyyy-MM-DD')!;
      }

    }
    this.oilService.SaveOrUpdateOilSheetByOilGroup(this.oilGroups).then((res) => {
      if (res) {
        this.fetchData();
      }
    }).finally(() => {
      this.loading = false;
      this._snackBar.open("Olie Gruppe Opdateret", "Success", { duration: 1000, panelClass: ['snack-bar'] });
    });
  }

}
