import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Terminal } from "src/app/shared/Models/terminal";
import { ConfirmDialogComponent } from "src/app/shared/util/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'new-terminal-dialog',
    templateUrl: './new-terminal.dialog.html',
    styleUrls: ['./new-terminal.dialog.scss'],
})
export class NewTerminalDialog {
    name: string = '';
    date: any;
    saveBtnDisabled = true;
    dialogData = <Terminal>{};
    constructor(public dialogRef: MatDialogRef<NewTerminalDialog>, private dialog: MatDialog, private translateService: TranslateService) {
        dialogRef.backdropClick().subscribe(() => {
            this.confirmDialog();
        });
    }

    save() {
        this.dialogRef.close(this.dialogData);
    }


    confirmDialog(){
        if (this.dialogData && this.dialogData.name && this.dialogData.name != '') {
            const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: this.translateService.instant('CLOSETERMINALDIALOGTITLE'),
                    message: this.translateService.instant('FOUNDDATAERRORMESSAGE')
                }
            });

            confirmDialog.afterClosed().subscribe((confirmed: any) => {
                if (confirmed) {
                    this.dialogRef.close();
                }
            });
        }
        else {
            this.dialogRef.close();
        }
    }

    close() {
        this.confirmDialog();
    }

}
