<div>
    <div class="flexColumn">
        <mat-form-field class="formField">
            <mat-label class="textColorDark">{{'WEIGHTTOVOLUME' | translate}}</mat-label>
            <input matInput type="number" [(ngModel)]="rule.weightprvolume">
        </mat-form-field>

        <mat-form-field class="formField">
            <mat-label class="textColorDark">{{'WEIGHTTOLDM' | translate}}</mat-label>
            <input matInput type="number" [(ngModel)]="rule.weightprldb">
        </mat-form-field>

        <mat-form-field class="formField">
            <mat-label class="textColorDark">{{'PALLETTOLDM' | translate}}</mat-label>
            <input matInput type="number" [(ngModel)]="rule.pllprldm">
        </mat-form-field>

        <mat-form-field class="formField">
            <mat-label class="textColorDark">{{'ROUNDING' | translate}}</mat-label>
            <input matInput type="number" [(ngModel)]="rule.pllRound">
        </mat-form-field>

    </div>
    <div class="saveBtn">
        <button (click)="saveRule()" mat-raised-button>{{'SAVE' | translate}}</button>
    </div>
</div>