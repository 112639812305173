
// This class is mainly for helper methods or internal check methods
export default class Utils {

    // Helper method : checks if value is an object
    static isObject(val: any) {
        return (typeof val === 'object');
    }
    // Compares 2 elements by object and ID
    static compareByOptionId(idFirst: any, idSecond: any) {
        return idFirst && idSecond && idFirst.id == idSecond.id;
    }

    // Compares 2 elements by object and ref
    static compareByOptionRef(refFirst: any, refSecond: any) {
        return refFirst && refSecond && refFirst.ref == refSecond.ref;
    }
   
}