import { Injectable } from "@angular/core";
import { HandlingType } from "../Models/pricesheetZone";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})
export class HandlingTypeService {
    constructor(private api: ApiService) {}

async getAll(handlingType: HandlingType){
    return await this.api.post("/handlingtype/getall", handlingType).toPromise();
}

}