import { Injectable } from "@angular/core";
import { Terminal } from "../Models/terminal";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})
export class TerminalService {
    constructor(private api: ApiService) {}

async getAll(terminal: Terminal){
    return await this.api.post("/terminal/getall", terminal).toPromise();
}

async saveOrUpdate(terminal: Terminal){
    return await this.api.post("/terminal/saveorupdate", terminal).toPromise();
}

async delete(terminal: Terminal){
    return await this.api.delete("/terminal/delete?id=" + terminal.id + "&licenseRef=" + terminal.licenseRef).toPromise();
}


async bulkSaveOrUpdate(terminals: Terminal[]){
    return await this.api.post("/terminal/bulksaveorupdate", terminals).toPromise();
}

}