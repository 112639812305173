<div fxLayout="row" style="margin:1%;">
  <div style="display:flex; justify-content: space-between; margin: 1%; align-items: center;">
    <h2 style="margin:0px;" class="header">{{'TERMINAL'|translate}}</h2>
    <button mat-raised-button style="background-color: var(--primary-blue); color:white; border-radius: 10px;" (click)="newTerminal()">Ny Terminal</button>
    <button mat-raised-button (click)="saveAll()"
      style="border-radius: 10px;background-color: var(--primary-blue); color:white;">{{'SAVEALL' | translate}}</button>
  </div>
  <div style="border-radius: 6px;" class="mat-elevation-z20 table-responsive">
    <table style="width:100%;" mat-table [dataSource]="terminalsDataSource">
      <ng-container matColumnDef="name">
        <th class="centText" mat-header-cell *matHeaderCellDef>{{'NAME'|translate}}</th>
        <td class="centText" mat-cell *matCellDef="let terminal">
          <input #name placeholder="Navn" type="text" matInput (ngModelChange)="checkData(terminal)"
            [(ngModel)]="terminal!.name">
        </td>
      </ng-container>
      <!-- DELETE/ACTION ↓ -->
      <ng-container matColumnDef="action">
        <th class="centText" mat-header-cell *matHeaderCellDef></th>
        <td class="centText" mat-cell *matCellDef="let terminal">
          <div *ngIf="!terminal.id || terminal.editable">
            <button matTooltip="Gem Terminal"
              style="border-radius: 10px;background-color: var(--primary-blue); color:white;" mat-raised-button
              (click)="updateTerminal(terminal)">
              <mat-icon>save</mat-icon>
            </button>
          </div>
          <div *ngIf="terminal.id && !terminal.editable">
            <button matTooltip="Slet Terminal" style="border-radius: 10px;" mat-raised-button color="warn"
              (click)="deleteTerminal(terminal)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr style="background-color:var(--primary-blue);" mat-header-row
        *matHeaderRowDef="displayedColumns; sticky:true;">
      </tr>
      <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 15, 25]" showFirstLastButtons aria-label="Vælg Side"></mat-paginator>
  </div>
</div>

<app-mat-spinner-overlay *ngIf="loading === true" [overlay]="true">
</app-mat-spinner-overlay>