<h1 mat-dialog-title
    style="background-color: var(--primary-blue); padding:10px; margin:-24px; margin-bottom:10px; justify-content: center; display:flex; color:white;">
    {{data.title}}</h1>

<div mat-dialog-content style="font-size:16px;" [innerHtml]="data.message">
</div>

<div mat-dialog-actions style="justify-content: space-between;">
    <button mat-raised-button color="warn" (click)="onDismiss()">Nej</button>
    <button mat-raised-button style="background-color: var(--primary-blue); color:white;" (click)="onConfirm()">Ja</button>
</div>