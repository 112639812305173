import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerService } from 'src/app/shared/api/customer.service';
import { DataShareService } from 'src/app/shared/api/datashare.service';
import { OmexProductService } from 'src/app/shared/api/omex-product.service';
import { OmexService } from 'src/app/shared/api/omex.service';
import { ProductService } from 'src/app/shared/api/product.service';
import { TerminalService } from 'src/app/shared/api/terminal.service';
import { Customer } from 'src/app/shared/Models/customer';
import { Omex, OmexProduct } from 'src/app/shared/Models/omex';
import { Product } from 'src/app/shared/Models/product';
import { Terminal } from 'src/app/shared/Models/terminal';
import { ConfirmDialogComponent } from 'src/app/shared/util/confirm-dialog/confirm-dialog.component';
import { NewOmexDialog } from './new-omex-dialog/new-omex.dialog';

@Component({
  selector: 'app-omex',
  templateUrl: './omex.component.html',
  styleUrls: ['./omex.component.scss']
})
export class OmexComponent implements OnInit {
  omexes: Omex[] = [];
  allOmexProducts: OmexProduct[] = [];
  activeOmexProducts?: OmexProduct[] = [];
  omexesDataSource!: MatTableDataSource<Omex>;
  terminals: Terminal[] = [];
  displayedColumns: string[] = ["terminal", "pickup", "via", "delivery", "action"];
  activeOmex?: Omex;
  products: Product[] = [];
  loading: any;
  customers: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private data: DataShareService,
    private terminalService: TerminalService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private productService: ProductService,
    private omexProductService: OmexProductService,
    private customerService: CustomerService,
    private omexService: OmexService) { }

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    this.getAllOmexes();
    this.getAllTerminals();
    this.getAllProducts();
    this.getAllOmexProducts();
    this.getCustomers();
  }


  getAllOmexes() {
    let omex: Omex = {
      licenseRef: this.data.License,
      pickup: "",
      via: "",
      delivery: "",
      includeTerminal: true
    }
    this.omexService.getAll(omex).then((res) => {
      this.omexes = res.modelObject;
      // this.omexes.unshift({ licenseRef: this.data.License, pickup: "", via: "", delivery: "", terminal: undefined });
      this.omexesDataSource = new MatTableDataSource(this.omexes);
      this.omexesDataSource.paginator = this.paginator;
    });
  }

  newOmex() {
    const dialogRef = this.dialog.open(NewOmexDialog, { disableClose: true });

    dialogRef.afterClosed().subscribe((omex: any) => {
      if (omex) {
        omex.fk_terminalId = omex.terminal.id;
        omex.licenseRef = this.data.License;
        if (omex.omexProducts && omex.omexProducts.length > 0) {
          this.omexService.saveOrUpdateWithProducts(omex).then((res: any) => { // Rewrite into Single method
            if (res) {

            }
          });
        }
        else {
          // Just save omex
          this.omexService.saveOrUpdate(omex).then((res: any) => {
            if (res) {
            }
          }).finally(() => {
            this._snackBar.open("Omex Gemt", "Success", { duration: 1500, panelClass: ['snack-bar'] });
            this.getAllOmexes();
          });
        }
      }
    });
  }

  selectProduct(product: Product) {
    if (!this.activeOmexProducts?.find(x => x.product == product) && !this.activeOmexProducts?.find(x => x.fk_productId == product.id)) {
      this.activeOmexProducts?.push({
        fk_productId: product.id,
        fk_omexId: this.activeOmex?.id,
        licenseRef: this.data.License,
        product: product,
        omex: this.activeOmex
      } as OmexProduct);
    }
    else {
      this._snackBar.open("Produktet findes i forvejen", 'Fejl', { duration: 1500, panelClass: ['snack-bar'] });
    }
  }

  removeOmexProduct(omexProd: OmexProduct) {
    this.loading = true;
    if (omexProd.id) {
      this.omexProductService.delete(omexProd).then((res: any) => {
        if (res) {
          this.fetchData();
        }
      }).finally(() => {
        this.handleOmex(this.activeOmex);
        this.loading = false;
      });
    }
    else {
      // Something
    }
  }


  getAllTerminals() {
    let terminal: Terminal = {
      licenseRef: this.data.License
    };
    this.terminalService.getAll(terminal).then((res) => {
      if (res) {
        this.terminals = res.modelObject;
      }
    });
  }

  saveOmexProducts() {
    this.loading = true;
    if (this.activeOmexProducts && this.activeOmexProducts?.length > 0) {
      for (let i = 0; i < this.activeOmexProducts.length; i++) {
        this.omexProductService.saveOrUpdate(this.activeOmexProducts[i]).then((res: any) => {
          if (i = this.activeOmexProducts!.length) {
            this.fetchData();
            this.loading = false;
            this._snackBar.open("Omex Produkter Gemt", "Success", { duration: 1500, panelClass: ['snack-bar'] });
          }
        });
      }
    }

  }

  getAllOmexProducts() {
    let omexProduct: OmexProduct = {
      licenseRef: this.data.License,
      includeProduct: true,
      includeCustomer: true
    };

    this.omexService.getAllOmexProducts(omexProduct).then((res: any) => {
      if (res) {
        this.allOmexProducts = res.modelObject;
      }
    });
  }


  getAllProducts() {
    let product: Product = {
      licenseRef: this.data.License,
    };
    this.productService.getAll(product).then((res: any) => {
      if (res) {
        this.products = res.modelObject;
      }
    });
  }

  saveAll() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "16%",
      height: "18%",
      data: {
        title: "Er du sikker?", // Translate
        message: "Du er ved at gemme :  <b>Alle Omexes</b>" // Translate
      }
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        for (let i = 0; i < this.omexesDataSource.data.length; i++) {
          if (this.omexesDataSource.data[i].terminal != null) {
            this.omexesDataSource.data[i].fk_terminalId = this.omexesDataSource.data[i].terminal!.id!;
          }
          if (!this.omexesDataSource.data[i].id && this.omexesDataSource.data[i].via!.length > 0 && this.omexesDataSource.data[i].terminal != null) {
            this.updateOmex(this.omexesDataSource.data[i]);
            this.omexesDataSource.data.splice(i, 1); --i;
          }
          else {
            if (this.omexesDataSource.data[i].id == null && (this.omexesDataSource.data[i].via!.length > 0 || this.omexesDataSource.data[i].terminal || this.omexesDataSource.data[i].pickup!.length > 0 || this.omexesDataSource.data[i].delivery!.length > 0)) {
              this._snackBar.open("Øverste Omex blev ikke gemt da der manglede felter", "Fejl", { duration: 3000, panelClass: ['snack-bar'] }); // Translate
            }
          }
          if (!this.omexesDataSource.data[i].id && this.omexesDataSource.data[i].via!.length <= 0) {
            this.omexesDataSource.data.splice(i, 1); --i;
          }

        }
        this.omexService.bulkSaveOrUpdate(this.omexesDataSource.data).then((res: any) => {
          if (res) {
            this.getAllOmexes();
            this._snackBar.open("Omexes er gemt", "Success", { duration: 2000, panelClass: ['snack-bar'] }); // Translate
          }
        });
      }
    });
  }

  updateOmex(omex: Omex) {
    omex.fk_terminalId = omex.terminal!.id!;
    if (omex && omex.via!.length > 0 && omex.terminal != null) {
      if (!omex.pickup) { omex.pickup = "" };
      if (!omex.delivery) { omex.delivery = "" };
      this.omexService.saveOrUpdate(omex).then((res) => {
        if (res) {
          this.getAllOmexes();
        }
      });
    }
    else {
      this._snackBar.open("Mangler felter", "Fejl", { duration: 2000, panelClass: ['snack-bar'] }); // Translate
    }
  }

  deleteOmex(omex: any) {
    if (omex) {
      this.omexService.delete(omex).then((res) => {
        if (res) {
          this.getAllOmexes();
        }
      }).finally(() => {
        this._snackBar.open("Omex Slettet", "Success", {panelClass: ['snack-bar']});
      });
    }
  }

  getCustomers(): void {
    this.loading = true;
    let customer: Customer;
    customer = {
      licenseRef: this.data.License,
      includeReturnCalcMethods: true,
      includeCustomerRules: true,
      includeCustomerPricesheets: true
    }

    this.customerService.getCustomers(customer).then((res: any) => {
      let customers = res.modelObject;
      customers.sort((a: Customer, b: Customer) => {
        if (a.clientObject == undefined && b.clientObject == undefined) return 0;
        if (a.clientObject == undefined) return -1;
        if (b.clientObject == undefined) return 1;
        if ((a.clientObject?.name == undefined || a.clientObject?.name == "") &&
          (b.clientObject?.name == undefined || b.clientObject?.name == "")) return 0;
        if (a.clientObject?.name == undefined || a.clientObject?.name == "") return -1;
        if (b.clientObject?.name == undefined || b.clientObject?.name == "") return -1;
        if (a.clientObject?.name!.toLowerCase() > b.clientObject?.name!.toLowerCase()) {
          return -1;
        }
        if (a.clientObject?.name!.toLowerCase() < b.clientObject?.name!.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      for (let i = 0; i < customers.length; i++) {
        if (customers[i].clientObject?.name) {
          customers[i].name = customers[i].clientObject.name;
        }
      }
      this.customers = customers.sort((a: any, b: any) => { if (a.name > b.name) return 1; if (b.name > a.name) return -1; return 0; });
    }
    ).finally(() => this.loading = false);
  }

  selectCustomer(customer: any, omexProd: any) {
    omexProd.customer = customer;
    omexProd.fk_customerId = customer.id;
  }

  compareByOptionId(idFirst: any, idSecond: any) {
    return idFirst && idSecond && idFirst.id == idSecond.id;
  }

  handleOmex(omex: any) {
    this.activeOmex = omex;
    this.activeOmexProducts = this.allOmexProducts.filter(x => x.fk_omexId == omex.id);

  }

  checkData(omex: any) {
    if (omex.id > 0) {
      this.handleOmex(omex);
      omex.editable = true;
    }
  }

}
