import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataShareService } from '../shared/api/datashare.service';
import { RulesService } from '../shared/api/rules.service';
import { Customer } from '../shared/Models/customer';
import { Pricesheet } from '../shared/Models/pricesheet';
import { Rule } from '../shared/Models/rule';

@Component({
  selector: 'app-customer-rules',
  templateUrl: './customer-rules.component.html',
  styleUrls: ['./customer-rules.component.scss']
})
export class CustomerRulesComponent implements OnInit {

  @Input() customer = <Customer>{};
  @Input() pricesheet = <Pricesheet>{};
  rule: Rule = {};
  constructor(private ruleService: RulesService, private data: DataShareService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getRulesByCustomer();
  }

  getRulesByCustomer() {
    if (this.customer) {
      this.ruleService.getByCustomer({
        fk_customerId: this.customer.id,
        licenseRef: this.data.License,
        oilpaydatetype: 0,
      }).then((res: any) => {
        if (res && res.modelObject) {
          this.rule = res.modelObject;
        }
      });
    }
  }

  saveRule() {
    if (this.rule) {
      if (!this.rule.licenseRef) {
        this.rule.licenseRef = this.data.License;
      }
      this.rule.fk_pricesheetId = this.pricesheet.id;
      this.rule.fk_customerId = this.customer.id;
      this.ruleService.saveOrUpdate(this.rule).then((res) => {
        if (res && res.modelObject) {
          this.rule = res.modelObject;
        }
      }).finally(() => {
        this._snackBar.open("Regler Gemt", "Success", {duration: 1000, panelClass: ['snack-bar']});
      });
    }
  }

}