import { Injectable } from "@angular/core";
import { Product } from "../Models/product";
import { ProductPrice } from "../Models/productPrice";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    constructor(private api: ApiService) {}

async saveFullProduct(productPrice: ProductPrice){
    return await this.api.post("/product/saveorupdate", productPrice).toPromise();
}

async saveProducts(productPrices: ProductPrice[]){
    return await this.api.post("/product/saveorupdateAll", productPrices).toPromise();
}

async getAll(product: Product){
    return await this.api.post("/product/getallstandard", product).toPromise();
}

async delete(id: any, licenseRef: any){
    return await this.api.delete("/product/delete?id=" + id + "&licenseRef=" + licenseRef).toPromise();
}

}