import { Injectable } from "@angular/core";
import { Omex, OmexProduct } from "../Models/omex";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})
export class OmexProductService {
    constructor(private api: ApiService) { }

    async bulkSaveOrUpdate(omexProducts: OmexProduct[]) {
        return await this.api.post("/omexProduct/bulkSaveOrUpdate", omexProducts).toPromise();
    }

    async delete(omexProduct: OmexProduct){
        return await this.api.post("/omexProduct/delete", omexProduct).toPromise();
    }


    async saveOrUpdate(omexProduct: OmexProduct) {
        return await this.api.post("/omexProduct/saveOrUpdate", omexProduct).toPromise();
    }

}