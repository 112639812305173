<div fxLayout="row"
    style="background-color: var(--primary-blue); padding:10px; margin:-24px; margin-bottom:10px; justify-content: center; display:flex; color:white;">
    <h1 mat-dialog-title>{{'NEWCOLLITYPEMEASUREMENT' | translate}}</h1>
</div>
<mat-dialog-content fxLayout="column" style="width:25vw; margin-bottom:6%; overflow:unset;">
    <div style="display:flex; flex-direction: column; align-items: stretch; justify-content: center;">
        <mat-form-field floatLabel='never'>
            <mat-label>{{'COLLITYPE' | translate}}</mat-label>
            <mat-select [(ngModel)]="dialogData.colliType" name="collitype" [compareWith]="compareByOptionId">
                <mat-option *ngFor="let ct of colliTypes" [value]="ct">
                    {{ct.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="row" style="display:flex;flex-direction: row; justify-content: space-between;">
        <mat-form-field floatLabel='never'>
            <input placeholder="Højde" type="text" matInput [(ngModel)]="dialogData.height">
        </mat-form-field>
        <mat-form-field floatLabel='never'>
            <input placeholder="Længde" type="text" matInput [(ngModel)]="dialogData.length">
        </mat-form-field>
    </div>
    <div fxLayout="row" style="display:flex;flex-direction: row; justify-content: space-between;">
        <mat-form-field floatLabel='never'>
            <input placeholder="Bredde" type="text" matInput [(ngModel)]="dialogData.width">
        </mat-form-field>
        <mat-form-field floatLabel='never'>
            <input placeholder="Vægt" type="text" matInput [(ngModel)]="dialogData.weight">
        </mat-form-field>
    </div>

    <div fxLayout="row" style="display:flex;flex-direction: row; justify-content: space-between;">
        <mat-form-field floatLabel='never'>
            <input placeholder="Volume" type="text" matInput [(ngModel)]="dialogData.volume">
        </mat-form-field>
        <mat-form-field floatLabel='never'>
            <input placeholder="ldm" type="text" matInput [(ngModel)]="dialogData.ldm">
        </mat-form-field>
    </div>
    <div style="display:flex; flex-direction: column; align-items: stretch; justify-content: center;">
        <mat-form-field floatLabel='never'>
            <input placeholder="Pris Kode" type="text" matInput [(ngModel)]="dialogData.priceCode">
        </mat-form-field>
    </div>
    <div fxLayout="row" style="display:flex;flex-direction: row; justify-content: space-between;">
        <mat-form-field floatLabel='never'>
            <mat-label>Kunde</mat-label>
            <mat-select [(ngModel)]="dialogData.customerRef" name="customer" [compareWith]="compareByOptionRef">
                <mat-option *ngFor="let cust of customers" [value]="cust.customerexternRef">
                    {{cust.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel='never'>
            <mat-label>{{'GOTOCOLLITYPE' | translate}}</mat-label>
            <mat-select [(ngModel)]="dialogData.springCollitypeRef" name="collitype" [compareWith]="compareByOptionRef">
                <mat-option *ngFor="let ct of colliTypes" [value]="ct.ref">
                    {{ct.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between;">
    <button mat-raised-button (click)="close()">{{'CLOSE' | translate}}</button>
    <button mat-raised-button style="background-color: var(--primary-blue); color:white;" (click)="save()">{{'CREATE' |
        translate}}</button>
</mat-dialog-actions>