import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DataShareService } from '../shared/api/datashare.service';
import { ProductPriceService } from '../shared/api/productprice.service';
import { ProductPrice } from '../shared/Models/productPrice';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  productprice = <ProductPrice>{};
  productprices: ProductPrice[] = [];
  displayedColumns: string[] = ["name", "price", "valuta"];
  dataSource!: MatTableDataSource<ProductPrice>;
  originalProductPrices: any;
  menuTopLeftPosition = { x: '0', y: '0' }
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger!: MatMenuTrigger;

  constructor(private productpriceService: ProductPriceService, private data: DataShareService, private datePipe: DatePipe, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getProductPrices();
  }

  saveCustomerProducts() {
    for (let i = 0; i < this.productprices.length; i++) {
      this.productprices[i].validFrom = this.datePipe.transform(this.productprices[i].validFrom, 'yyyy-MM-dd')!;
      this.productprices[i].fk_customerId = this.data.selectedCustomer.id;
    }
    this.productpriceService.saveOrUpdateCustomerProducts(this.productprices).then((res) => {
      if (res) {
        this.getProductPrices();
      }
    }).finally(() => {
      this._snackBar.open("Produkt er Gemt", "Success", {panelClass: ['snack-bar']});
    });
  }

  onRightClick(event: MouseEvent, item: any) {
    event.preventDefault();
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
    this.matMenuTrigger.menuData = { item: item }
    this.matMenuTrigger.openMenu();

  }

  async getProductPrices() {
    if (this.data.selectedCustomer.id) {
      this.productprice.licenseRef = this.data.License;
      this.productprice.includeCustomer = true;
      this.productprice.includeProduct = true;
      this.productprice.includeCurrency = true;
      this.productprice.fk_customerId = this.data.selectedCustomer.id;
      this.productpriceService.getProductPricesByCustomerId(this.productprice).then((res) => {
        if (res.modelObject) {
          this.productprices = res.modelObject;
          this.productprices = this.productprices.sort((a: ProductPrice, b: ProductPrice) => {
            if (a.fk_customerId == null && b.fk_customerId == null) {
              if (a.product?.name! > b.product?.name!) {
                return 1;
              }
              if (b.product?.name! > a.product?.name!) {
                return -1;
              }
              return 0;
            }
            if (a.fk_customerId == null) {
              return 1;
            }
            if (b.fk_customerId == null) {
              return -1;
            }
            if (a.product?.name! > b.product?.name!) {
              return 1;
            }
            if (b.product?.name! > a.product?.name!) {
              return -1;
            }
            return 0;
          });
          this.dataSource = new MatTableDataSource(this.productprices);
        }
      });
    }
  }

}
