import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'pricesheet-dialog',
    templateUrl: 'pricesheet.dialog.html'
})
export class PriceSheetDialog {
    name: string = '';
    date: any;
    saveBtnDisabled = true;
    dialogData = <any>{};
    constructor(public dialogRef: MatDialogRef<PriceSheetDialog>, private datePipe: DatePipe
    ) {
        this.date = new Date();
        this.date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    }

    save() {
        this.dialogRef.close({ name: this.name, date: this.date });
    }

    close() {
        this.dialogRef.close();
    }
}
