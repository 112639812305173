import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OilService } from 'src/app/shared/api/oil.service';
import { CapacityTaxService } from '../shared/api/capacity-tax.service';
import { DataShareService } from '../shared/api/datashare.service';
import { EnvironmentalTaxService } from '../shared/api/environmental-tax.service';
import { CapacityTax } from '../shared/Models/capacityTax';
import { ColliType } from '../shared/Models/collitype';
import { Customer } from '../shared/Models/customer';
import { EnvironmentalTax } from '../shared/Models/enviromentalTax';
import { OilGroup } from '../shared/Models/oilGroup';
import { OilSheet } from '../shared/Models/oilSheet';
import { ConfirmDialogComponent } from '../shared/util/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-additions',
  templateUrl: './additions.component.html',
  styleUrls: ['./additions.component.scss']
})

export class AdditionsComponent implements OnInit {
  @Input() customer: Customer = {};
  environmentalTax: EnvironmentalTax = {};
  capacityTax?: CapacityTax = {};
  oilSheet?: OilSheet = {};
  colliTypes: any;
  collitypeRequest = <ColliType>{};
  colliType: any;
  environmentalTaxes: any;
  oilGroups: OilGroup[] = [];
  oilSheets: OilSheet[] = [];
  selectedEnvironmentalTax: any;
  capacityTaxes: CapacityTax[] = [];
  newCapacityTax: CapacityTax = {};
  newEnvironmentalTax: EnvironmentalTax = {};
  newOilSheet: OilSheet = { date: undefined };

  constructor(
    private capacityTaxService: CapacityTaxService,
    private data: DataShareService,
    private enviromentalTaxService: EnvironmentalTaxService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private oilService: OilService) { }

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    this.getAllCapacityTaxesByCustomer();
    this.getEnvironmentalTaxByCustomer();
    this.getAllOilSheets();
    this.getOilGroups();
  }

  getAllCapacityTaxesByCustomer() {
    this.capacityTaxService.getAllByCustomer(this.customer.id!, { licenseRef: this.data.License }).then((res: any) => {
      if (res) {
        this.capacityTaxes = res.modelObject;
        this.capacityTaxes.sort((a: any, b: any) => (a.date > b.date) ? -1 : (a.date == b.date) ? 0 : 1);
        this.capacityTaxes = this.capacityTaxes.slice(0, 3);
        for (let i = 0; i < this.capacityTaxes.length; i++) {
          if (this.capacityTaxes[i].date) {
            this.capacityTaxes[i].date = this.datePipe.transform(this.capacityTaxes[i].date, 'yyyy-MM-dd')!;
          }
        }
      }
    });
  }
  
  getEnvironmentalTaxByCustomer() {
    this.enviromentalTaxService.getAllByCustomer(this.customer.id!, { licenseRef: this.data.License }).then((res: any) => {
      if (res) {
        this.environmentalTaxes = res.modelObject;
        this.environmentalTaxes.sort((a: any, b: any) => (a.date > b.date) ? -1 : (a.date == b.date) ? 0 : 1);
        this.environmentalTaxes = this.environmentalTaxes.slice(0, 3);
        for (let i = 0; i < this.environmentalTaxes.length; i++) {
          if (this.environmentalTaxes[i].date) {
            this.environmentalTaxes[i].date = this.datePipe.transform(this.environmentalTaxes[i].date, 'yyyy-MM-dd')!;
          }
        }
      }
    });
  }


  getOilGroups() {
    let oilGroup: OilGroup = {
      licenseRef: this.data.License
    }
    this.oilService.getAllOilGroup(oilGroup).then((res: any) => {
      if (res) {
        this.oilGroups = res.modelObject;
      }
    });
  }

  saveOil() {
    if (this.newOilSheet.procent && this.newOilSheet.date) {
      var custOilGroup = this.oilGroups.find((x: any) => x.id == this.customer.fk_oilGroupId);
      if (!custOilGroup) {
        custOilGroup = {};
        custOilGroup.name = "Ingen";
      }
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        width: "20%",
        maxHeight: '300px',
        data: {
          title: "Gem ny Oliesats?", // Translate ↓
          message: "Ved at tilføje en ny Oliesats på kunden<br/> fjernes kunden fra<br/>OlieGruppen:<b>" + custOilGroup.name + "</b><br/>" +
            "Ny Olie <br/>Procent: <b>" + this.newOilSheet.procent + "</b><br/>" + "Dato: <b>" + this.newOilSheet.date + "</b><br/>" + "Erp Kode: <b>" + (this.newOilSheet.erpCode ? this.newOilSheet.erpCode + "</b>" : "Ingen</b>")
        }
      });
      this.newOilSheet.licenseRef = this.data.License;
      this.newOilSheet.fk_customerId = this.customer.id;
      this.newOilSheet.date = this.datePipe.transform(this.newOilSheet.date, 'yyyy-MM-dd')!;
      confirmDialog.afterClosed().subscribe((res: any) => {
        if (res) {
          this.oilService.SaveOrUpdateOilSheet(this.newOilSheet).then((res: any) => {
            if (res) {
              this.getAllOilSheets();
            }
          }).finally(() => {
            this._snackBar.open("Olie Gemt", "Success", {panelClass: ['snack-bar']});
          });
        }
      });

    }
  }

  saveEnvironmental() {
    if (this.newEnvironmentalTax.procent && this.newEnvironmentalTax.date) {
      this.newEnvironmentalTax.date = this.datePipe.transform(this.newEnvironmentalTax.date, 'yyyy-MM-dd')!;
      this.newEnvironmentalTax.licenseRef = this.data.License;
      this.newEnvironmentalTax.fk_customerId = this.customer.id;
      this.enviromentalTaxService.saveOrUpdate(this.newEnvironmentalTax).then((res: any) => {
        if (res) {
          this.getEnvironmentalTaxByCustomer();
          
        }
      }).finally(() => {
        this._snackBar.open("Miljø Tillæg Gemt", "Success", {panelClass: ['snack-bar']});
      });

    }
  }

  saveCapacity() {
    if (this.newCapacityTax.procent && this.newCapacityTax.date) {
      this.newCapacityTax.date = this.datePipe.transform(this.newCapacityTax.date, 'yyyy-MM-dd')!;
      this.newCapacityTax.licenseRef = this.data.License;
      this.newCapacityTax.fk_customerId = this.customer.id;
      this.capacityTaxService.saveOrUpdate(this.newCapacityTax).then((res: any) => {
        if (res) {
          this.getAllCapacityTaxesByCustomer();
        }
      }).finally(() => {
        this._snackBar.open("Kapacitets Tillæg Gemt", "Success", {panelClass: ['snack-bar']});
      })
    }
  }

  selectEnvironmentalTax(envTax: any) {
    if (envTax) {
      this.selectedEnvironmentalTax = envTax;
    }
  }

  getAllOilSheets() {
    this.oilService.getAllOilSheetByCustomer(this.customer.id!, { licenseRef: this.data.License }).then((res: any) => {
      if (res) {
        this.oilSheets = res.modelObject;
        this.oilSheets.sort((a: any, b: any) => (a.date > b.date) ? -1 : (a.date == b.date) ? 0 : 1);
        this.oilSheets = this.oilSheets.slice(0, 3);
        for (let i = 0; i < this.oilSheets.length; i++) {
          if (this.oilSheets[i].date) {
            this.oilSheets[i].date = this.datePipe.transform(this.oilSheets[i].date, 'yyyy-MM-dd')!;
          }
        }
      }
    });
  }
}