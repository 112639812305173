<div fxLayout="row"
    style="background-color: var(--primary-blue); padding:10px; margin:-24px; margin-bottom:10px; justify-content: center; display:flex; color:white;">
    <h1 mat-dialog-title>{{'NEWOMEX' | translate}}</h1>
</div>
<mat-dialog-content
    style="width:25vw; margin-bottom:6%; overflow:unset; display:flex; flex-direction: row; justify-content: space-around;">
    <div style="display:flex; flex-direction: column; align-items: stretch; justify-content: center;">
        <div style="display:flex; flex-direction: row;">
            <mat-form-field floatLabel='never' style="width:200px;">
                <mat-label>{{'TERMINAL' | translate}}</mat-label>
                <mat-select [(ngModel)]="dialogData.terminal" name="terminal" [compareWith]="compareByOptionId">
                    <mat-option *ngFor="let term of terminals" [value]="term">
                        {{term.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div style="display:flex; flex-direction: row;">
            <mat-form-field style="width:200px;">
                <input placeholder="Afhentning" type="text" matInput [(ngModel)]="dialogData.pickup">
            </mat-form-field>
        </div>
        <div style="display:flex; flex-direction: row;">
            <mat-form-field style="width:200px;">
                <input placeholder="Via" type="text" matInput [(ngModel)]="dialogData.via">
            </mat-form-field>
        </div>
        <div style="display:flex; flex-direction: row;">
            <mat-form-field style="width:200px;">
                <input placeholder="Levering" type="text" matInput [(ngModel)]="dialogData.delivery">
            </mat-form-field>
        </div>
    </div>
    <div style="display:flex; flex-direction: column; align-items: center; overflow-y: auto; max-height: 500px;">
        <h3>Omex Produkter</h3>
        <mat-form-field appearance="outline">
            <mat-label>{{'CHOOSEOMEXPRODUCT' | translate}}</mat-label>
            <mat-select>
                <mat-option *ngFor="let prod of products" (click)="selectProduct(prod)">
                    {{prod.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div style="display:flex; flex-direction: row; align-items: center; width:100%;"
            *ngFor="let omexProd of omexProducts">
            <div
                style="margin:5px; padding: 5px 50px 5px 50px; border-radius: 10px; border:black solid 1px; text-align:center; width:100%;">
                {{omexProd.product!.name}}
            </div>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between;">
    <button mat-raised-button (click)="close()">{{'CLOSE' | translate}}</button>
    <button mat-raised-button style="background-color: var(--primary-blue); color:white;" (click)="save()">{{'CREATE' |
        translate}}</button>
</mat-dialog-actions>