import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ColliType, ColliTypeTranslate } from "../Models/collitype";
import { DataShareService } from "./datashare.service";

@Injectable({
  providedIn: 'root',
})
export class ColliTypeService {
  private apiUrl = environment.api_collitype_url;
  constructor(private http: HttpClient, private data: DataShareService) { }


  async getAllColliTypes(colliTypeRequest: ColliType) {
    if (!colliTypeRequest.licenseRef) {
      colliTypeRequest.licenseRef = this.data.License;
    }
    return await this.http.post(`${this.apiUrl}/collitype/getall`, colliTypeRequest, this.getOptions()).toPromise();
  }


  async getAllColliTypeTranslate(colliTypeRequest: ColliTypeTranslate) {
    if (!colliTypeRequest.licenseRef) {
      colliTypeRequest.licenseRef = this.data.License;
    }
    return await this.http.post(`${this.apiUrl}/collitypetranslate/getall`, colliTypeRequest, this.getOptions()).toPromise();
  }

  async getAllColliTypeTranslateByCustomer(colms: any){
    return await this.http.post(`${this.apiUrl}/collitypetranslate/getbycustomerref`, colms, this.getOptions()).toPromise();
  }


  async saveOrUpdate(collitypeTranslate: any) {
    return await this.http.post(`${this.apiUrl}/collitypetranslate/createorupdate`, collitypeTranslate, this.getOptions()).toPromise();
  }

  async saveOrUpdateColliTypeMeasurement(colms: any) {
    return await this.http.post(`${this.apiUrl}/collitypemeasurement/createorupdate`, colms, this.getOptions()).toPromise();
  }

  async getAllColliTypeMeasurement(colms: any){
    return await this.http.post(`${this.apiUrl}/collitypemeasurement/getall`, colms, this.getOptions()).toPromise();
  }

  async getAllColliTypeMeasurementByCustomer(colms: any){
    return await this.http.post(`${this.apiUrl}/collitypemeasurement/getbycustomer`, colms, this.getOptions()).toPromise();
  }

  async bulkSaveOrUpdate(collitypeTranslates: any){
    return await this.http.post(`${this.apiUrl}/collitypeTranslate/bulkSaveOrUpdate`, collitypeTranslates, this.getOptions()).toPromise();
  }

  async delete(collitype: any){
    if (!collitype.licenseRef) {
      collitype.licenseRef = this.data.License;
    }
    return await this.http.delete(`${this.apiUrl}/collitypetranslate/delete?id=` + collitype.id + "&licenseRef=" + collitype.licenseRef, this.getOptions()).toPromise();
  }

  async deleteColliTypeMeasurement(colms: any){
    return await this.http.delete(`${this.apiUrl}/collitypemeasurement/delete?id=` + colms.id + "&licenseRef=" + colms.licenseRef, this.getOptions()).toPromise();
  }

  async bulkSaveOrUpdateColliTypeMeasurements(collims: any[]) {
    return await this.http.post(`${this.apiUrl}/collitypemeasurement/bulksaveorupdate`, collims, this.getOptions()).toPromise();
  }


  getAllColliTypesSync(colliTypeRequest: ColliType) {
    if (!colliTypeRequest.licenseRef) {
      colliTypeRequest.licenseRef = this.data.License;
    }
    return this.http.post(`${this.apiUrl}/collitype/getall`, colliTypeRequest, this.getOptions());
  }

  getOptions() {
    let httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json', "Authorization": 'E5B93DDF-CA5C-4C4B-975B-9BBDD95DD2D0' }),
    };
    return httpOptions;
  }

}