<div style="position: fixed; left:50%; right:50%; transform: translate(-50%, 30%); height:60%;" class="container"
	id="container">
	<div class="form-container sign-in-container">
		<form action="#">
			<h1 style="box-shadow: 0px 2px black; font-weight: 500;">Log ind</h1>
			<p></p>
			<mat-form-field appearance="outline">
				<mat-label>License</mat-label>
				<input type="text" autofocus style="width:140px; background-color: white;padding-bottom: 0px;" [(ngModel)]="license" [autocomplete]="false" matInput  required name="license" />
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Password</mat-label>
				<input style="width:140px; background-color: white;padding-bottom: 0px;" matInput name="password" [(ngModel)]="password" [autocomplete]="false" type="password" />
			</mat-form-field>
			<button style="border-color:black; border-bottom-width: medium;" matTooltip="Test Login"
				(click)="doLogin()">{{'LOGIN' | translate}}</button>
		</form>
	</div>
	<div class="overlay-container">
		<div class="overlay">
			<div class="overlay-panel overlay-right">
				<h1 style="font-size:xx-large; text-shadow:-1px 3px black; font-weight: 500;">Price Engine</h1>
			</div>
		</div>
	</div>
</div>
