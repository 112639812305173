import { EventEmitter, Injectable, Output } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { Customer } from "../Models/customer";
import { PricesheetPrice } from "../Models/pricesheetPrice";
import { PricesheetZone } from "../Models/pricesheetZone";

@Injectable({
  providedIn: 'root'
})
// Data Service - Acts like Central point for data keeping/Handling Frontend wise
export class DataShareService {
  constructor() { }

  private _filteredZones = new ReplaySubject<PricesheetZone[]>();
  filteredZones$ = this._filteredZones.asObservable();
  changeFilteredZones(filteredZones: PricesheetZone[]) {
    this._filteredZones.next(filteredZones);
  }

  private _filteredPrices = new ReplaySubject<PricesheetPrice[]>();
  filteredPrices$ = this._filteredPrices.asObservable();
  changeFilteredPrices(filteredPrices: PricesheetPrice[]) {
    this._filteredPrices.next(filteredPrices);
  }

  // Serialization of new Data Logic
  @Output() SerializeZones: EventEmitter<any> = new EventEmitter();
  @Output() SerializePrices: EventEmitter<any> = new EventEmitter();
  @Output() DoneSerializing: EventEmitter<any> = new EventEmitter();

  private pricesSerializationDone = false;
  private zonesSerializationDone = false;

  startSerialization() {
    this.pricesSerializationDone = false;
    this.zonesSerializationDone = false;
    this.SerializePrices.emit(true);
    this.SerializeZones.emit(true);
  }

  doneSerializingPrices() {
    this.pricesSerializationDone = true;
    if (this.zonesSerializationDone && this.pricesSerializationDone) {
      this.DoneSerializing.emit(true);
    }

  }

  doneSerializingZones() {
    this.zonesSerializationDone = true;
    if (this.zonesSerializationDone && this.pricesSerializationDone) {
      this.DoneSerializing.emit(true);
    }
  }

  ClearData() {
    this.filteredPrices = [];
    this.filteredZones = [];
    this.WorkZones = [];
    this.WorkPrices = [];
    this.selectedCustomer = {};
    this.selectedPricesheet = null;
    this.Prices = [];
    this.Zones = [];
    this.pricesheets = [];
    this.ZoneDates = [];
    this.changeFilteredPrices([]);
    this.changeFilteredZones([]);
  }

  AlmostClearData() {
    this.filteredPrices = [];
    this.filteredZones = [];
    this.WorkZones = [];
    this.WorkPrices = [];
    this.selectedCustomer = {};
    this.selectedPricesheet = null;
    this.Prices = [];
    this.Zones = [];
    this.ZoneDates = [];
    this.changeFilteredPrices([]);
    this.changeFilteredZones([]);
  }

  // Properties
  public License: any;
  public customerChosen: any;
  public filteredZones: PricesheetZone[] = [];
  public Zones: PricesheetZone[] = [];
  public selectedPricesheet: any;
  public pricesheets: any;
  public Prices: PricesheetZone[] = [];
  public filteredPrices: PricesheetPrice[] = [];
  public selectedCustomer = <Customer>{};
  public selectedDate: any;
  public Currencies: any;
  public ZoneDates: any[] = [];
  public WorkZones: PricesheetZone[] = [];
  public WorkPrices: PricesheetPrice[] = [];
  public chosenCountry: any;
  public ColliTypes: any;
  public AllZones: PricesheetZone[] = [];
  public AllPrices: PricesheetPrice[] = [];
  public showHidePrint: boolean = false;


  setSelectedDate(selectedDate: any) {
    this.selectedDate = selectedDate;
    if (this.Zones.length > 0) {
      this.filterByDateZone();
      this.changeFilteredZones(this.filteredZones);
    }
    if (this.Prices.length > 0) {
      this.filterByDatePrice();
      this.changeFilteredPrices(this.filteredPrices);
    }
  }

  setZones(zones: PricesheetZone[]) {
    this.Zones = zones;
    if (this.selectedDate) {
      this.filterByDateZone();
      this.changeFilteredZones(this.filteredZones);
    }
  }

  setPrices(prices: PricesheetPrice[]) {
    this.Prices = prices;
    if (this.selectedDate) {
      this.filterByDatePrice();
      this.changeFilteredPrices(this.filteredPrices);
    }
  }

  hidePrintToggle() {
    this.showHidePrint = !this.showHidePrint;
    this.filterByDatePrice();
    this.changeFilteredPrices(this.filteredPrices)
    this.filterByDateZone();
    this.changeFilteredZones(this.filteredZones)
  }

  turnOffPrintToggle() {
    this.showHidePrint = false;
  }


  filterByDateZone() {
    if (this.selectedPricesheet) {
      if (this.Zones.length > 0) {
        this.filteredZones = this.Zones?.filter((item: PricesheetZone) => {
          return item.validFrom! >= this.selectedDate && item.validFrom! <= this.selectedDate && (this.showHidePrint ? true : !item.hidePrint);
        });
      }
    }
  }
  filterByDatePrice() {
    if (this.selectedPricesheet) {
      if (this.Prices.length > 0) {
        this.filteredPrices = this.Prices?.filter((item: any) => {
          return item.validFrom >= this.selectedDate && item.validFrom <= this.selectedDate && (this.showHidePrint ? true : !item.hidePrint);
        });
      }
    }
  }
}