import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Pricesheet } from '../Models/pricesheet';
import { Results } from '../Models/results';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class PricesheetService {
  private API = environment.api_url;
  private root_API = this.API + "/pricesheet";

  constructor(private http: HttpClient, private api: ApiService) { }

  getAllPriceSheets(priceSheet: Pricesheet): Observable<Results> {
    const headers = { 'Authorization': 'E5B93DDF-CA5C-4C4B-975B-9BBDD95DD2D0' };
    const body = priceSheet;
    return this.http.post(this.root_API + "/getall", body, { headers });
  }

  async getPriceSheetsByCustomer(customerId: number, priceSheet: Pricesheet) {
    const headers = { 'Authorization': 'E5B93DDF-CA5C-4C4B-975B-9BBDD95DD2D0' };
    const body = priceSheet;
    return await this.http.post(this.root_API + "/GetByCustomerPricesheets?customerId=" + customerId, body, { headers }).toPromise();
  }

  async savePricesheet(pricesheet: Pricesheet) {
    const headers = { 'Authorization': 'E5B93DDF-CA5C-4C4B-975B-9BBDD95DD2D0' };
    const body = pricesheet;
    return await this.http.post(this.root_API + "/SaveOrUpdate", body, { headers }).toPromise();

  }

  async delete(id: any, licenseRef: any) {
    return await this.api.delete("/pricesheet/delete?id=" + id + "&licenseRef=" + licenseRef).toPromise();
  }

  async saveCustomerPricesheet(pricesheet: any){
    return await this.api.post("/customerpricesheet/saveOrUpdateCustomerPricesheet", pricesheet).toPromise();
  }

  async saveNewPricesheetOnCustomer(pricesheet: any){
    return await this.api.post("/pricesheet/saveCustomerPricesheet", pricesheet).toPromise();
  }

  async saveOrUpdateCustomerPricesheet(pricesheet: any){ // Bulkify
    return await this.api.post("/pricesheet/saveOrUpdateCustomerPricesheet", pricesheet).toPromise();
  }

  async deleteCustomerRelation(id: any, customerId: any, licenseRef: any) {
    return await this.api.delete("/pricesheet/deleteCustomerRelation?id=" + id + "&customerId=" + customerId + "&licenseRef=" + licenseRef).toPromise();
  }

  getAllDefaultPricesheets(pricesheet: Pricesheet): Observable<Results> {
    const headers = { 'Authorization': 'E5B93DDF-CA5C-4C4B-975B-9BBDD95DD2D0' };
    const body = pricesheet;
    return this.http.post(this.root_API + "/GetAllDefault", body, { headers });
  }

}
