import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../shared/api/auth.service';
import { DataShareService } from '../shared/api/datashare.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isValue: any;
  menuTopLeftPosition = { x: '0', y: '0' }
  maxCount: number = 3;
  count: number = 0;
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger!: MatMenuTrigger;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isLoggedIn$!: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver, private authService: AuthService, private elementRef: ElementRef, public data: DataShareService) { }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedIn;
  }

  logout() {
    this.isValue = 1;
    this.authService.logout();
  }

  toggle(val: any) { this.isValue = val; }


  onRightClick(event: MouseEvent, item: any) {
    this.count++;
    event.preventDefault();
    if (this.count == this.maxCount) {
      this.count = 0;
      this.menuTopLeftPosition.x = event.clientX + 'px';
      this.menuTopLeftPosition.y = event.clientY + 'px';
      this.matMenuTrigger.menuData = { item: item }
      this.matMenuTrigger.openMenu();
    }

  }

  chooseColor(color: any) {
    var primColor = "";
    if (color == "random") { // Set Random color if random
      primColor = "#" + Math.round(Math.random() * 1000);
    }
    this.elementRef.nativeElement.ownerDocument.body.style.setProperty('--primary-blue', primColor);

    if (color == "random") { // Recursive
      setTimeout(() => { this.chooseColor("random") }, 500);
    }
  }

}
