import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";
import { CustomerService } from "src/app/shared/api/customer.service";
import { CustomerPricesheetService } from "src/app/shared/api/customerpricesheet.service";
import { DataShareService } from "src/app/shared/api/datashare.service";
import { OilService } from "src/app/shared/api/oil.service";
import { PricesheetService } from "src/app/shared/api/pricesheet.service";
import { ReturnCalcService } from "src/app/shared/api/returncalc.service";
import { RulesService } from "src/app/shared/api/rules.service";
import { Customer } from "src/app/shared/Models/customer";
import { CustomerPriceSheet } from "src/app/shared/Models/customerPriceSheet";
import { OilGroup } from "src/app/shared/Models/oilGroup";
import { OilSheet } from "src/app/shared/Models/oilSheet";
import { Pricesheet } from "src/app/shared/Models/pricesheet";
import { ReturnCalcMethod } from "src/app/shared/Models/returnCalcMethod";
import { Rule } from "src/app/shared/Models/rule";

@Component({
    selector: 'pricesheet-dialog',
    templateUrl: 'customer-settings.dialog.html',
    styleUrls: ['./customer-settings.dialog.scss']
})
export class CustomerSettingsDialog implements OnInit {

    customers: Customer[] = [];
    filteredOptions: Observable<Customer[]>;
    customControl = new FormControl();
    selectedCustomer?: Customer;
    oilGroups: OilGroup[] = [];
    selectedOilGroup?: OilGroup = {};
    rule: Rule = {};
    oilSheets: OilSheet[] = [];
    allOilSheets: OilSheet[] = [];
    pricesheets: any;
    displayedColumns: string[] = ["procent", "erpCode", "date"];
    calcZone?: string;
    calcPercentReturn?: any;
    calcPercentOutside?: any;
    calcId: any;
    customerPricesheets?: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: Customer, public dialogRef: MatDialogRef<CustomerSettingsDialog>, private dataService: DataShareService,
        private ruleService: RulesService,
        private pricesheetService: PricesheetService,
        private dataShare: DataShareService,
        private oilService: OilService,
        private _snackBar: MatSnackBar,
        private customerService: CustomerService,
        private customerPricesheetService: CustomerPricesheetService,
        private returnCalcService: ReturnCalcService) {
        this.filteredOptions = new Observable<Customer[]>();
        this.selectedCustomer = this.data;
    }

    ngOnInit(): void {
        this.fetchData();
    }

    fetchData() {
        this.getAllOilGroups();
        this.getRulesByCustomer();
        this.getCustomerPricesheets();
        this.getStandardPricesheets();
        this.fetchCustomerReturnCalc();
    }


    fetchCustomerReturnCalc() {
        if (this.selectedCustomer) {
            if (this.selectedCustomer.returnCalcMethods != undefined && this.selectedCustomer.returnCalcMethods.length > 0) {
                this.selectedCustomer.returnCalcMethods.sort((a: any, b: any) => (a.fk_pricesheetId > b.fk_pricesheetId) ? 1 : -1);
                for (let i = 0; i < this.selectedCustomer.returnCalcMethods.length; i++) {
                    if (this.selectedCustomer.returnCalcMethods[i].fk_pricesheetId == null) {
                        this.calcId = this.selectedCustomer.returnCalcMethods[i].id;
                        this.calcZone = this.selectedCustomer.returnCalcMethods[i].calcZone!;
                        this.calcPercentReturn = this.selectedCustomer.returnCalcMethods[i].calcPercentReturn!;
                        this.calcPercentOutside = this.selectedCustomer.returnCalcMethods[i].calcPercentOutside!;
                        break;
                    }
                }
            }
        }
    }

    close() {
        this.dialogRef.close();
    }

    togglePricesheet(pricesheet: any) {
        if (pricesheet) {
            let customerPricesheet: CustomerPriceSheet = {
                licenseRef: this.dataShare.License,
                fk_customerId: this.selectedCustomer?.id,
                fk_pricesheetId: pricesheet.id,
                pricesheet: pricesheet,
                priority: 0,
            };
            this.customerPricesheets.push(customerPricesheet);
            for (let i = 0; i < this.pricesheets.length; i++) {
                if (this.pricesheets[i].id == pricesheet.id) {
                    this.pricesheets.splice(i, 1);
                }
            }
        }
    }

    toggleCustomerPricesheet(customerPricesheet: any) {
        if (customerPricesheet.pricesheet.fk_pricesheetTypeId != 6 && customerPricesheet.pricesheet.fk_pricesheetTypeId != 1) {
            this._snackBar.open('Slet arket for at fjerne kunde til prisark relation ', 'Afvist', {panelClass: ['snack-bar']}); // Translate
            return;
        }
        if (customerPricesheet) {
            let pricesheet: Pricesheet = customerPricesheet.pricesheet;
            this.pricesheets.push(pricesheet);
            for (let i = 0; i < this.customerPricesheets.length; i++) {
                if (this.customerPricesheets[i].pricesheet.id == customerPricesheet.pricesheet.id) {
                    this.customerPricesheets.splice(i, 1);
                }
            }
        }
    }

    saveCustomerPricesheets() {
        if (this.customerPricesheets && this.customerPricesheets.length > 0) {
            this.customerPricesheetService.bulkSaveOrUpdate(this.customerPricesheets).then((res: any) => {
                if (res) {
                    this.fetchData();
                    this._snackBar.open("Kunde Ark Gemt", "Sucess", { duration: 1500, panelClass: ['snack-bar'] });
                    return;
                }
            });
        }
        if (!this.customerPricesheets || this.customerPricesheets.length <= 0) {
            this.customerPricesheetService.deleteByCustomer(this.selectedCustomer!.id!, this.dataShare.License).then((res: any) => {
                if (res) {
                    this.fetchData();
                    this._snackBar.open("Alle Ark relationer fjernet", "Success", {panelClass: ['snack-bar']});
                    return;
                }
            });
        }
    }

    getStandardPricesheets() {
        let pricesheet: Pricesheet = {
            licenseRef: this.dataShare.License,
            includeCustomerPricesheets: true
        };
        this.pricesheetService.getAllDefaultPricesheets(pricesheet).subscribe((res) => {
            if (res) {
                this.pricesheets = res.modelObject;
                for (let i = 0; i < this.pricesheets.length; i++) {
                    this.pricesheets[i].checked = false;
                }
            }

            if (this.customerPricesheets) {
                for (let a = 0; a < this.customerPricesheets.length; a++) {
                    for (let b = 0; b < this.pricesheets.length; b++) {
                        if (this.customerPricesheets[a].fk_pricesheetId == this.pricesheets[b].id) {
                            this.pricesheets[b].checked = true;
                            this.pricesheets[b].HasBeenAdded = true;
                            this.pricesheets.splice(b, 1);
                        }
                    }
                }
                this.pricesheets.sort((x: any, y: any) => (x.priority > y.priority) ? 1 : (x.priority == y.priority) ? 0 : -1);
            }
        });
    }


    getCustomerPricesheets() {
        let customerPricesheet: CustomerPriceSheet = {
            licenseRef: this.dataShare.License,
            fk_customerId: this.selectedCustomer?.id,
            includePricesheet: true
        };
        this.customerPricesheetService.getAllByCustomer(customerPricesheet).then((res: any) => {
            if (res) {
                this.customerPricesheets = res.modelObject;
                this.customerPricesheets.sort((x: any, y: any) => (x.priority > y.priority) ? 1 : (x.priority == y.priority) ? 0 : -1);
            }
        });

    }

    getAllOilSheetsByCustomer() {
        let oilsheet: OilSheet = {
            fk_customerId: this.selectedCustomer?.id,
            licenseRef: this.dataService.License
        };

        this.oilService.getAllOilSheetsByCustomer(oilsheet).then((res) => {
            if (res) {
                this.oilSheets = res.modelObject;
                this.oilSheets.sort((a: any, b: any) => (a.date! > b.date!) ? -1 : (a.date == b.date) ? 0 : 1);
                this.oilSheets.unshift({});
                for (let i = 0; i < this.oilSheets.length; i++) {
                    Object.defineProperty(this.oilSheets[i], "editable", { value: true });
                }
            }
        });
    }

    getRulesByCustomer() {
        if (this.selectedCustomer) {
            let rule: Rule = {
                fk_customerId: this.selectedCustomer.id,
                licenseRef: this.dataService.License,
                oilpaydatetype: 0,
            };
            this.ruleService.getByCustomer(rule).then((res: any) => {
                if (res && res.modelObject) {
                    this.rule = res.modelObject;
                }
            });
        }
    }

    dropCust(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.customerPricesheets!, event.previousIndex, event.currentIndex);

    }
    getAllOilGroups() {
        let oilGroup: OilGroup = {
            licenseRef: this.dataService.License,
            includeOilSheets: true
        }
        this.oilService.getAllOilGroup(oilGroup).then((res) => {
            this.oilGroups = res.modelObject;
            this.oilGroups.sort((a, b) => (a.date! > b.date!) ? -1 : (a.date == b.date) ? 0 : 1);
            this.oilGroups.unshift({ name: "Ingen valgt" }); // Translate
            if (this.selectedCustomer?.fk_oilGroupId) {
                this.selectedOilGroup = this.oilGroups.find(x => x.id == this.selectedCustomer?.fk_oilGroupId);
            }
        });
    }

    getAllOilGroupsByCustomer() {
        let oilGroup: OilGroup = {
            licenseRef: this.dataService.License,
            includeOilSheets: true
        }
        this.oilService.getAllOilGroupByCustomer(oilGroup).then((res) => {
            this.oilGroups = res.modelObject;
            this.oilGroups.sort((a, b) => (a.date! > b.date!) ? -1 : (a.date == b.date) ? 0 : 1);
            this.oilGroups.unshift({ name: "Ingen valgt" }); // translate
        });
    }

    selectOilGroup(oilGroup: any) {
        if (oilGroup.id) {
            if (oilGroup.oilSheets != null && oilGroup.oilSheets.length > 0) {
                this.oilSheets = oilGroup.oilSheets.filter((x: any) => x.fk_customerId == this.selectedCustomer?.id);
            }
            else {
                this.oilSheets.unshift({});
            }
        }
    }


    saveCustomerCalcRules() {
        if (this.selectedCustomer) {
            const returnCalc: ReturnCalcMethod = {
                calcZone: this.calcZone,
                calcPercentReturn: this.calcPercentReturn,
                calcPercentOutside: this.calcPercentOutside,
                customer: this.selectedCustomer,
                licenseRef: this.dataService.License,
                fk_customerId: this.selectedCustomer!.id
            };
            if (this.calcId) {
                returnCalc.id = this.calcId;
            }
            this.returnCalcService.saveOrUpdate(returnCalc).then((res: any) => {
                this.calcZone = res.modelObject.calcZone;
                this.calcPercentOutside = res.modelObject.calcPercentOutside;
                this.calcPercentReturn = res.modelObject.calcPercentReturn;
                this._snackBar.open("Retur Beregning gemt på kunden", "Success", { duration: 1000, panelClass: ['snack-bar'] }); // Translate
            });
        }
        else {
            console.error("Failed to Save!");
        }
    }

    closeCustomerSettings() {
        this.dialogRef.close();
    }

    saveOilTab() {
        if (this.selectedOilGroup && this.selectedCustomer) {
            this.customerService.updateCustomerOilGroup(this.selectedCustomer!.id!, this.selectedOilGroup.id!, this.dataShare.License).then((res: any) => {
                if (res) {
                    this._snackBar.open("Olie er gemt på kunden", "Success", {panelClass: ['snack-bar']});
                }
            });
        }
    }

}