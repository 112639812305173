import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'terminal-dialog',
    templateUrl: 'new-oil.dialog.html'
})
export class NewOilDialog {
    name: string = '';
    saveBtnDisabled = true;
    dialogData = <any>{};
    procent: any; date: Date | any; erpCode: any;

    constructor(public dialogRef: MatDialogRef<NewOilDialog>, private datepipe: DatePipe) {
        this.date = this.datepipe.transform(new Date(), 'yyyy-MM-dd')!;
     }

    save() {
        var tzoffset = (this.date!).getTimezoneOffset() * 60000; //offset in milliseconds
        var localISOTime = (new Date(this.date!.getTime() - tzoffset)).toISOString().slice(0, -1);
        
        this.dialogData = { name: this.name, procent: this.procent, date: localISOTime, erpCode: this.erpCode };
        this.dialogRef.close(this.dialogData);
    }

    close() {
        this.dialogRef.close();
    }

}
