import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataShareService } from '../shared/api/datashare.service';
import { ReturnCalcService } from '../shared/api/returncalc.service';
import { Customer } from '../shared/Models/customer';
import { ReturnCalcMethod } from '../shared/Models/returnCalcMethod';
import { ConfirmDialogComponent } from '../shared/util/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-price-calc',
  templateUrl: './price-calc.component.html',
  styleUrls: ['./price-calc.component.scss']
})
export class PriceCalcComponent implements OnInit {

  @Input() customer = <Customer>{};
  calcZone?: string;
  calcPercentReturn?: any;
  calcPercentOutside?: any;
  panelOpenState: any;
  returnCalcId: any;

  constructor(
    private data: DataShareService,
    private returnCalcService: ReturnCalcService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.panelOpenState = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getCalcByCustomer();
  }


  getCalcByCustomer() {
    if (this.customer) {
      if (this.customer.returnCalcMethods != undefined && this.customer.returnCalcMethods.length > 0) {
        this.customer.returnCalcMethods.sort((a: any, b: any) => (a.fk_pricesheetId > b.fk_pricesheetId) ? -1 : 1);

        for (let i = 0; i < this.customer.returnCalcMethods.length; i++) {
          if (this.customer.returnCalcMethods[i].fk_pricesheetId == this.data.selectedPricesheet.id) {
            this.returnCalcId = this.customer.returnCalcMethods[i].id;
            this.calcZone = this.customer.returnCalcMethods[i].calcZone!;
            this.calcPercentReturn = this.customer.returnCalcMethods[i].calcPercentReturn!;
            this.calcPercentOutside = this.customer.returnCalcMethods[i].calcPercentOutside!;
            break;
          }
          if (this.customer.returnCalcMethods[i].fk_pricesheetId == null) {
            this.returnCalcId = this.customer.returnCalcMethods[i].id;
            this.calcZone = this.customer.returnCalcMethods[i].calcZone!;
            this.calcPercentReturn = this.customer.returnCalcMethods[i].calcPercentReturn!;
            this.calcPercentOutside = this.customer.returnCalcMethods[i].calcPercentOutside!;
            break;
          }
        }
      }
    }
  }

  saveCustomerCalcRules() {
    var returnCalc = this.createReturnCalc();
    this.returnCalcService.saveOrUpdate(returnCalc).then((res) => {
      this.calcZone = res.modelObject.calcZone;
      this.calcPercentOutside = res.modelObject.calcPercentOutside;
      this.calcPercentReturn = res.modelObject.calcPercentReturn;
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: "(Gen)beregn Prisarket?",
          message: "<b>Prisark:</b> " + this.data.selectedPricesheet.name + "<br/><b>Retur Zone: </b>"
            + (this.calcZone != null ? this.calcZone : "") + "<br/><b>Retur Procent:</b> "
            + (this.calcPercentReturn != null ? this.calcPercentReturn : "") + "%<br/><b>Kunde til Kunde Procent:</b> "
            + (this.calcPercentOutside != null ? this.calcPercentOutside : "") + "%"
        }
      });
      dialogRef.afterClosed().subscribe((dialogData) => {
        if (dialogData) {
          if (this.data.WorkZones && this.data.WorkPrices) {
            this.data.startSerialization();
          }
        }
      })
    }).finally(() => {
      this._snackBar.open("Kunde Beregnings Regler Gemt", "Success", {panelClass: ['snack-bar']});
    });
  }


  createReturnCalc() {
    const returnCalc: ReturnCalcMethod = {
      id: this.returnCalcId != null ? this.returnCalcId : null,
      calcZone: this.calcZone,
      calcPercentReturn: this.calcPercentReturn,
      calcPercentOutside: this.calcPercentOutside,
      customer: this.customer,
      licenseRef: this.data.License,
      fk_customerId: this.customer.id
    };
    if (this.data.selectedPricesheet) {
      returnCalc.fk_pricesheetId = this.data.selectedPricesheet.id;
    };
    return returnCalc;
  }
}
