import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataShareService } from "src/app/shared/api/datashare.service";

@Component({
    selector: 'edit-pricesheet',
    templateUrl: 'edit-pricesheet.dialog.html',
    styleUrls: ['edit-pricesheet.dialog.scss']
})
export class EditPricesheetDialog {
    name: string = '';
    saveBtnDisabled = true;
    dialogData: any;
    selectedPricesheet: any;

    constructor(@Inject(MAT_DIALOG_DATA) public pricesheet: any, public dialogRef: MatDialogRef<EditPricesheetDialog>, private data: DataShareService) {
        this.selectedPricesheet = this.pricesheet;
    }

    save() {
        this.dialogRef.close(this.selectedPricesheet);
    }

    close() {
        this.dialogRef.close();
    }

}
