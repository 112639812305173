import { Injectable } from "@angular/core";
import { PriceCalcUnit } from "../Models/priceCalcUnit";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})
export class PriceCalcUnitService {
    constructor(private api: ApiService) {}

async getAll(priceCalcUnit: PriceCalcUnit){
    return await this.api.post("/pricecalcunit/getall", priceCalcUnit).toPromise();
}



}