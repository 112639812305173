export interface ColliType {
    id?: number;
    licenseRef?: string;
    name?: string;
    ref?: string; 
    colliTypeTranslate?: ColliTypeTranslate;
    includeColliTypeTranslates?: boolean;
}
export interface ColliTypeTranslate {
    id?: number;
    licenseRef: string;
    name: string;
    fk_colliTypeId?: number;
    customerRef?: string;
    includeColliType?: boolean;
}

export class ColliTypeClass {
    id: number;
    licenseRef: string;
    name: string;
    ref: string; 
    colliTypeTranslate: ColliTypeTranslate;
    includeColliTypeTranslates: boolean;
    constructor(colliType: any){
        this.id = colliType.id;
        this.licenseRef = colliType.licenseRef;
        this.name = colliType.name;
        this.ref = colliType.ref;
        this.colliTypeTranslate = colliType.colliTypeTranslate;
        this.includeColliTypeTranslates = colliType.includeColliTypeTranslates;
    }
}