import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SpreadsheetComponent } from '@syncfusion/ej2-angular-spreadsheet';
import { Subscription } from 'rxjs';
import { CountryService } from '../shared/api/country.service';
import { DataShareService } from '../shared/api/datashare.service';
import { HandlingTypeService } from '../shared/api/handlingtype.service';
import { PricesheetzonesService } from '../shared/api/pricesheetzones.service';
import { TerminalService } from '../shared/api/terminal.service';
import { Country } from '../shared/Models/country';
import { Currency } from '../shared/Models/currency';
import { Pricesheet } from '../shared/Models/pricesheet';
import { HandlingType, PricesheetZone, PricesheetZoneViewModel } from '../shared/Models/pricesheetZone';
import { Terminal } from '../shared/Models/terminal';

@Component({
  selector: 'app-pricesheetzones',
  templateUrl: './pricesheetzones.component.html',
  styleUrls: ['./pricesheetzones.component.scss'],
})
export class PricesheetzonesComponent implements OnInit, OnChanges, OnDestroy {
  zones: PricesheetZoneViewModel[] = [];
  currencies: Currency[] = [];
  countries: Country[] = [];
  serializedZones: PricesheetZone[] = [];
  handlingTypes: HandlingType[] = [];
  terminals: Terminal[] = [];
  displayedColumns: string[] = ["fromZipFrom", "fromZipTo", "toZipFrom", "toZipTo", "zoneNo"];

  @Input() pricesheet = <Pricesheet>{};
  countryRequest = <Country>{};
  emptyZone = <PricesheetZone>{};
  chosenCountryFrom: any; chosenCountryTo: any; maxRow: any;
  filteredZonesObservable?: Subscription;
  showHidePrint?: boolean;
  editAll: any = false;
  rowCount: any;
  @ViewChild('spreadsheet') spreadsheetObj!: SpreadsheetComponent;

  constructor(private pricesheetZonesService: PricesheetzonesService,
    public data: DataShareService,
    private countryService: CountryService,
    private translate: TranslateService,
    private terminalService: TerminalService,
    private handlingTypeService: HandlingTypeService) { }

  ngOnDestroy(): void {
    this.zones = [];
    this.filteredZonesObservable!.unsubscribe();
    this.data.turnOffPrintToggle();
  }
  ngOnInit(): void {
    this.filteredZonesObservable = this.data.filteredZones$.subscribe((res) => {
      this.copyZonesToViewModel(res);
    });
    this.getHandlingTypes();
    this.getCountries();
    this.getTerminals();
    this.data.SerializeZones.subscribe((val: any) => {
      if (val) {
        this.checkExtraRows();
      }
    });
  }


  cellEdit(event: any) {
    var rowNo = event.address.substring(7);
    if (rowNo == this.maxRow) {
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=CountryFrom!D2:D" + (this.countries.length + 1) }, 'E2:E' + (+rowNo + 1));
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=CountryFrom!D2:D" + (this.countries.length + 1) }, 'B2:B' + (+rowNo + 1));
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=HandlingType!B2:B" + (this.handlingTypes.length + 1) }, 'I2:I' + (+rowNo + 1));
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=Terminals!C2:C" + (this.terminals.length + 1) }, 'J2:J' + (+rowNo + 1));
      this.maxRow++;
    }
  }

  checkExtraRows() {
    var json = this.spreadsheetObj.saveAsJson();
    var rows: any[] = [];
    json.then((val: any) => {
      this.zones = [];
      for (let i = 1; i < val.jsonObject.Workbook.sheets[0].rows.length; i++) {
        if (!val.jsonObject.Workbook.sheets[0].rows[i]) {
          break;
        }
        rows.push(val.jsonObject.Workbook.sheets[0].rows[i]);
      }
      for (let j = 0; j < rows.length; j++) {
        if (rows[j].cells.length < 7) {
          break;
        }

        if (rows[j].cells[1]?.value == undefined || rows[j].cells[2]?.value == undefined || rows[j].cells[3]?.value == undefined ||
          rows[j].cells[4]?.value == undefined || rows[j].cells[5]?.value == undefined || rows[j].cells[6]?.value == undefined || rows[j].cells[7]?.value == undefined) {
          continue;
        }

        if (rows[j].cells.slice(1).every((el: any) => el != null)) {
          if (rows[j].cells[2]?.value.toString().includes("'")) {
            rows[j].cells[2].value = rows[j].cells[2].value.toString().replace("'", "");
          }
          if (rows[j].cells[3]?.value.toString().includes("'")) {
            rows[j].cells[3].value = rows[j].cells[3].value.toString().replace("'", "");
          }
          if (rows[j].cells[5]?.value.toString().includes("'")) {
            rows[j].cells[5].value = rows[j].cells[5].value.toString().replace("'", "");
          }
          if (rows[j].cells[6]?.value.toString().includes("'")) {
            rows[j].cells[6].value = rows[j].cells[6].value.toString().replace("'", "");
          }
          var newZone = {
            id: rows[j].cells[0] != null ? rows[j].cells[0].value : null,
            countryFrom: rows[j].cells[1].value,
            fromZipFrom: rows[j].cells[2].value,
            fromZipTo: rows[j].cells[3].value,
            countryTo: rows[j].cells[4].value,
            toZipFrom: rows[j].cells[5].value,
            toZipTo: rows[j].cells[6].value,
            zoneNo: rows[j].cells[7].value,
            handlingType: rows[j].cells[8] != null ? rows[j].cells[8].value : null,
            terminal: rows[j].cells[9] != null ? rows[j].cells[9].value : null
          } as PricesheetZoneViewModel;
          newZone.fk_pricesheetId = this.data.selectedPricesheet.id;
          this.zones.push(newZone);
        }
      }
      this.data.WorkZones = this.zones;
      this.data.doneSerializingPrices();
    });
  }

  dataSourceChanged(event: any): void {
    if (event.action == "edit") {
      if (event.data) {
        for (let i = 0; i < event.data!.length; i++) {
          for (let j = 0; j < this.zones.length; j++) {
            if (this.zones[j].id == event.data[i].id) {
              this.zones[j] = event.data[i] as PricesheetZone;
            }

          }
        }
        this.data.WorkZones = this.zones;
      }
    }
    if (event.action == "dataSourceChanged") {
      this.setHeaderRow();
      this.setDataValidation();
    }
  }

  copyZonesToViewModel(zones: PricesheetZone[]) {
    const tmpArray = [];
    for (let i = 0; i < zones.length; i++) {
      const zone = {
        id: zones[i].id ? zones[i].id : null,
        countryFrom: zones[i].countryFrom,
        fromZipFrom: zones[i].fromZipFrom,
        fromZipTo: zones[i].fromZipTo,
        countryTo: zones[i].countryTo,
        toZipFrom: zones[i].toZipFrom,
        toZipTo: zones[i].toZipTo,
        zoneNo: zones[i].zoneNo,
        handlingType: zones[i].handlingType,
        terminal: zones[i].terminal
      } as PricesheetZoneViewModel;
      tmpArray.push(zone);
    }
    this.zones = tmpArray;
    this.rowCount = this.zones.length + 500;
    this.maxRow = (this.zones.length + 2);
    this.data.WorkZones = this.zones;
  }

  created() {
    this.setHeaderRow();
  }

  setDataValidation() {
    if (this.countries) {
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=CountryFrom!D2:D" + (this.countries.length + 1) }, 'E2:E' + (this.zones.length + 2));
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=CountryFrom!D2:D" + (this.countries.length + 1) }, 'B2:B' + (this.zones.length + 2));
    }
    if (this.handlingTypes) {
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=HandlingType!B2:B" + (this.handlingTypes.length + 1) }, 'I2:I' + (this.zones.length + 2));
    }
    if (this.terminals) {
      this.spreadsheetObj.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=Terminals!C2:C" + (this.terminals.length + 1) }, 'J2:J' + (this.zones.length + 2));
    }


  }

  setHeaderRow() {
    this.spreadsheetObj.cellFormat({ fontWeight: 'bold', fontSize: '14px' }, 'B1:L1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('COUNTRYFROM') }, 'B1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('FROMCOUNTRYCODE') }, 'C1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('TOCOUNTRYCODE') }, 'D1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('COUNTRYTO') }, 'E1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('FROMZONE') }, 'F1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('TOZONE') }, 'G1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('ZONENO') }, 'H1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('DRIVERTYPE') }, 'I1');
    this.spreadsheetObj.updateCell({ index: 1, value: this.translate.instant('TERMINAL') }, 'J1');
  }

  getHandlingTypes() {
    let handlingType: HandlingType = {
      licenseRef: this.data.License
    };
    this.handlingTypeService.getAll(handlingType).then((res) => {
      this.handlingTypes = res.modelObject;
    });
  }

  getZones() {
    if (this.pricesheet) {
      let pricesheetZone: PricesheetZone = {
        licenseRef: this.pricesheet.licenseRef,
        includeCollitypeCategory: true,
        includeCountryFrom: true,
        includeCountryTo: true,
        includeTerminal: true,
      }
      this.pricesheetZonesService.getPricesheetZonesView(this.pricesheet.id!, pricesheetZone).subscribe(res => {
        this.setPriceSheetZoneDates(res.modelObject);
        for (let i = 0; i < res.modelObject.length; i++) {
          res.modelObject[i].countryFrom = this.checkCountry(res.modelObject[i].countryFrom);
          res.modelObject[i].countryTo = this.checkCountry(res.modelObject[i].countryTo);
          res.modelObject[i].handlingType = this.checkHandlingType(res.modelObject[i].handlingType);
          res.modelObject[i].terminal = this.checkTerminal(res.modelObject[i].terminal);
        }
        this.data.setZones(res.modelObject);
      });
    }
  }

  checkCountry(count: any): any {
    if (count) {
      for (let i = 0; i < this.countries.length; i++) {
        if (count.isocode == this.countries[i].isocode) {
          return this.countries[i].isocode;
        }
      }
    }
  }

  checkTerminal(term: any): any {
    if (term) {
      for (let i = 0; i < this.terminals.length; i++) {
        if (term.id == this.terminals[i].id) {
          return this.terminals[i].name;
        }
      }
    }
  }

  checkHandlingType(ht: any): any {
    if (ht) {
      for (let i = 0; i < this.handlingTypes.length; i++) {
        if (ht.id == this.handlingTypes[i].id) {
          return this.handlingTypes[i].name;
        }
      }
    }
  }

  setPriceSheetZoneDates(zones: any) {
    for (let i = 0; i < zones.length; i++) {
      if (!this.data.ZoneDates.includes(zones[i].validFrom)) {
        this.data.ZoneDates.push(zones[i].validFrom);
      }
    }
    if (this.data.ZoneDates.length > 0) {
      this.data.setSelectedDate(this.data.ZoneDates[0]);
    }
    else {
      this.data.ZoneDates.push(this.data.selectedPricesheet.validFrom);
      this.data.setSelectedDate(this.data.selectedPricesheet.validFrom);
    }
  }



  getCountries() {
    this.countryRequest.licenseRef = this.data.License;
    this.countryService.getCountries(this.countryRequest).then((res: any) => {
      this.countries = res.modelObject;
      this.countries.sort((a, b) => (a.isocode! > b.isocode!) ? 1 : (a.isocode == b.isocode) ? 0 : -1);
    });
  }

  getTerminals() {
    let terminal: Terminal = {
      licenseRef: this.data.License
    };
    this.terminalService.getAll(terminal).then((res) => {
      this.terminals = res.modelObject;
    })
  }

  selectCountryFrom(country: any) {
    if (country) {
      for (let i = 0; i < this.zones.length; i++) {
        this.zones[i].countryFrom = country;
        this.spreadsheetObj.updateCell({ value: country.isocode }, 'B' + (i + 2));
      }
    }
  }
  selectCountryTo(country: any) {
    if (country) {
      for (let i = 0; i < this.zones.length; i++) {
        this.zones[i].countryTo = country;
        this.spreadsheetObj.updateCell({ value: country.isocode }, 'E' + (i + 2));
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getZones();
  }

}