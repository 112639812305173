<div>
    <div class="flexColumn">
      <mat-form-field class="formField">
          <mat-label style="color:black;">{{'REUTRNZONE'|translate}} </mat-label>
              <input matInput [disabled]="(calcPercentReturn != null && calcPercentReturn != '') || (calcPercentOutside != null && calcPercentOutside != '')" [(ngModel)]="calcZone">
      </mat-form-field>
      <mat-form-field class="formField">
          <mat-label style="color:black;">{{'REUTRNPERCENT'|translate}} </mat-label>
              <input [disabled]="calcZone != null && calcZone != ''"  matInput [(ngModel)]="calcPercentReturn">
      </mat-form-field>
      <mat-form-field class="formField">
          <mat-label style="color:black;">{{'REUTRNPERCENTCUSTOMERTOCUSTOMER'|translate}} </mat-label>
              <input [disabled]="calcZone != null && calcZone != ''" matInput [(ngModel)]="calcPercentOutside">
      </mat-form-field>
    </div>
    <div class="saveBtn">
        <button (click)="saveCustomerCalcRules()" mat-raised-button>{{'SAVE' | translate}}</button>
    </div>
</div>
