import { DatePipe } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CustomerService } from "src/app/shared/api/customer.service";
import { DataShareService } from "src/app/shared/api/datashare.service";
import { PricesheetService } from "src/app/shared/api/pricesheet.service";
import { Customer } from "src/app/shared/Models/customer";
import { Pricesheet } from "src/app/shared/Models/pricesheet";

@Component({
    selector: 'pricesheet-dialog',
    templateUrl: 'new-pricesheet.dialog.html'
})
export class NewPricesheetDialog {
    name: any;
    saveBtnDisabled = true;
    dialogData = <any>{};
    customers: any;
    selectedCustomer?: Customer;
    selectedPricesheet?: Pricesheet;
    pricesheets?: Pricesheet[];
    newPricesheetName: any;
    htFromToZip: any;
    date: any;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<NewPricesheetDialog>,
        private dataShare: DataShareService,
        private customerService: CustomerService,
        private datePipe: DatePipe,
        private pricesheetService: PricesheetService
    ) {
        this.getAllCustomers();
        const date = new Date();
        this.date = this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    save() {
        if (this.selectedPricesheet) {
            this.selectedPricesheet.id = undefined;
            if (this.newPricesheetName) {
                this.selectedPricesheet!.name = this.newPricesheetName;
            }
            if(this.htFromToZip == true){
                this.selectedPricesheet!.htFromToZip = this.htFromToZip;
            }
        }
        this.dialogRef.close({date: this.date, name: this.name, pricesheet: this.selectedPricesheet, htFromToZip: this.htFromToZip, saved: true});
    }

    selectCustomer(cust: any) {
        if (cust) {
            this.selectedCustomer = cust;
            let pricesheet: Pricesheet = {
                licenseRef: this.selectedCustomer?.licenseRef,
                includePricesheetPrices: true,
                includePricesheetZones: true,
                includePricesheetType: true
            };
            this.pricesheetService.getPriceSheetsByCustomer(this.selectedCustomer?.id!, pricesheet).then((res: any) => {
                if (res) {
                    this.pricesheets = res.modelObject;
                }
            });
        }
    }

    selectPricesheet(pricesheet: any) {
        if (pricesheet) {
            this.newPricesheetName = pricesheet.name.replace(this.selectedCustomer!.name, this.data);
            this.selectedPricesheet = pricesheet;
            this.date = this.datePipe.transform(this.selectedPricesheet?.validFrom, 'yyyy-MM-dd');
        }
    }


    getAllCustomers() {
        let customer: Customer = {
            licenseRef: this.dataShare.License,
        };
        this.customerService.getCustomers(customer).then((res: any) => {
            if (res) {
                let customers = res.modelObject;
                customers.sort((a: Customer, b: Customer) => {
                    if (a.clientObject == undefined && b.clientObject == undefined) return 0;
                    if (a.clientObject == undefined) return -1;
                    if (b.clientObject == undefined) return 1;
                    if ((a.clientObject?.name == undefined || a.clientObject?.name == "") && (b.clientObject?.name == undefined || b.clientObject?.name == "")) return 0;
                    if (a.clientObject?.name == undefined || a.clientObject?.name == "") return -1;
                    if (b.clientObject?.name == undefined || b.clientObject?.name == "") return -1;
                    if (a.clientObject?.name!.toLowerCase() > b.clientObject?.name!.toLowerCase()) {
                        return -1;
                    }
                    if (a.clientObject?.name!.toLowerCase() < b.clientObject?.name!.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
                for (let i = 0; i < customers.length; i++) {
                    if (customers[i].clientObject?.name) {
                        customers[i].name = customers[i].clientObject.name;
                    }
                }
                // Sort Alphabetically
                this.customers = customers.sort((a: any, b: any) => { if (a.name > b.name) return 1; if (b.name > a.name) return -1; return 0; });
            }
        });
    }

    close() {
        this.dialogRef.close();
    }

}
