import { Injectable } from "@angular/core";
import { Omex, OmexProduct } from "../Models/omex";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})
export class OmexService {
    constructor(private api: ApiService) { }

    // Omex
    async getAll(omex: Omex) {
        return await this.api.post("/omex/getall", omex).toPromise();
    }

    async saveOrUpdate(omex: Omex) {
        return await this.api.post("/omex/saveorupdate", omex).toPromise();
    }

    async saveOrUpdateWithProducts(omex: Omex) {
        return await this.api.post("/omex/saveorupdatewithProducts", omex).toPromise();
    }

    async bulkSaveOrUpdate(omices: Omex[]) {
        return await this.api.post("/omex/bulkSaveOrUpdate", omices).toPromise();
    }

    async delete(omex: Omex) {
        return await this.api.delete("/omex/delete?id=" + omex.id + "&licenseRef=" + omex.licenseRef).toPromise();
    }

    // OmexProduct
    async getAllOmexProducts(omexProd: OmexProduct) {
        return await this.api.post("/omexProduct/getAll", omexProd).toPromise();
    }

}