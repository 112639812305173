import { Injectable } from "@angular/core";
import { CapacityTax } from "../Models/capacityTax";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})
export class CapacityTaxService {
    constructor(private api: ApiService) {}

async getAll(capacityTax: CapacityTax){
    return await this.api.post("/capacitytax/getall", capacityTax).toPromise();
}

async getAllByCustomer(customerId: number, capacityTax: CapacityTax){
    return await this.api.post("/capacitytax/getAllByCustomer?customerId=" + customerId, capacityTax).toPromise();
}

async delete(capacityTax: CapacityTax){
    return await this.api.delete("/capacitytax/delete?id=" + capacityTax.id + "&licenseRef=" + capacityTax.licenseRef).toPromise();
}

async saveOrUpdate(capacityTax: CapacityTax){
    return await this.api.post("/capacitytax/saveOrUpdate", capacityTax).toPromise();
}

async bulkSaveOrUpdate(capacityTaxes: CapacityTax[]){
    return await this.api.post("/capacitytax/bulkSaveOrUpdate", capacityTaxes).toPromise();
}

async getLatest(capacityTax: CapacityTax){
    return await this.api.post("/capacitytax/getlatest", capacityTax).toPromise();
}
}