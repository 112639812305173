import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from "src/environments/environment";
import { AuthService } from '../shared/api/auth.service';
import { DataShareService } from '../shared/api/datashare.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  selectedLicense?: number;
  password?: string;
  license: string = '';

  @ViewChild('licenseField') licenseField!: ElementRef;

  constructor(private router: Router, private _snackBar: MatSnackBar, private dataShare: DataShareService, private auth: AuthService) {

  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    if (this.dataShare.License) {
      this.router.navigateByUrl('/prices');
    }
  }


  // Hardcoding will be removed when Actual Auth flow is build
  doLogin() {
    switch (this.license.toUpperCase()) {
      case 'TJW':
        if ((this.password == 'L9V!rb') || (this.password == environment.password)) {
          this.login('A47F92DB-7E46-462F-862F-69B6F07D478E', 'TJW');
        }
        else {
          this._snackBar.open("Fejl i oplysninger, Prøv igen ", "Fejl", { panelClass: ['snack-bar'] });
        }
        break;
      case 'PD':
        if ((this.password == 'gAxy4W') || (this.password == environment.password)) {
          this.login('E9583DDB-13C4-461A-AFEB-5BBB12F25947', 'Per Damgård');
        }
        else {
          this._snackBar.open("Fejl i oplysninger, Prøv igen ", "Fejl", { panelClass: ['snack-bar'] });
        }
        break;
      case 'TJK':
        if ((this.password == 'c3E3Rm') || (this.password == environment.password)) {

          this.login('CAEF9437-7DF7-41B4-9097-E5611DE1D7AD', 'Team JK');
        }
        else {
          this._snackBar.open("Fejl i oplysninger, Prøv igen ", "Fejl", { panelClass: ['snack-bar'] });
        }
        break;
      case 'EGJ':
        if ((this.password == '5nEx9g') || (this.password == environment.password)) {
          this.login('187E0447-65EF-4ACC-A096-357DAC3BCA02', 'Jyderup Gods');
        }
        else {
          this._snackBar.open("Fejl i oplysninger, Prøv igen ", "Fejl", { panelClass: ['snack-bar'] });
        }
        break;
      default:
        this._snackBar.open('Forkerte oplysninger, prøv igen', "fejl", { panelClass: ['snack-bar'] });
        break;
    }
  }



  private login(license: string, customerChosen: string) {
    this.dataShare.License = license;
    this.dataShare.customerChosen = customerChosen;
    this.auth.login(license);
    if (this.dataShare.License) {
      this.router.navigateByUrl('/prices');
    }
  }

}
