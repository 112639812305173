import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CurrencyService } from '../shared/api/currency.service';
import { DataShareService } from '../shared/api/datashare.service';
import { ProductService } from '../shared/api/product.service';
import { ProductPriceService } from '../shared/api/productprice.service';
import { ProductUnitService } from '../shared/api/productunit.service';
import { Currency } from '../shared/Models/currency';
import { Product } from '../shared/Models/product';
import { ProductPrice } from '../shared/Models/productPrice';
import { ProductUnit } from '../shared/Models/productUnit';
import { ConfirmDialogComponent } from '../shared/util/confirm-dialog/confirm-dialog.component';
import { StandardProductDialog } from './standard-products-dialog/standard-products.dialog';

@Component({
  selector: 'app-standard-products',
  templateUrl: './standard-products.component.html',
  styleUrls: ['./standard-products.component.scss']
})
export class StandardProductsComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild("name") nameControl!: ElementRef;
  @ViewChild("number") numberControl!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  productprices: ProductPrice[] = [];
  productpricesDataSource!: MatTableDataSource<ProductPrice>;
  productprice = <ProductPrice>{};
  displayedColumns: string[] = ["name", "price", "minPrice", "percent", "valuta", "number", "productunit", "oil", "enviroment", "capacity", "freightLine", "action"];
  currencyRequest = <Currency>{};
  productUnitRequest = <ProductUnit>{};
  productUnits: ProductUnit[] = [];
  currencies: any; selectedCurrency: any; selectedDate: any;
  products: Product[] = [];
  pricesheetDates: Date[] = [];
  SNACK_BAR_DURATION: number = 2000;
  UNDEFINED_ACTION: undefined;
  loading: any;
  
  constructor(private _snackBar: MatSnackBar, private data: DataShareService, private currencyService: CurrencyService,
    private productUnitService: ProductUnitService, private productService: ProductService, private productPriceService: ProductPriceService, private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.getAllCurrencies();
    this.getAllProductUnits();
    this.getProductDates();
  }

  chooseDate(date: any) {
    this.getAllProducts();
    this.selectedDate = date;
  }

  checkData(productPrice: any) {
    if (productPrice.id > 0) {
      productPrice.editable = true;
    }
  }

  newProduct() {
    const dialogRef = this.dialog.open(StandardProductDialog, {
      minHeight: '600px'
    });


    dialogRef.afterClosed().subscribe((productPrice: any) => {
      if (productPrice) {
        productPrice.product.licenseRef = this.data.License;
        if (productPrice.product.productUnit) {
          productPrice.product.fk_productUnitID = productPrice.product.productUnit.id;
        }
        this.productService.saveFullProduct(productPrice.product).then((res: any) => {
          if (res) {
            productPrice.licenseRef = this.data.License;
            productPrice.product!.id = res.modelObject.id;
            productPrice.fk_productId = res.modelObject.id;
            if (productPrice.currency) {
              productPrice.fk_currencyId = productPrice.currency.id;
            }
            this.productPriceService.save(productPrice).then((res: any) => {
              if (res) {
              }
            }).finally(() => {
              this._snackBar.open("Produkt Gemt", "Success", { duration: 1500, panelClass: ['snack-bar'] });
              this.getAllProducts();
            });
          }
        });
      }
      else {
        // Do nothing
      }
    });
  }

  saveAll() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "16%",
      height: "18%",
      data: {
        title: "Er du sikker?", // Translate
        message: "Du er ved at gemme :  <b>alle produkter</b>" // Translate
      }
    });

    dialogRef.afterClosed().subscribe((dialogData) => {
      if (dialogData) {
        this.loading = true;
        const productArray: any[] = [];
        const productPricesArray: any[] = [];
        for (let i = 0; i < this.productpricesDataSource.data.length; i++) {
          if (this.productpricesDataSource.data[i].id! > 0) {
            const tmpProductPrice: ProductPrice = {
              id: this.productpricesDataSource.data[i].id,
              licenseRef: this.data.License,
              price: this.productpricesDataSource.data[i].price,
              validFrom: this.selectedDate,
              product: {},
              currency: {},
              fk_currencyId: this.productpricesDataSource.data[i].currency != null ? this.productpricesDataSource.data[i].currency?.id : null
            };
            tmpProductPrice.minPrice = this.productpricesDataSource.data[i].minPrice;
            tmpProductPrice.percent = this.productpricesDataSource.data[i].percent;
            tmpProductPrice.product!.name = this.productpricesDataSource.data[i].product?.name;
            tmpProductPrice.product!.erpCode = this.productpricesDataSource.data[i].product?.erpCode;
            tmpProductPrice.fk_currencyId = this.productpricesDataSource.data[i].currency!.id!;
            tmpProductPrice.product!.productUnit = this.productpricesDataSource.data[i].product?.productUnit;
            tmpProductPrice.product!.excludeOilTariff = this.productpricesDataSource.data[i].product?.excludeOilTariff;
            tmpProductPrice.product!.excludeEnvironmentalTariff = this.productpricesDataSource.data[i].product?.excludeEnvironmentalTariff;
            tmpProductPrice.product!.excludeCapacityTariff = this.productpricesDataSource.data[i].product?.excludeCapacityTariff;
            tmpProductPrice.product!.calcFromFreightLine = this.productpricesDataSource.data[i].product?.calcFromFreightLine;
            tmpProductPrice.fk_productId = this.productpricesDataSource.data[i].product?.id ? this.productpricesDataSource.data[i].product!.id! : undefined;
            const product: Product = {
              id: tmpProductPrice.id,
              name: tmpProductPrice.product?.name,
              erpCode: tmpProductPrice.product?.erpCode,
              fk_productUnitID: tmpProductPrice.product?.productUnit!.id!,
              calcFromFreightLine: tmpProductPrice.product?.calcFromFreightLine,
              licenseRef: this.data.License,
              excludeCapacityTariff: tmpProductPrice.product?.excludeCapacityTariff,
              excludeEnvironmentalTariff: tmpProductPrice.product?.excludeEnvironmentalTariff,
              excludeOilTariff: tmpProductPrice.product?.excludeOilTariff,
              productPrices: []
            }

            productArray.push(product);
            if (!tmpProductPrice.product?.id) {
              tmpProductPrice.product = undefined;
            }
            productPricesArray.push(tmpProductPrice);
            Object.defineProperty(this.productpricesDataSource.data[i], "editable", { value: false });
          }
        }

        if (productArray.length > 0) {
          this.productService.saveProducts(productArray).then((res: any) => {
            if (res) {
              if (productPricesArray.length > 0) {
                this.productPriceService.saveAll(productPricesArray).then((res: any) => {
                  if (res) {
                    console.log("Saved...");
                  }
                });
              }
            }
          }).finally(() => {
            this.loading = false;
            this._snackBar.open("Alle Produkter Gemt", "Success", { duration: 1000, panelClass: ['snack-bar'] });
          });
        }
        else {
          this.loading = false;
          this._snackBar.open("Intet Gemt", "Fejl", {panelClass: ['snack-bar']});
        }

        this.nameControl.nativeElement.focus();
        this.table.renderRows();
      }
    });
  }

  // Move to helper methods Utils
  compareByOptionId(idFist: any, idSecond: any) {
    return idFist && idSecond && idFist.id == idSecond.id;
  }


  // change Snack bar to pop up on screen (middle)
  openSnackBar(name: string) {
    this._snackBar.open("Du skal udfylde " + name + "!", this.UNDEFINED_ACTION, { duration: this.SNACK_BAR_DURATION, panelClass: ['snack-bar'] }); // Translate
  }

  applyData(productPrice: any) {
    // Check Fields ↓
    if (!productPrice.product?.name) {
      this.openSnackBar("et Navn"); // Translate
      this.nameControl.nativeElement.focus();
      return;
    }

    if (!productPrice.product?.erpCode) {
      this.openSnackBar("ERP Kode"); // Translate
      this.numberControl.nativeElement.focus();
      return;
    }

    if (!productPrice.product?.productUnit) {
      this.openSnackBar("Produkt Enhed"); // Translate
      return;
    }

    if (!productPrice.currency) {
      this.openSnackBar("en Valuta"); // Translate
      return;
    }

    if (!productPrice.id) {
      this.saveProductPriceWithoutId(productPrice);
    }
    else if (productPrice.id) {
      this.saveProductPriceWithId(productPrice);
    }
  }


  saveProductPriceWithId(productPrice: any) {
    const tmpProductPrice: ProductPrice = {
      id: productPrice.id,
      licenseRef: this.data.License,
      price: productPrice.price,
      validFrom: this.selectedDate,
      product: {},
      currency: {}
    };

    tmpProductPrice.minPrice = productPrice.minPrice;
    tmpProductPrice.percent = productPrice.percent;
    tmpProductPrice.product!.name = productPrice.product?.name;
    tmpProductPrice.product!.erpCode = productPrice.product?.erpCode;
    tmpProductPrice.fk_currencyId = productPrice.currency.id!;
    tmpProductPrice.product!.id = productPrice.product.id;
    tmpProductPrice.product!.productUnit = productPrice.product?.productUnit;
    tmpProductPrice.product!.excludeOilTariff = productPrice.product?.excludeOilTariff;
    tmpProductPrice.product!.excludeEnvironmentalTariff = productPrice.product?.excludeEnvironmentalTariff;
    tmpProductPrice.product!.excludeCapacityTariff = productPrice.product?.excludeCapacityTariff;
    tmpProductPrice.product!.calcFromFreightLine = productPrice.product?.calcFromFreightLine;

    const product: Product = {
      id: tmpProductPrice.product?.id,
      name: tmpProductPrice.product?.name,
      erpCode: tmpProductPrice.product?.erpCode,
      fk_productUnitID: tmpProductPrice.product?.productUnit!.id!,
      licenseRef: this.data.License,
      excludeCapacityTariff: tmpProductPrice.product?.excludeCapacityTariff,
      excludeEnvironmentalTariff: tmpProductPrice.product?.excludeEnvironmentalTariff,
      excludeOilTariff: tmpProductPrice.product?.excludeOilTariff,
      calcFromFreightLine: tmpProductPrice.product?.calcFromFreightLine,
      productPrices: []
    }

    this.productService.saveFullProduct(product).then((res: any) => {
      tmpProductPrice.product!.id = res.modelObject.id;
      tmpProductPrice.fk_productId = res.modelObject.id;
      tmpProductPrice.fk_customerId = null;
      tmpProductPrice.id = null;
      this.productPriceService.save(tmpProductPrice).then((res: any) => {
        if (res) {
          console.log("Saved Product...");
        }
      });
    });

    tmpProductPrice.currency! = productPrice.currency!;
    productPrice.editable! = false;
    this.nameControl.nativeElement.focus();
    this.table.renderRows();
  }

  saveProductPriceWithoutId(productPrice: any) {
    const tmpProductPrice: ProductPrice = {
      id: (this.productpricesDataSource.data.length * -1),
      licenseRef: this.data.License,
      price: productPrice.price,
      validFrom: this.selectedDate,
      product: {},
      currency: {}
    };
    tmpProductPrice.minPrice = productPrice.minPrice;
    tmpProductPrice.percent = productPrice.percent;
    tmpProductPrice.product!.name = productPrice.product?.name;
    tmpProductPrice.product!.erpCode = productPrice.product?.erpCode;
    tmpProductPrice.fk_currencyId = productPrice.currency.id!;
    tmpProductPrice.product!.productUnit = productPrice.product?.productUnit;
    tmpProductPrice.product!.excludeOilTariff = productPrice.product?.excludeOilTariff;
    tmpProductPrice.product!.excludeEnvironmentalTariff = productPrice.product?.excludeEnvironmentalTariff;
    tmpProductPrice.product!.excludeCapacityTariff = productPrice.product?.excludeCapacityTariff;
    tmpProductPrice.product!.calcFromFreightLine = productPrice.product?.calcFromFreightLine;

    const product: Product = {
      name: tmpProductPrice.product?.name,
      erpCode: tmpProductPrice.product?.erpCode,
      fk_productUnitID: tmpProductPrice.product?.productUnit!.id!,
      licenseRef: this.data.License,
      excludeCapacityTariff: tmpProductPrice.product?.excludeCapacityTariff,
      excludeEnvironmentalTariff: tmpProductPrice.product?.excludeEnvironmentalTariff,
      excludeOilTariff: tmpProductPrice.product?.excludeOilTariff,
      calcFromFreightLine: tmpProductPrice.product?.calcFromFreightLine,
      productPrices: []
    }


    this.productService.saveFullProduct(product).then((res: any) => {
      tmpProductPrice.product!.id = res.modelObject.id;
      tmpProductPrice.fk_productId = res.modelObject.id;
      tmpProductPrice.fk_customerId = null;
      tmpProductPrice.id = null;
      this.productPriceService.save(tmpProductPrice).then((res) => {
        tmpProductPrice.id = res.modelObject.id;
      });
    }).finally(() => {
      this._snackBar.open("Nyt Produkt Gemt", "Success", { duration: 1000, panelClass: ['snack-bar'] });
    });

    tmpProductPrice.currency! = productPrice.currency!;
    this.resetProductPrice(productPrice);

    if (this.productpricesDataSource.data.length == 1) {
      this.productpricesDataSource.data.push(tmpProductPrice);
    }
    else {
      this.productpricesDataSource.data.splice(1, 0, tmpProductPrice);
    }

    this.nameControl.nativeElement.focus();
    this.table.renderRows();
  }

  getAllCurrencies() {
    this.currencyRequest.licenseRef = this.data.License;
    this.currencyService.getCurrencies(this.currencyRequest).then((res) => {
      this.currencies = res.modelObject;
      this.currencies.sort((a: any, b: any) => (a.isocode > b.isocode) ? 1 : (a.isocode == b.isocode) ? 0 : -1);
    });
  }

  deleteProductPrice(productPrice: ProductPrice) {
    if (productPrice.id! < 0) {
      this.productpricesDataSource.data.forEach((element, index) => {
        if (element.id == productPrice.id) {
          this.productprices.splice(index, 1);
        }
      });
      this.table.renderRows();
    }
    else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: "280px",
        height: "170px",
        data: {
          title: "Er du sikker?", // Translate
          message: "Du er ved at slette : " + "<b>" + productPrice.product?.name + "</b>" // Translate
        }
      });

      dialogRef.afterClosed().subscribe(dialogRes => {
        if (dialogRes) {
          this.productService.delete(productPrice.product!.id, this.data.License).then((res: any) => {
            if (res) {
            }
            for (let i = 0; i < this.productpricesDataSource.data.length; i++) {
              if (this.productpricesDataSource.data[i].product!.id == productPrice.product!.id) {
                this.productpricesDataSource.data.splice(i, 1);
              }
            }
            this.table.renderRows();
          }).finally(() => {
            this._snackBar.open("Produkt Slettet", "Success", { duration: 1000, panelClass: ['snack-bar'] });
          });

        }
      });
    }
  }


  resetProductPrice(productPrice: ProductPrice) {
    productPrice.id = undefined;
    productPrice.product!.id = undefined;
    productPrice.product!.name = undefined;
    productPrice.price = null;
    productPrice.currency = undefined;
    productPrice.product!.erpCode = undefined;
    productPrice.product!.productUnit = undefined;
    productPrice.product!.excludeCapacityTariff = undefined;
    productPrice.product!.excludeEnvironmentalTariff = undefined;
    productPrice.product!.excludeOilTariff = undefined;
    productPrice.product!.calcFromFreightLine = undefined;
    productPrice.percent = undefined;
    productPrice.minPrice = undefined;
  }

  getAllProducts() {
    const product: Product = {
      name: "",
      erpCode: "",
      licenseRef: this.data.License,
      fk_productUnitID: 0,
      includeProductPrices: true,
      includeProductUnit: true
    };
    this.productService.getAll(product).then((res: any) => {
      this.productpricesDataSource = new MatTableDataSource(undefined);
      for (let i = 0; i < res.modelObject.length; i++) {
        if (res.modelObject[i].productPrices != null) {
          for (let j = 0; j < res.modelObject[i].productPrices.length; j++) {
            if (res.modelObject[i].productPrices[j].validFrom == this.selectedDate) {
              this.productpricesDataSource.data.push({
                id: res.modelObject[i].productPrices[j].id,
                price: res.modelObject[i].productPrices[j].price,
                fk_productId: res.modelObject[i].productPrices[j].fk_productId,
                currency: res.modelObject[i].productPrices[j].currency,
                percent: res.modelObject[i].productPrices[j].percent,
                minPrice: res.modelObject[i].productPrices[j].minPrice,
                product: {
                  id: res.modelObject[i].id,
                  name: res.modelObject[i].name,
                  licenseRef: res.modelObject[i].licenseRef,
                  productUnit: res.modelObject[i].productUnit,
                  erpCode: res.modelObject[i].erpCode,
                  excludeCapacityTariff: res.modelObject[i].excludeCapacityTariff,
                  excludeEnvironmentalTariff: res.modelObject[i].excludeEnvironmentalTariff,
                  excludeOilTariff: res.modelObject[i].excludeOilTariff,
                  calcFromFreightLine: res.modelObject[i].calcFromFreightLine
                }
              });
            }
          }
        }
      }
      // this.productpricesDataSource.data.unshift({ licenseRef: this.data.License, product: {}, currency: {} }); // Add Empty Product
      this.productpricesDataSource.paginator = this.paginator;
      this.table.renderRows();
      this.loading = false;
    }).finally(() => {
      this.loading = false;
    });
  }


  getProductDates() {
    const product: Product = {
      name: "",
      erpCode: "",
      licenseRef: this.data.License,
      fk_productUnitID: 0,
      includeProductPrices: true,
      includeProductUnit: true
    };
    this.productService.getAll(product).then((res: any) => {
      for (let i = 0; i < res.modelObject.length; i++) {
        if (res.modelObject[i].productPrices != null) {
          for (let j = 0; j < res.modelObject[i].productPrices.length; j++) {
            if (!this.pricesheetDates.includes(res.modelObject[i].productPrices[j].validFrom)) {
              this.pricesheetDates.push(res.modelObject[i].productPrices[j].validFrom);
            }
          }
        }
      }
      if (this.pricesheetDates.length == 1) {
        this.selectedDate = this.pricesheetDates[0];
        this.getAllProducts();
      }
    });
  }


  getAllProductUnits() {
    this.productUnitRequest.licenseRef = this.data.License;
    this.productUnitRequest.name = "";
    this.productUnitService.getAll(this.productUnitRequest).then((res: any) => {
      this.productUnits = res.modelObject;
    });
  }

  saveProduct(productPrice: any) {
    if (productPrice) {
      this.productService.saveFullProduct(productPrice).then((res: Product) => {
        if(res){
          console.log("RESULT", res);
        }
      }).finally(() => {
        this._snackBar.open("Produkt Gemt", "Success", { duration: 1000, panelClass: ['snack-bar'] });
      });
    }
  }

}
