import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ColliTypeService } from "src/app/shared/api/collitype.service";
import { CustomerService } from "src/app/shared/api/customer.service";
import { DataShareService } from "src/app/shared/api/datashare.service";
import { ColliType } from "src/app/shared/Models/collitype";
import { ColliTypeMeasurement } from "src/app/shared/Models/colliTypeMeasurement";
import { Customer } from "src/app/shared/Models/customer";
import { ConfirmDialogComponent } from "src/app/shared/util/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'new-terminal-dialog',
    templateUrl: './new-colli-measurement.dialog.html',
    styleUrls: ['./new-colli-measurement.dialog.scss'],
})
export class NewColliMeasurementDialog {
    name: string = '';
    date: any;
    saveBtnDisabled = true;
    dialogData = <ColliTypeMeasurement>{};
    colliTypes: any;
    customers: any;
    constructor(public dialogRef: MatDialogRef<NewColliMeasurementDialog>, private dialog: MatDialog, private translateService: TranslateService, private customerService: CustomerService, private data: DataShareService, private collitypeService: ColliTypeService) {
        this.getAllColliTypes();
        this.getCustomers();
        dialogRef.backdropClick().subscribe(() => {
          if (this.dialogData.colliType) {
              const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
                  data: {
                      title: this.translateService.instant('CLOSENEWCOLLIMEASUREMENTDIALOGTITLE'),
                      message: this.translateService.instant('FOUNDDATAERRORMESSAGE')
                  }
              });
  
              confirmDialog.afterClosed().subscribe((confirmed: any) => {
                  if (confirmed) {
                      this.dialogRef.close();
                  }
              });
          }
          else {
              dialogRef.close();
          }
      });
    }

    save() {
        this.dialogRef.close(this.dialogData);
    }

    getAllColliTypes() {
        let colliType: ColliType = {
            licenseRef: this.data.License
        };
        this.collitypeService.getAllColliTypes(colliType).then((res: any) => {
            if (res) {
                this.colliTypes = res.modelObject;
                this.colliTypes.sort((a: any, b: any) => (a.name > b.name) ? 1 : (a.name == b.name) ? 0 : -1)
            }
        });


    }
    compareByOptionRef(refirst: any, refSecond: any) {
        return refirst && refSecond && refirst == refSecond;
    }


    compareByOptionId(idFirst: any, idSecond: any) {
        return idFirst && idSecond && idFirst.id == idSecond.id;
    }

    getCustomers(): void {
        let customer: Customer;
        customer = {
          licenseRef: this.data.License,
          includeReturnCalcMethods: true,
          includeCustomerRules: true,
          includeCustomerPricesheets: true
        };
    
        this.customerService.getCustomers(customer).then((res: any) => {
          let customers = res.modelObject;
          customers.sort((a: Customer, b: Customer) => {
            if (a.clientObject == undefined && b.clientObject == undefined) return 0;
            if (a.clientObject == undefined) return -1;
            if (b.clientObject == undefined) return 1;
            if ((a.clientObject?.name == undefined || a.clientObject?.name == "") &&
              (b.clientObject?.name == undefined || b.clientObject?.name == "")) return 0;
            if (a.clientObject?.name == undefined || a.clientObject?.name == "") return -1;
            if (b.clientObject?.name == undefined || b.clientObject?.name == "") return -1;
            if (a.clientObject?.name!.toLowerCase() > b.clientObject?.name!.toLowerCase()) {
              return -1;
            }
            if (a.clientObject?.name!.toLowerCase() < b.clientObject?.name!.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          for (let i = 0; i < customers.length; i++) {
            if (customers[i].clientObject?.name) {
              customers[i].name = customers[i].clientObject.name;
            }
          }
          this.customers = customers.sort((a: any, b: any) => { if (a.name > b.name) return 1; if (b.name > a.name) return -1; return 0; });
          this.customers.unshift({ name: 'Ingen valgt' }); // Translate
        });
      }

    close() {
        if (this.dialogData.colliType) {
            const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: this.translateService.instant('CLOSENEWCOLLIMEASUREMENTDIALOGTITLE'),
                    message: this.translateService.instant('FOUNDDATAERRORMESSAGE')
                }
            });

            confirmDialog.afterClosed().subscribe((confirmed: any) => {
                if (confirmed) {
                    this.dialogRef.close();
                }
            });

        }
        else { // Nothing Filled
            this.dialogRef.close();
        }
    }

}
