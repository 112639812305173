import { Injectable } from "@angular/core";
import { CapacityTax } from "../Models/capacityTax";
import { EnvironmentalTax } from "../Models/enviromentalTax";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})
export class EnvironmentalTaxService {
    constructor(private api: ApiService) {}

async getAll(environmentalTax: EnvironmentalTax){
    return await this.api.post("/environmentaltax/getall", environmentalTax).toPromise();
}

async getAllByCustomer(customerId: number, environmentalTax: EnvironmentalTax){
    return await this.api.post("/environmentaltax/getallByCustomer?customerId=" + customerId, environmentalTax).toPromise();
}

async getLatest(environmentalTax: EnvironmentalTax){
    return await this.api.post("/environmentaltax/getlatest", environmentalTax).toPromise();
}

async saveOrUpdate(environmentalTax: EnvironmentalTax){
    return await this.api.post("/environmentaltax/saveOrUpdate", environmentalTax).toPromise();
}

async bulkSaveOrUpdate(environmentalTaxes: EnvironmentalTax[]){
    return await this.api.post("/environmentaltax/bulkSaveOrUpdate", environmentalTaxes).toPromise();
}

async delete(environmentalTax: EnvironmentalTax){
    return await this.api.delete("/environmentaltax/delete?Id=" + environmentalTax.id + "&licenseRef=" + environmentalTax.licenseRef).toPromise();
}

}