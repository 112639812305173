<div fxLayout="row" style="width:80%; margin:1%;">
  <div style="display:flex; justify-content: space-between; margin: 1%; align-items: center;">
    <h2 style="margin:0px;" class="header">{{'CAPACITYTAX'|translate}}</h2>
    <button mat-raised-button style="background-color: var(--primary-blue); color:white; border-radius: 10px;" (click)="newCapacityTax()">Ny Kapacitets Tillæg</button>
    <button mat-raised-button (click)="saveAll()"
      style="border-radius: 10px;background-color: var(--primary-blue); color:white;">{{'SAVEALL' | translate}}</button>
  </div>
  <div style="border-radius: 6px;" class="mat-elevation-z20 table-responsive">
    <table style="width:100%;" mat-table [dataSource]="capaTaxesDataSource">

      <!-- ↓ CUSTOMER ↓ -->
      <ng-container matColumnDef="customer">
        <th class="centText" mat-header-cell *matHeaderCellDef>{{'CUSTOMER'|translate}}
        </th>
        <td class="centText" mat-cell *matCellDef="let capacityTax">
          <mat-form-field class="centText" floatLabel='never'>
            <mat-label>{{'CUSTOMER' | translate}}</mat-label>
            <mat-select (ngModelChange)="checkData(capacityTax)" [(ngModel)]="capacityTax.fk_customerId" name="customer"
              [compareWith]="compareByOptionRef">
              <mat-option *ngFor="let cust of customers" [value]="cust.id">
                {{cust.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <!-- ↓ DATE  ↓ -->
      <ng-container matColumnDef="date">
        <th class="centText" mat-header-cell *matHeaderCellDef>{{'DATE'|translate}}</th>
        <td class="centText" mat-cell *matCellDef="let capacityTax">
          <input [(ngModel)]="capacityTax.date" matInput type="date" />
        </td>
      </ng-container>

      <!-- ↓ PROCENT  ↓ -->
      <ng-container matColumnDef="procent">
        <th class="centText" mat-header-cell *matHeaderCellDef>{{'PROCENT'|translate}}</th>
        <td class="centText" mat-cell *matCellDef="let capacityTax">
          <input #procent placeholder="Procent" type="text" matInput (ngModelChange)="checkData(capacityTax)"
            [(ngModel)]="capacityTax.procent">
        </td>
      </ng-container>

      <!-- ↓ COLLITYPEREF  ↓ -->
      <ng-container matColumnDef="colliType">
        <th class="centText" mat-header-cell *matHeaderCellDef>{{'COLLITYPE'|translate}}
        </th>
        <td class="centText" mat-cell *matCellDef="let capacityTax">
          <mat-form-field class="centText" floatLabel='never'>
            <mat-label>{{'COLLITYPE' | translate}}</mat-label>
            <mat-select (ngModelChange)="checkData(capacityTax)" [(ngModel)]="capacityTax.collitypeRef" name="collitype"
              [compareWith]="compareByOptionRef">
              <mat-option *ngFor="let ct of colliTypes" [value]="ct.ref">
                {{ct.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <!-- ↓ ERPCODE  ↓ -->
      <ng-container matColumnDef="erpCode">
        <th class="centText" mat-header-cell *matHeaderCellDef>{{'ERPCODE'|translate}}</th>
        <td class="centText" mat-cell *matCellDef="let capacityTax">
          <input #erpCode placeholder="ERP Kode" type="text" matInput (ngModelChange)="checkData(capacityTax)"
            [(ngModel)]="capacityTax!.erpCode">
        </td>
      </ng-container>

      <!-- DELETE/ACTION ↓ -->
      <ng-container matColumnDef="action">
        <th class="centText" mat-header-cell *matHeaderCellDef></th>
        <td class="centText" mat-cell *matCellDef="let capacityTax">
          <div *ngIf="!capacityTax.id || capacityTax.editable">
            <button matTooltip="Gem Kapacitets Tillæg"
              style="border-radius: 10px;background-color: var(--primary-blue); color:white;" mat-raised-button
              (click)="updateCapacityTax(capacityTax)">
              <mat-icon>save</mat-icon>
            </button>
          </div>
          <div *ngIf="capacityTax.id && !capacityTax.editable">
            <button matTooltip="Slet Kapacitets Tillæg" style="border-radius: 10px;" mat-raised-button color="warn"
              (click)="deleteCapacityTax(capacityTax)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr style="background-color:var(--primary-blue);" mat-header-row
        *matHeaderRowDef="displayedColumns; sticky:true;">
      </tr>
      <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 15, 25]" showFirstLastButtons aria-label="Vælg Side"></mat-paginator> <!-- Translate-->
  </div>
</div>

<app-mat-spinner-overlay *ngIf="loading === true" [overlay]="true">
</app-mat-spinner-overlay>