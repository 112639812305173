import { Injectable } from "@angular/core";
import { Rule } from "../Models/rule";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})
export class RulesService {
    constructor(private api: ApiService) {}

async getAll(rule: Rule){
    return await this.api.post("/rule/getall", rule).toPromise();
}

async getByCustomer(rule: Rule){
    return await this.api.post("/rule/getbycustomer", rule).toPromise();
}


async saveOrUpdate(rule: Rule){
    return await this.api.post("/rule/saveorupdate", rule).toPromise();
}


}