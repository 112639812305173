import { Injectable } from "@angular/core";
import { CustomerPriceSheet } from "../Models/customerPriceSheet";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root'
})
export class CustomerPricesheetService { 
    constructor(private api: ApiService) { }

    async getAllByCustomer(custPricesheet: CustomerPriceSheet) {
        return await this.api.post("/customerpricesheet/getallbyCustomer", custPricesheet).toPromise();
    }

    async bulkSaveOrUpdate(custPricesheet: CustomerPriceSheet[]) {
        return await this.api.post("/customerpricesheet/BulksaveOrUpdate", custPricesheet).toPromise();
    }

    async deleteByCustomer(customerId: number, licenseRef: string){
        return await this.api.delete("/customerpricesheet/deletebyCustomer?customerId=" + customerId + "&licenseRef=" + licenseRef).toPromise();
    }
}