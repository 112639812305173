<div fxLayout="row" style="max-width:100%; margin:1%;">
    <div style="display:flex; justify-content: space-between; margin:1%; align-items: center;">
        <h2 style="margin:0px;" class="header">{{'COLLITYPE' | translate}}</h2>
        <button mat-raised-button *ngIf="!selectedCustomer" style="background-color: var(--primary-blue); color:white; border-radius: 10px;" (click)="newColliTypeTranslate()">Ny Gods type</button>
        <button mat-raised-button (click)="saveAll()"
            style="border-radius: 10px; background-color: var(--primary-blue); color: white;">
            {{'SAVEALL' | translate}}
        </button>
    </div>
    <div style="border-radius: 6px;" class="mat-elevation-z10 table-responsive">
        <table style="width:100%;" mat-table [dataSource]="collitypeTranslatesDataSource">

            <ng-container matColumnDef="collitype">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'COLLITYPE'|translate}}
                </th>
                <td class="centText" mat-cell *matCellDef="let collitype">
                    <mat-form-field class="centText" floatLabel='never'>
                        <mat-label>{{'COLLITYPE' | translate}}</mat-label>
                        <mat-select (ngModelChange)="checkData(collitype)" [(ngModel)]="collitype.colliTypeRef"
                            name="collitype" [compareWith]="compareByOptionRef">
                            <mat-option *ngFor="let ct of colliTypes" [value]="ct.ref">
                                {{ct.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="translate">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'TRANSLATETO'|translate}}
                </th>
                <td class="centText" mat-cell *matCellDef="let collitype">
                    <mat-form-field class="centText" floatLabel='never'>
                        <mat-label>{{'TRANSLATESTO' | translate}}</mat-label>
                        <mat-select (ngModelChange)="checkData(collitype)" [(ngModel)]="collitype.translateRef"
                            name="translate" [compareWith]="compareByOptionRef">
                            <mat-option *ngFor="let ct of colliTypes" [value]="ct.ref">
                                {{ct.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="customer">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'CUSTOMER'|translate}}
                </th>
                <td class="centText" mat-cell *matCellDef="let collitype">
                    <mat-form-field class="centText" floatLabel='never'>
                        <mat-label>{{'CUSTOMER' | translate}}</mat-label>
                        <mat-select [disabled]="selectedCustomer != null" (ngModelChange)="checkData(collitype)" [(ngModel)]="collitype.customerRef"
                            name="customer" [compareWith]="compareByOptionRef">
                            <mat-option *ngFor="let cust of customers" [value]="cust.customerexternRef">
                                {{cust.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th class="centText" mat-header-cell *matHeaderCellDef></th>
                <td class="centText" mat-cell *matCellDef="let collitype">
                    <div *ngIf="!collitype.id || collitype.editable">
                        <button matTooltip="Gem Gods type"
                            style="border-radius: 10px;background-color: var(--primary-blue); color:white;"
                            mat-raised-button (click)="updateColliType(collitype)">
                            <mat-icon>save</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="collitype.id && !collitype.editable">
                        <button matTooltip="Slet Gods Type" style="border-radius: 10px;" mat-raised-button color="warn"
                            (click)="deleteColliType(collitype)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>
            <tr style="background-color:var(--primary-blue);" mat-header-row
                *matHeaderRowDef="displayedColumns; sticky:true;"></tr>
            <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 15, 20, 25]" showFirstLastButtons aria-label="Vælg Side"></mat-paginator>
    </div>
</div>

<app-mat-spinner-overlay *ngIf="loading === true" [overlay]="true">
</app-mat-spinner-overlay>