import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { PricesheetType } from "../Models/pricesheetType";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})
export class PricesheetTypeService {
    constructor(private api: ApiService) { }

    async getAll(pricesheetType: PricesheetType) {
        return await this.api.post("/pricesheettype/getall", pricesheetType).toPromise();
    }

}