import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

@Injectable({
    providedIn: 'root',
  })
export class NavigationService {
    private history: string[] = [];

    constructor(private router: Router, private location: Location) { }

    startSaveHistory() {
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                this.history.push(event.urlAfterRedirects);
            }
        });
    }

    getHistory() {
        return this.history;
    }

    goBack() {
        this.history.pop;

        if (this.history && this.history.length > 0) {
            this.location.back();
        }
        else {
            this.router.navigateByUrl("/");
        }
    }

    getPreviousUrl() {
        if (this.history.length > 0) {
            return this.history[this.history.length - 2];
        }
        return '';
    }
}