export const environment = {
  production: false,
  // appVersion: require('../../package.json').version,
  api_url: "https://alicepetest.azurewebsites.net/api/",
  api_clients_url: "https://aliceclientstest.azurewebsites.net",
  api_collitype_url:"https://alicectttest.azurewebsites.net/api",
  // api_url: "https://localhost:44392/api",
  // api_clients_url: "https://localhost:44312/",
  // api_collitype_url:"https://localhost:44301/api",
  password: 'A4Ever1234'
  // api_url: "https://alicepe.alicetms.dk/api",
  // api_collitype_url:"https://alicectt.alicetms.dk/api",
  // api_clients_url: "https://aliceclients.alicetms.dk",
};
