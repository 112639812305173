import { NumberSymbol } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PricesheetPrice } from '../Models/pricesheetPrice';
import { Results } from '../Models/results';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PricesheetpricesService {

  private API = environment.api_url;
  private root_API = this.API + "/pricesheetprice";

  constructor(private http: HttpClient, private api: ApiService) { }

  getPricesheetPrices(pricesheetId: number, pricesheetPrice?: PricesheetPrice): Observable<Results> {
    const headers = { 'Authorization': 'E5B93DDF-CA5C-4C4B-975B-9BBDD95DD2D0' };
    const body = pricesheetPrice;
    return this.http.post(this.root_API + "/getbypricesheet?pricesheetId=" + pricesheetId, body, { headers });
  }


  async saveOrUpdate(pricesheetPrices: any) {
    return await this.api.post("/pricesheetPrice/saveorupdate", pricesheetPrices).toPromise();
  }

  async saveOrUpdateStandard(pricesheetPrices: any) {
    return await this.api.post("/pricesheetPrice/saveorupdateStandard", pricesheetPrices).toPromise();
  }

  async getPricesheetPricesView(pricesheetId: number, pricesheetPrice?: PricesheetPrice) {
    const headers = { 'Authorization': 'E5B93DDF-CA5C-4C4B-975B-9BBDD95DD2D0' };
    const body = pricesheetPrice;
    return await this.http.post(this.root_API + "/getbypricesheetView?pricesheetId=" + pricesheetId, body, { headers }).toPromise();
  }


}
