<div fxLayout="row"
    style="background-color: var(--primary-blue); padding:10px; margin:-24px; margin-bottom:10px; justify-content: center; display:flex; color:white;">
    <h1 mat-dialog-title>{{'NEWTERMINAL' | translate}}</h1>
</div>
<mat-dialog-content fxLayout="column" style="width:15vw; margin-bottom:6%; overflow:unset;">
    <div style="display:flex; flex-direction: column; align-items: stretch; justify-content: center;">
        <mat-form-field appearance="outline">
            <mat-label>{{'NAME' | translate}}</mat-label>
            <input required [(ngModel)]="dialogData.name" matInput type="text" />
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: space-between;">
    <button mat-raised-button (click)="close()">{{'CLOSE' | translate}}</button>
    <button mat-raised-button style="background-color: var(--primary-blue); color:white;" (click)="save()">{{'CREATE' |
        translate}}</button>
</mat-dialog-actions>