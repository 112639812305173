import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { SpreadsheetComponent } from '@syncfusion/ej2-angular-spreadsheet';
import { ColliTypeService } from '../shared/api/collitype.service';
import { CountryService } from '../shared/api/country.service';
import { CurrencyService } from '../shared/api/currency.service';
import { DataShareService } from '../shared/api/datashare.service';
import { HandlingTypeService } from '../shared/api/handlingtype.service';
import { MeasureUnitService } from '../shared/api/measure.service';
import { PriceCalcUnitService } from '../shared/api/pricecalcunit.service';
import { PricesheetService } from '../shared/api/pricesheet.service';
import { PricesheetpricesService } from '../shared/api/pricesheetprices.service';
import { PricesheetTypeService } from '../shared/api/pricesheettype.service';
import { PricesheetzonesService } from '../shared/api/pricesheetzones.service';
import { ProductService } from '../shared/api/product.service';
import { TerminalService } from '../shared/api/terminal.service';
import { ColliType } from '../shared/Models/collitype';
import { Country } from '../shared/Models/country';
import { Currency } from '../shared/Models/currency';
import { Customer } from '../shared/Models/customer';
import { PriceCalcUnit } from '../shared/Models/priceCalcUnit';
import { Pricesheet } from '../shared/Models/pricesheet';
import { PricesheetPrice, PricesheetPriceViewModel } from '../shared/Models/pricesheetPrice';
import { PricesheetType } from '../shared/Models/pricesheetType';
import { HandlingType, PricesheetZone, PricesheetZoneViewModel } from '../shared/Models/pricesheetZone';
import { Product } from '../shared/Models/product';
import { ProductPrice } from '../shared/Models/productPrice';
import { Terminal } from '../shared/Models/terminal';
import { ConfirmDialogComponent } from '../shared/util/confirm-dialog/confirm-dialog.component';
import Utils from '../shared/util/internalMethodHelper';
import { StandardPricesheetDialog } from './standard-pricesheet-dialog/standard-pricesheet-dialog';

@Component({
  selector: 'app-standard-pricesheet',
  templateUrl: './standard-pricesheet.component.html',
  styleUrls: ['./standard-pricesheet.component.scss']
})
export class StandardPricesheetComponent implements OnInit {

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  @ViewChild("name") nameControl!: ElementRef;
  loading: boolean = false;
  newCustomer = <Customer>{};
  productprice = <ProductPrice>{};
  productprices: ProductPrice[] = [];
  displayedColumns: string[] = ["name", "price", "valuta"];
  dataSource!: MatTableDataSource<ProductPrice>;
  selectedPricesheet?: Pricesheet;
  pricesheets: Pricesheet[] = [];
  zoneDates = <any[]>[];
  products: Product[] = [];
  handlingTypes: HandlingType[] = [];

  // Zones Properties
  countries: Country[] = [];
  currencies: Currency[] = [];
  countryRequest = <Country>{};
  zones: PricesheetZoneViewModel[] = [];
  zonesToSend: PricesheetZone[] = [];
  terminals: Terminal[] = [];
  type: any; maxRowZone: any;
  @ViewChild('spreadsheetZone') spreadsheetObjZone!: SpreadsheetComponent;

  // Prices Properties
  @ViewChild('spreadsheetPrice') spreadsheetObjPrice!: SpreadsheetComponent;
  prices: PricesheetPriceViewModel[] = [];
  maxRowPrice: any; measureUnits: any; colliTypes: any;
  pricesToSend: PricesheetPrice[] = [];
  currencyRequest = <Currency>{}; collitypeRequest = <ColliType>{};
  priceCalcUnits: PriceCalcUnit[] = [];

  pricesResult: any;
  zonesResult: any;

  constructor(private data: DataShareService,
    private pricesheetTypeService: PricesheetTypeService,
    private pricesheetService: PricesheetService,
    private translate: TranslateService,
    private countryService: CountryService,
    private handlingTypeService: HandlingTypeService,
    private pricesheetZonesService: PricesheetzonesService,
    private pricesheetpriceService: PricesheetpricesService,
    private colliTypeService: ColliTypeService,
    private currencyService: CurrencyService,
    private measureUnitService: MeasureUnitService,
    private productService: ProductService,
    private _snackBar: MatSnackBar,
    private terminalService: TerminalService,
    private priceCalcUnitService: PriceCalcUnitService,
    private dialog: MatDialog
  ) {
    this.data.ClearData();
  }

  ngOnInit(): void {
    this.loading = true;
    this.getDefaultPricesheets();
    this.getCountries();
    this.getColliTypes();
    this.getMeasureUnits();
    this.getAllCurrencies();
    this.getDefaultPricesheetType();
    this.getAllProducts();
    this.getHandlingTypes();
    this.getTerminals();
    this.getAllPriceCalcUnit();
  }


  savePricesheet() {
    this.loading = true;
    this.checkExtraPriceRows();
    this.checkExtraZoneRows();
  }

  getDefaultPricesheets() {
    let priceSheet: Pricesheet = {
      licenseRef: this.data.License,
      includeCustomerPricesheets: true
    }

    this.pricesheetService.getAllDefaultPricesheets(priceSheet).subscribe((res) => {
      this.pricesheets = res.modelObject;
    });
  }

  selectPriceSheet(pricesheet: Pricesheet): void {
    this.loading = true;
    this.selectedPricesheet = pricesheet;
    this.getZones();
    this.getPrices();
  }

  // ↓ ZONES METHODS ↓
  setZoneDataValidation() {
    if (this.countries) {
      this.spreadsheetObjZone.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=CountryFrom!D2:D" + (this.countries.length + 1) }, 'E2:E' + (this.zones.length + 2));
      this.spreadsheetObjZone.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=CountryFrom!D2:D" + (this.countries.length + 1) }, 'B2:B' + (this.zones.length + 2));
    }
    if (this.handlingTypes) {
      this.spreadsheetObjZone.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=HandlingType!B2:B" + (this.handlingTypes.length + 1) }, 'I2:I' + (this.zones.length + 2));
    }
    if (this.terminals) {
      this.spreadsheetObjZone.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=Terminals!C2:C" + (this.terminals.length + 1) }, 'J2:J' + (this.zones.length + 2));
    }
  }

  checkExtraZoneRows() {
    this.loading = true;
    var json = this.spreadsheetObjZone.saveAsJson();
    var rows: any[] = [];
    json.then((val: any) => {
      this.zonesToSend = [];
      for (let i = 1; i < val.jsonObject.Workbook.sheets[0].rows.length; i++) {
        if (!val.jsonObject.Workbook.sheets[0].rows[i]) {
          break;
        }
        rows.push(val.jsonObject.Workbook.sheets[0].rows[i]);
      }
      for (let j = 0; j < rows.length; j++) {
        if (rows[j].cells.length < 7) {
          break;
        }

        if (rows[j].cells[1]?.value == undefined || rows[j].cells[2]?.value == undefined || rows[j].cells[3]?.value == undefined ||
          rows[j].cells[4]?.value == undefined || rows[j].cells[5]?.value == undefined || rows[j].cells[6]?.value == undefined || rows[j].cells[7]?.value == undefined) {
          continue;
        }

        if (rows[j].cells.slice(1).every((el: any) => el != null)) {
          if (rows[j].cells[2]?.value.toString().includes("'")) {
            rows[j].cells[2].value = rows[j].cells[2].value.toString().replace("'", "");
          }
          if (rows[j].cells[3]?.value.toString().includes("'")) {
            rows[j].cells[3].value = rows[j].cells[3].value.toString().replace("'", "");
          }
          if (rows[j].cells[5]?.value.toString().includes("'")) {
            rows[j].cells[5].value = rows[j].cells[5].value.toString().replace("'", "");
          }
          if (rows[j].cells[6]?.value.toString().includes("'")) {
            rows[j].cells[6].value = rows[j].cells[6].value.toString().replace("'", "");
          }
          var newZone = {
            id: rows[j].cells[0] != null ? rows[j].cells[0].value : null,
            countryFrom: rows[j].cells[1].value,
            fromZipFrom: rows[j].cells[2].value,
            fromZipTo: rows[j].cells[3].value,
            countryTo: rows[j].cells[4].value,
            toZipFrom: rows[j].cells[5].value,
            toZipTo: rows[j].cells[6].value,
            zoneNo: rows[j].cells[7].value,
            handlingType: rows[j].cells[8] != null ? rows[j].cells[8].value : null,
            terminal: rows[j].cells[9] != null ? rows[j].cells[9].value : null
          } as PricesheetZoneViewModel;
          if (this.selectedPricesheet) {
            newZone.fk_pricesheetId = this.selectedPricesheet.id;
          }
          this.zonesToSend.push(newZone);
        }
      }
      this.zonesResult = this.mapZones();

      this.pricesheetZonesService.saveOrUpdateStandard(this.zonesResult).then((res: any) => {
        if (res) {
        }
      }).finally(() => {
        this.loading = false
        this._snackBar.open("Zoner Gemt", "Success", { duration: 1000, panelClass: ['snack-bar'] });
      });
    });
  }


  mapZones() {
    const tmpZoneArray: PricesheetZone[] = [];
    for (let i = 0; i < this.zonesToSend.length; i++) {

      // Check Country To 
      if (!Utils.isObject(this.zonesToSend[i].countryTo)) {
        for (let f = 0; f < this.countries.length; f++) {
          if (this.countries[f].isocode == this.zonesToSend[i].countryTo) {
            this.zonesToSend[i].countryTo = this.countries[f];
          }
        }
      }
      // Check Country From
      if (!Utils.isObject(this.zonesToSend[i].countryFrom)) {
        for (let j = 0; j < this.countries.length; j++) {
          if (this.countries[j].isocode == this.zonesToSend[i].countryFrom) {
            this.zonesToSend[i].countryFrom = this.countries[j];
          }
        }
      }
      // Check HandlingType
      if (!Utils.isObject(this.zonesToSend[i].handlingType)) {
        for (let k = 0; k < this.handlingTypes.length; k++) {
          if (this.handlingTypes[k].name == this.zonesToSend[i].handlingType) {
            this.zonesToSend[i].handlingType = this.handlingTypes[k];
          }
        }
      }
      // Check Terminal
      if (!Utils.isObject(this.zonesToSend[i].terminal)) {
        for (let l = 0; l < this.terminals.length; l++) {
          if (this.terminals[l].name == this.zonesToSend[i].terminal) {
            this.zonesToSend[i].terminal = this.terminals[l];
          }
        }
      }

      const wZone: PricesheetZone = {
        collitypeCategory: this.zonesToSend[i].collitypeCategory,
        id: this.zonesToSend[i].id,
        fromZipFrom: this.zonesToSend[i].fromZipFrom?.toString(),
        fromZipTo: this.zonesToSend[i].fromZipTo?.toString(),
        toZipFrom: this.zonesToSend[i].toZipFrom?.toString(),
        toZipTo: this.zonesToSend[i].toZipTo?.toString(),
        zoneNo: this.zonesToSend[i].zoneNo?.toString(),
        validFrom: this.selectedPricesheet!.validFrom,
        licenseRef: this.selectedPricesheet?.licenseRef,
        fk_pricesheetId: this.selectedPricesheet?.id,
        fk_countryFromId: this.zonesToSend[i].countryFrom?.id!,
        fk_countryToId: this.zonesToSend[i].countryTo?.id!,
        fk_handlingTypeId: this.zonesToSend[i].handlingType?.id,
        handlingType: this.zonesToSend[i].handlingType,
        terminal: this.zonesToSend[i].terminal,
        fk_terminalId: this.zonesToSend[i].terminal?.id
      };
      tmpZoneArray.push(wZone);
    }
    return tmpZoneArray;
  }

  setZoneHeaderRow() {
    this.spreadsheetObjZone.cellFormat({ fontWeight: 'bold', fontSize: '14px' }, 'B1:L1');
    this.spreadsheetObjZone.updateCell({ index: 1, value: this.translate.instant('COUNTRYFROM') }, 'B1');
    this.spreadsheetObjZone.updateCell({ index: 1, value: this.translate.instant('FROMCOUNTRYCODE') }, 'C1');
    this.spreadsheetObjZone.updateCell({ index: 1, value: this.translate.instant('TOCOUNTRYCODE') }, 'D1');
    this.spreadsheetObjZone.updateCell({ index: 1, value: this.translate.instant('COUNTRYTO') }, 'E1');
    this.spreadsheetObjZone.updateCell({ index: 1, value: this.translate.instant('FROMZONE') }, 'F1');
    this.spreadsheetObjZone.updateCell({ index: 1, value: this.translate.instant('TOZONE') }, 'G1');
    this.spreadsheetObjZone.updateCell({ index: 1, value: this.translate.instant('ZONENO') }, 'H1');
    this.spreadsheetObjZone.updateCell({ index: 1, value: this.translate.instant('DRIVERTYPE') }, 'I1');
    this.spreadsheetObjZone.updateCell({ index: 1, value: this.translate.instant('TERMINAL') }, 'J1');

  }

  getZones() {
    if (this.selectedPricesheet) {
      let pricesheetZone: PricesheetZone = {
        licenseRef: this.data.License,
        includeCollitypeCategory: true,
        includeCountryFrom: true,
        includeCountryTo: true,
        includeTerminal: true,
      }
      this.pricesheetZonesService.getPricesheetZonesView(this.selectedPricesheet.id!, pricesheetZone).subscribe(res => {
        for (let i = 0; i < res.modelObject.length; i++) {
          res.modelObject[i].countryFrom = this.checkCountry(res.modelObject[i].countryFrom);
          res.modelObject[i].countryTo = this.checkCountry(res.modelObject[i].countryTo);
          res.modelObject[i].handlingType = this.checkHandlingType(res.modelObject[i].handlingType);
          res.modelObject[i].terminal = this.checkTerminal(res.modelObject[i].terminal);
        }
        this.copyZonesToViewModel(res.modelObject);
      });
    }
  }

  checkTerminal(term: any): any {
    if (term) {
      for (let i = 0; i < this.terminals.length; i++) {
        if (term.id == this.terminals[i].id) {
          return this.terminals[i].name;
        }
      }
    }
  }

  checkCountry(count: any): any {
    if (count) {
      for (let i = 0; i < this.countries.length; i++) {
        if (count.isocode == this.countries[i].isocode) {
          return this.countries[i].isocode;
        }
      }
    }
  }

  checkHandlingType(ht: any): any {
    if (ht) {
      for (let i = 0; i < this.handlingTypes.length; i++) {
        if (ht.id == this.handlingTypes[i].id) {
          return this.handlingTypes[i].name;
        }
      }
    }
  }

  getCountries() {
    this.countryRequest.licenseRef = this.data.License;
    this.countryService.getCountries(this.countryRequest).then((res: any) => {
      this.countries = res.modelObject;
      this.countries.sort((a, b) => (a.isocode! > b.isocode!) ? 1 : (a.isocode == b.isocode) ? 0 : -1);
    });
  }

  getHandlingTypes() {
    let handlingType: HandlingType = {
      licenseRef: this.data.License
    };
    this.handlingTypeService.getAll(handlingType).then((res) => {
      this.handlingTypes = res.modelObject;
    });
  }

  copyZonesToViewModel(zones: PricesheetZone[]) {
    const tmpArray = [];
    for (let i = 0; i < zones.length; i++) {
      const zone = {
        id: zones[i].id,
        countryFrom: zones[i].countryFrom,
        fromZipFrom: zones[i].fromZipFrom,
        fromZipTo: zones[i].fromZipTo,
        countryTo: zones[i].countryTo,
        toZipFrom: zones[i].toZipFrom,
        toZipTo: zones[i].toZipTo,
        zoneNo: zones[i].zoneNo,
        handlingType: zones[i].handlingType,
        terminal: zones[i].terminal
      } as PricesheetZoneViewModel;
      tmpArray.push(zone);
    }
    this.zones = tmpArray;
    this.maxRowZone = (this.zones.length + 2);
  }

  dataSourceChangedZone(event: any): void {
    if (event.action == "edit") {
      if (event.data) {
        for (let i = 0; i < event.data!.length; i++) {
          for (let j = 0; j < this.zones.length; j++) {
            if (this.zones[j].id == event.data[i].id) {
              this.zones[j] = event.data[i] as PricesheetZone;
            }

          }
        }
      }
    }
    if (event.action == "dataSourceChanged") {
      this.setZoneHeaderRow();
      this.setZoneDataValidation();
    }
  }

  cellEditZone(event: any) {
    var rowNo = event.address.substring(7);
    if (rowNo == this.maxRowZone) {
      this.spreadsheetObjZone.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=CountryFrom!D2:D" + (this.countries.length + 1) }, 'E2:E' + (+rowNo + 1));
      this.spreadsheetObjZone.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=CountryFrom!D2:D" + (this.countries.length + 1) }, 'B2:B' + (+rowNo + 1));
      this.spreadsheetObjZone.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=HandlingType!B2:B" + (this.handlingTypes.length + 1) }, 'I2:I' + (+rowNo + 1));
      this.spreadsheetObjZone.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=Terminals!C2:C" + (this.terminals.length + 1) }, 'J2:J' + (+rowNo + 1));

      this.maxRowZone++;
    }
  }

  createdZone() {
    this.setZoneHeaderRow();
  }


  // ↓ Prices Methods ↓
  cellEditPrice(event: any) {
    var rowNo = event.address.substring(8);
    if (rowNo == this.maxRowPrice) {
      this.spreadsheetObjPrice.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=priceCalcUnit!C2:C" + (this.priceCalcUnits.length + 1) }, 'H2:H' + (+rowNo + 1));
      this.spreadsheetObjPrice.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=measureUnit!C2:C" + (this.measureUnits.length + 1) }, 'G2:G' + (+rowNo + 1));
      this.spreadsheetObjPrice.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=currencies!D2:D" + (this.currencies.length + 1) }, 'J2:J' + (+rowNo + 1));
      this.spreadsheetObjPrice.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=colliTypes!C2:C" + (this.colliTypes.length + 1) }, 'C2:C' + (+rowNo + 1));
      this.spreadsheetObjPrice.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=products!B2:B" + (this.products.length + 1) }, 'B2:B' + (+rowNo + 1));
      this.maxRowPrice++;
    }
  }


  checkExtraPriceRows() {
    this.loading = true;
    var json = this.spreadsheetObjPrice.saveAsJson();

    var rows: any[] = [];
    json.then((val: any) => {
      this.pricesToSend = [];
      for (let i = 1; i < val.jsonObject.Workbook.sheets[0].rows.length; i++) {
        if (!val.jsonObject.Workbook.sheets[0].rows[i]) {
          break;
        }
        rows.push(val.jsonObject.Workbook.sheets[0].rows[i]);
      }
      for (let j = 0; j < rows.length; j++) {
        if (rows[j].cells) {
          if (rows[j].cells.length < 9) {
            // Missing data
            this._snackBar.open("Der mangler Data på række: " + j, "Fejl", {panelClass: ['snack-bar']});
            break;
          }
          if (rows[j].cells[4]?.value == undefined) {
            continue;
          }
          if (rows[j].cells[5]?.value == undefined || rows[j].cells[9]?.value == undefined
            || rows[j].cells[4]?.value == undefined || rows[j].cells[6]?.value == undefined) {
            continue;
          }
          if (rows[j].cells[9] == null) {
            rows[j].cells[9] = 0;
          }
          if (rows[j].cells[1] == null) {
            rows[j].cells[1] = ' ';
          }
          if (rows[j].cells.slice(1).every((el: any) => el != null)) {
            var newPrice = {
              id: rows[j].cells[0] != null ? rows[j].cells[0].value : null,
              product: rows[j].cells[1].value,
              collitypeRef: rows[j].cells[2].value,
              currency: rows[j].cells[9].value,
              intervalFrom: parseFloat((rows[j].cells[4].value.toString().replace(',', '.'))),
              intervalTo: parseFloat((rows[j].cells[5].value.toString().replace(',', '.'))),
              measureUnit: rows[j].cells[6].value,
              priceCalcUnit: rows[j].cells[7].value,
              price: rows[j].cells[8].value,
              priceCode: rows[j].cells[3].value != null ? rows[j].cells[3].value : "",
              zoneNo: rows[j].cells[10].value,
              text: rows[j].cells[11] != null ? rows[j].cells[11].value : null
            } as PricesheetPriceViewModel;
            if (this.selectedPricesheet) {
              newPrice.fk_pricesheetId = this.selectedPricesheet.id;
            }
            this.pricesToSend.push(newPrice);
          }
        }
      }
      this.pricesResult = this.mapPrices();

      this.pricesheetpriceService.saveOrUpdateStandard(this.pricesResult).then((res: any) => {
        if (res) {
        }
      }).finally(() => {
        this.loading = false;
        this._snackBar.open("Priser Gemt", "Success", {duration: 1000, panelClass: ['snack-bar']});
      });

    });
  }

  mapPrices() {
    const tmpPriceArray: PricesheetPrice[] = [];
    for (let j = 0; j < this.pricesToSend.length; j++) {
      // Check Currency
      if (!Utils.isObject(this.pricesToSend[j].currency)) {
        for (let k = 0; k < this.currencies.length; k++) {
          if (this.currencies[k].isocode == this.pricesToSend[j].currency) {
            this.pricesToSend[j].currency = this.currencies[k];
            break;
          }
        }
      }
      // Check MeasureUnit
      if (!Utils.isObject(this.pricesToSend[j].measureUnit)) {
        for (let a = 0; a < this.measureUnits.length; a++) {
          if (this.measureUnits[a].name == this.pricesToSend[j].measureUnit) {
            this.pricesToSend[j].measureUnit = this.measureUnits[a];
            break;
          }
        }
      }
      // Check Product
      if (!Utils.isObject(this.pricesToSend[j].product)) {
        for (let b = 0; b < this.products.length; b++) {
          if (this.products[b].name == this.pricesToSend[j].product) {
            this.pricesToSend[j].product = this.products[b];
            break;
          }
        }
      }

      // Check PriceCalcUnit
      if (!Utils.isObject(this.pricesToSend[j].priceCalcUnit)) {
        for (let q = 0; q < this.priceCalcUnits.length; q++) {
          if (this.priceCalcUnits[q].name == this.pricesToSend[j].priceCalcUnit) {
            this.pricesToSend[j].priceCalcUnit = this.priceCalcUnits[q];
            break;
          }
        }
      }

      const wPrice: PricesheetPrice = {
        id: this.pricesToSend[j].id,
        collitypeRef: this.deserializeColliType(this.pricesToSend[j].collitypeRef),
        licenseRef: this.selectedPricesheet?.licenseRef,
        zoneNo: this.pricesToSend[j].zoneNo?.toString(),
        intervalFrom: this.pricesToSend[j].intervalFrom,
        intervalTo: this.pricesToSend[j].intervalTo,
        price: this.pricesToSend[j].price,
        hidePrint: false, // Hardcoded to false as it is required in backend
        validFrom: this.selectedPricesheet!.validFrom!,
        fk_pricesheetTypeId: 1, // hardcoded 1 so far
        fk_pricesheetId: this.selectedPricesheet?.id,
        fk_currencyId: this.pricesToSend[j].currency?.id!,
        fk_measureUnitId: this.pricesToSend[j].measureUnit?.id!,
        fk_pricecalcUnitId: this.pricesToSend[j].priceCalcUnit != null ? this.pricesToSend[j].priceCalcUnit?.id! : undefined,
        fk_productId: this.pricesToSend[j].product?.id!,
        product: this.pricesToSend[j].product,
        priceCalcUnit: this.pricesToSend[j].priceCalcUnit,
        priceCode: this.pricesToSend[j].priceCode,
        text: this.pricesToSend[j].text?.toString()
      };
      tmpPriceArray.push(wPrice);
    }
    return tmpPriceArray;
  }

  deserializeColliType(colliRef: any) {
    if (this.colliTypes) {
      for (let i = 0; i < this.colliTypes.length; i++) {
        if (colliRef == this.colliTypes[i].name) {
          return this.colliTypes[i].ref;
        }
      }
    }
  }


  dataSourceChangedPrice(event: any): void {
    if (event.action == "edit") {
      if (event.data) {
        for (let i = 0; i < event.data!.length; i++) {
          for (let j = 0; j < this.prices.length; j++) {
            if (this.prices[j].id == event.data[i].id) {
              this.prices[j] = event.data[i] as PricesheetPrice;
            }
          }
        }
      }
    }

    if (event.action == "dataSourceChanged") {
      this.setPriceHeaderRow();
      this.setPriceDataValidation();
    }

  }

  getMeasureUnits() { // this
    var licenseRef = this.data.License;
    this.measureUnitService.getMeasureUnits(licenseRef).subscribe((res: any) => {
      this.measureUnits = res.modelObject;
    });
  }

  getAllPriceCalcUnit() {
    this.loading = true;
    let priceCalcUnit: PriceCalcUnit = {
      licenseRef: this.data.License,
      name: " "
    };
    this.priceCalcUnitService.getAll(priceCalcUnit).then((res) => {
      this.priceCalcUnits = res.modelObject;

    }).finally(() => this.loading = false);
  }



  getColliTypes() {
    this.colliTypeService.getAllColliTypes(this.collitypeRequest).then((res: any) => {
      if (res && res.modelObject) {
        this.colliTypes = res.modelObject;
        this.colliTypes!.sort((a: any, b: any) => (a.name > b.name) ? 1 : (a.name == b.name) ? 0 : -1);
      }
    }).catch((err) => {
      console.error("ERROR Getting Colli Types: ", err);
    });

  }

  getTerminals() {
    let terminal: Terminal = {
      licenseRef: this.data.License
    };
    this.terminalService.getAll(terminal).then((res) => {
      this.terminals = res.modelObject;
    })
  }

  displayFn(colli: ColliType) {
    if (colli) { return colli.name; }
    return "";
  }

  setPriceDataValidation() {
    if (this.priceCalcUnits) {
      this.spreadsheetObjPrice.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=priceCalcUnit!C2:C" + (this.priceCalcUnits.length + 1) }, 'H2:H' + (this.prices.length + 2));
    }
    if (this.measureUnits) {
      this.spreadsheetObjPrice.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=measureUnit!C2:C" + (this.measureUnits.length + 1) }, 'G2:G' + (this.prices.length + 2));
    }
    if (this.currencies) {
      this.spreadsheetObjPrice.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=currencies!D2:D" + (this.currencies.length + 1) }, 'J2:J' + (this.prices.length + 2));
    }
    if (this.colliTypes) {
      this.spreadsheetObjPrice.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=colliTypes!C2:C" + (this.colliTypes.length + 1) }, 'C2:C' + (this.prices.length + 2));
    }
    if (this.products) {
      this.spreadsheetObjPrice.addDataValidation({ type: 'List', inCellDropDown: true, value1: "=products!B2:B" + (this.products.length + 1) }, 'B2:B' + (this.prices.length + 2));
    }
  }

  setPriceHeaderRow() {
    this.spreadsheetObjPrice.cellFormat({ fontWeight: 'bold', fontSize: '14px' }, 'B1:L1');
    this.spreadsheetObjPrice.updateCell({ index: 1, value: this.translate.instant('PRODUCTS') }, 'B1');
    this.spreadsheetObjPrice.updateCell({ index: 1, value: this.translate.instant('COLLITYPE') }, 'C1');
    this.spreadsheetObjPrice.updateCell({ index: 1, value: this.translate.instant('CURRENCY') }, 'J1');
    this.spreadsheetObjPrice.updateCell({ index: 1, value: this.translate.instant('INTERVALFROM') }, 'E1');
    this.spreadsheetObjPrice.updateCell({ index: 1, value: this.translate.instant('INTERVALTO') }, 'F1');
    this.spreadsheetObjPrice.updateCell({ index: 1, value: this.translate.instant('MEASUREUNIT') }, 'G1');
    this.spreadsheetObjPrice.updateCell({ index: 1, value: this.translate.instant('PRICECALCUNIT') }, 'H1');
    this.spreadsheetObjPrice.updateCell({ index: 1, value: this.translate.instant('PRICE') }, 'I1');
    this.spreadsheetObjPrice.updateCell({ index: 1, value: this.translate.instant('PRICECODE') }, 'D1');
    this.spreadsheetObjPrice.updateCell({ index: 1, value: this.translate.instant('ZONENO') }, 'K1');
    this.spreadsheetObjPrice.updateCell({ index: 1, value: this.translate.instant('TEXT') }, 'L1');
    this.spreadsheetObjPrice.cellFormat({ textAlign: 'center' }, 'H1:H' + (this.prices.length + 1));
  }

  getPrices() {
    this.loading = true;
    if (this.selectedPricesheet) {
      let pricesheetprice: PricesheetPrice = {
        licenseRef: this.selectedPricesheet.licenseRef!,
        includeCurrency: true,
        includeMeasureunit: true
      }
      this.pricesheetpriceService.getPricesheetPricesView(this.selectedPricesheet.id!, pricesheetprice).then((res: any) => {
        for (let i = 0; i < res.modelObject.length; i++) {
          var colliNames = this.colliTypes.filter(function (element: any) {
            return element.ref == res.modelObject[i].collitypeRef;
          });
          if (colliNames.length > 0) {
            res.modelObject[i].collitypeRef = colliNames[0].name;
          }
          else {
            res.modelObject[i].collitypeRef = null;
          }
          res.modelObject[i].currency = this.checkCurrency(res.modelObject[i].currency);
          res.modelObject[i].measureUnit = this.checkMeasureUnit(res.modelObject[i].measureUnit);
          res.modelObject[i].product = this.checkProduct(res.modelObject[i].product);
          res.modelObject[i].priceCalcUnit = this.checkPriceCalcUnit(res.modelObject[i].priceCalcUnit);

          this.copyPrices(res.modelObject);
        }
        if (res.modelObject.length == 0) {
          this.prices = [];
        }

      }).finally(() => this.loading = false);
    }
  }

  checkProduct(prod: any): any {
    if (prod) {
      for (let i = 0; i < this.products.length; i++) {
        if (prod.id == this.products[i].id) {
          return this.products[i].name;
        }
      }
    }
  }

  checkPriceCalcUnit(pcu: any): any {
    if (pcu) {
      for (let i = 0; i < this.priceCalcUnits.length; i++) {
        if (pcu.name == this.priceCalcUnits[i].name) {
          return this.priceCalcUnits[i].name;
        }
      }
    }
  }

  copyPrices(prices: PricesheetPrice[]) {
    const tmpArray = [];
    for (let i = 0; i < prices.length; i++) {
      const price = {
        id: prices[i].id,
        product: prices[i].product,
        collitypeRef: prices[i].collitypeRef,
        priceCode: prices[i].priceCode,
        intervalFrom: prices[i].intervalFrom,
        intervalTo: prices[i].intervalTo,
        measureUnit: prices[i].measureUnit,
        priceCalcUnit: prices[i].priceCalcUnit,
        price: prices[i].price,
        currency: prices[i].currency,
        zoneNo: prices[i].zoneNo,
        text: prices[i].text
      } as PricesheetPriceViewModel;
      tmpArray.push(price);
    }
    this.prices = tmpArray;
    this.maxRowPrice = (this.prices.length + 2);
  }

  checkMeasureUnit(mes: any) {
    for (let i = 0; i < this.measureUnits.length; i++) {
      if (mes.name == this.measureUnits[i].name) {
        return this.measureUnits[i].name;
      }
    }
  }
  createdPrice() {
    this.setPriceHeaderRow();
  }

  createNewStandardPricesheet() {
    const dialogRef = this.dialog.open(StandardPricesheetDialog);

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        let pricesheet: Pricesheet = {
          name: res.name,
          validFrom: res.date.toString(),
          priority: 0,
          fk_pricesheetTypeId: this.type.id,
          licenseRef: this.data.License
        }
        this.pricesheetService.savePricesheet(pricesheet).then((res: any) => {
          if (res) {
            this.getDefaultPricesheets();
          }
        });
      }
    });
  }

  getDefaultPricesheetType() {
    let priceSheetType: PricesheetType = {
      licenseRef: this.data.License,
      name: ''
    }
    this.pricesheetTypeService.getAll(priceSheetType).then((res) => {
      for (let i = 0; i < res.modelObject.length; i++) {
        if (res.modelObject[i].name && res.modelObject[i].name == "Default") {
          this.type = res.modelObject[i];
        }
      }
    });
  }

  deleteStandardPricesheet(pricesheet: Pricesheet) {
    if (pricesheet.customerPricesheets!.length <= 0) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: "15%",
        height: "16%",
        data: {
          title: "Er du sikker?", // Translate
          message: "Du er ved at slette : <b>" + pricesheet.name + "</b>" // Translate
        }
      });
      dialogRef.afterClosed().subscribe((dialogData) => {
        if (dialogData) {
          if (pricesheet) {
            this.pricesheetService.delete(pricesheet.id!, pricesheet.licenseRef).then((res) => {
              this.getDefaultPricesheets();
              this.selectedPricesheet = undefined;
            });
          }
        }
      });
    }
    else {
      // Standard Pricesheet has Customers - Not Allowed to delete!
      this._snackBar.open("Arket er knyttet til en kunde", 'Kan ikke slettes', { duration: 2000, panelClass: ['snack-bar'] }); // Translate
    }
  }

  getAllCurrencies() {
    this.currencyRequest.licenseRef = this.data.License;
    this.currencyService.getCurrencies(this.currencyRequest).then((res) => {
      this.currencies = res.modelObject;
      this.currencies.sort((a: any, b: any) => (a.isocode > b.isocode) ? 1 : (a.isocode == b.isocode) ? 0 : -1);
    });
  }

  getAllProducts() {
    const product: Product = {
      name: "",
      erpCode: "",
      licenseRef: this.data.License,
      fk_productUnitID: 0,
      includeProductPrices: true,
      includeProductUnit: true
    };
    this.productService.getAll(product).then((res: any) => {
      for (let i = 0; i < res.modelObject.length; i++) {
        this.products.push({
          id: res.modelObject[i].id,
          name: res.modelObject[i].name,
          erpCode: res.modelObject[i].erpCode
        });
      }
    });
  }

  checkCurrency(curr: any): any {
    for (let i = 0; i < this.currencies.length; i++) {
      if (curr.isocode == this.currencies[i].isocode) {
        return this.currencies[i].isocode;
      }
    }
  }

}
