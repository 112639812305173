import { Injectable } from "@angular/core";
import { Currency } from "../Models/currency";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root'
})
export class CurrencyService {

    constructor(private api: ApiService) { }

    async getCurrencies(currency: Currency) {
        return await this.api.post("/currency/getall", currency).toPromise();
    }

}