 <div fxLayout="row" style="max-width:100%; margin:1%;">
    <div style="display:flex; justify-content: space-between; margin: 1%; align-items: center;">
        <h2 style="margin:0px;" class="header">{{'COLLITYPEMEASUREMENTS'|translate}}</h2>
        <button mat-raised-button *ngIf="!selectedCustomer" style="background-color: var(--primary-blue); color:white; border-radius: 10px;" (click)="newColliMeasurement()">Ny Godstype Mål</button>
        <button mat-raised-button (click)="saveAll()" class="stdBtn">{{'SAVEALL' | translate}}</button>
    </div>

    <div style="border-radius: 6px;" class="mat-elevation-z10 table-responsive">
        <table style="width:100%;" mat-table [dataSource]="collitypeMeasurementsDataSource">
            <!-- COLLI TYPE-->
            <ng-container matColumnDef="collitype">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'COLLITYPE'|translate}}
                </th>
                <td class="centText" mat-cell *matCellDef="let colms">
                    <mat-form-field style="width:150;" floatLabel='never'>
                        <mat-label>{{'COLLITYPE' | translate}}</mat-label>
                        <mat-select (ngModelChange)="checkData(colms)" [(ngModel)]="colms.colliType" name="collitype"
                            [compareWith]="compareByOptionId">
                            <mat-option *ngFor="let ct of colliTypes" [value]="ct">
                                {{ct.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>
            <!-- HEIGHT -->
            <ng-container matColumnDef="height">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'HEIGHT'|translate}}</th>
                <td class="centText" mat-cell *matCellDef="let colms">
                    <input placeholder="Højde" type="text" matInput (ngModelChange)="checkData(colms)"
                        [(ngModel)]="colms.height">
                </td>
            </ng-container>
            <!-- LENGTH -->
            <ng-container matColumnDef="length">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'LENGTH'|translate}}</th>
                <td class="centText" mat-cell *matCellDef="let colms">
                    <input placeholder="Længde" type="text" matInput (ngModelChange)="checkData(colms)"
                        [(ngModel)]="colms.length">
                </td>
            </ng-container>
            <!-- WIDTH -->
            <ng-container matColumnDef="width">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'WIDTH'|translate}}</th>
                <td class="centText" mat-cell *matCellDef="let colms">
                    <input placeholder="Bredde" type="text" matInput (ngModelChange)="checkData(colms)"
                        [(ngModel)]="colms.width">
                </td>
            </ng-container>
            <!-- WEIGHT -->
            <ng-container matColumnDef="weight">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'WEIGHT'|translate}}</th>
                <td class="centText" mat-cell *matCellDef="let colms">
                    <input placeholder="Vægt" type="text" matInput (ngModelChange)="checkData(colms)"
                        [(ngModel)]="colms.weight">
                </td>
            </ng-container>
            <!-- VOLUME -->
            <ng-container matColumnDef="volume">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'VOLUME'|translate}}</th>
                <td class="centText" mat-cell *matCellDef="let colms">
                    <input placeholder="Volume" type="text" matInput (ngModelChange)="checkData(colms)"
                        [(ngModel)]="colms.volume">
                </td>
            </ng-container>
            <!-- LDM -->
            <ng-container matColumnDef="ldm">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'LDM'|translate}}</th>
                <td class="centText" mat-cell *matCellDef="let colms">
                    <input placeholder="ldm" type="text" matInput (ngModelChange)="checkData(colms)"
                        [(ngModel)]="colms.ldm">
                </td>
            </ng-container>
            <!-- PRICECODE -->
            <ng-container matColumnDef="priceCode">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'PRICECODE'|translate}}
                </th>
                <td class="centText" mat-cell *matCellDef="let colms">
                    <input placeholder="Pris Kode" type="text" matInput (ngModelChange)="checkData(colms)"
                        [(ngModel)]="colms.priceCode">
                </td>
            </ng-container>
            <!-- CUSTOMER -->
            <ng-container matColumnDef="customer">
                <th class="centText" mat-header-cell *matHeaderCellDef>{{'CUSTOMER'|translate}}
                </th>
                <td class="centText" mat-cell *matCellDef="let colms">
                    <mat-form-field style="width:150;" floatLabel='never'>
                        <mat-label>Kunde</mat-label>
                        <mat-select [disabled]="selectedCustomer != null" (ngModelChange)="checkData(colms)"
                            [(ngModel)]="colms.customerRef" name="customer" [compareWith]="compareByOptionRef">
                            <mat-option *ngFor="let cust of customers" [value]="cust.customerexternRef">
                                {{cust.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>
            <!-- SPRING COllITYPE REF-->
            <ng-container matColumnDef="springCollitypeRef">
                <th class="centText" mat-header-cell *matHeaderCellDef>
                    {{'SPRINGCOLLITYPEREF'|translate}}
                </th>
                <td class="centText" mat-cell *matCellDef="let colms">
                    <mat-form-field style="width:150;" floatLabel='never'>
                        <mat-label>{{'GOTOCOLLITYPE' | translate}}</mat-label>
                        <mat-select (ngModelChange)="checkData(colms)" [(ngModel)]="colms.springCollitypeRef"
                            name="collitype" [compareWith]="compareByOptionRef">
                            <mat-option *ngFor="let ct of colliTypes" [value]="ct.ref">
                                {{ct.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>

            <!-- DELETE/ACTION ↓ -->
            <ng-container matColumnDef="action">
                <th class="centText" mat-header-cell *matHeaderCellDef></th>
                <td class="centText" mat-cell *matCellDef="let colms">
                    <div *ngIf="!colms.id || colms.editable">
                        <button matTooltip="Gem Gods mål"
                            style="border-radius: 10px;background-color: var(--primary-blue); color:white;"
                            mat-raised-button (click)="updateColliTypeMeasurement(colms)">
                            <mat-icon>save</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="colms.id && !colms.editable">
                        <button matTooltip="Slet gods mål" style="border-radius: 10px;" mat-raised-button color="warn"
                            (click)="deleteColliTypeMeasurement(colms)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>
            <tr style="background-color:var(--primary-blue);" mat-header-row
                *matHeaderRowDef="displayedColumns; sticky:true;">
            </tr>
            <tr class="table-row" mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 15, 25]" showFirstLastButtons aria-label="Vælg Side"></mat-paginator>
    </div>
</div>

<app-mat-spinner-overlay *ngIf="loading === true" [overlay]="true">
</app-mat-spinner-overlay>