<div fxLayout="column">
  <div fxLayoutGap="1px" fxLayout="row" style="margin-left:1%; margin-top:1%; display:flex; align-items: center; overflow-x: auto;">
    <button matTooltip="Opret Nyt Standard Prisark" mat-mini-fab
      style="background-color: var(--primary-blue); color:white; margin-right:4px; border-radius: 10px;"
      (click)="createNewStandardPricesheet()">
      <mat-icon>add</mat-icon>
    </button>
    <div *ngFor="let pricesheet of pricesheets; let i = index;">
      <button [ngClass]="{'active': selectedPricesheet == pricesheet}" 
        style="background-color: var(--primary-blue); color:white; margin-right:4px;" mat-raised-button
        (click)="selectPriceSheet(pricesheet)">
        {{pricesheet.name}}
      </button>
    </div>
  </div>
</div>
<div style="display:flex; align-items: center; width:65%; justify-content: space-between;" *ngIf="selectedPricesheet">
  <button style="margin:14px; margin-bottom:-45px;" mat-raised-button color="warn"
    (click)="deleteStandardPricesheet(selectedPricesheet)">{{'DELETE' | translate}} {{selectedPricesheet.name}}</button>
  <button style="margin:14px; margin-bottom:-45px; background-color: var(--primary-blue); color:white;"
    mat-raised-button (click)="savePricesheet()">{{'SAVEPRICESHEET' | translate}}</button>
</div>
<div *ngIf="selectedPricesheet" class="customerPricesheets">
  <div class="pricesheets">
    <!-- ZONES ↓ -->
    <div>
      <div
        style="display:flex; margin-top:1%; flex-direction: row; justify-content: center; width:50%; margin-bottom:-15px;">
        <h2 class="header">{{'ZONES'|translate}} </h2>
      </div>
      <div style="width:68% !important; margin:1%;">
        <!-- Ignore errors Below-->
        <ejs-spreadsheet allowNumberFormatting='false' allowCellFormatting='true' (created)="createdZone()"
          (cellEdit)="cellEditZone($event)" (dataSourceChanged)='dataSourceChangedZone($event)' #spreadsheetZone
          [showFormulaBar]='false' [showRibbon]='false' [showSheetTabs]='false'>
          <e-sheets>
            <e-sheet name="Zoner">
              <e-ranges>
                <e-range [dataSource]='zones'>
                </e-range>
              </e-ranges>
              <e-rows>
                <e-row>
                  <e-cells>
                  </e-cells>
                </e-row>
              </e-rows>
              <e-columns>
                <e-column [width]=1></e-column> <!-- ID -->
                <e-column [width]=100></e-column> <!-- COUNTRYFROM -->
                <e-column [width]=100></e-column> <!-- FROMCOUNTRYCODE -->
                <e-column [width]=100></e-column> <!-- TOCOUNTRYCODE -->
                <e-column [width]=100></e-column> <!-- COUNTRYTO -->
                <e-column [width]=100></e-column> <!-- FROMZONE -->
                <e-column [width]=100></e-column> <!-- TOZONE -->
                <e-column [width]=80></e-column> <!-- DRIVERTYPE -->
                <e-column [width]=140></e-column> <!-- TERMINAL -->
                <e-column [width]=110></e-column> 
              </e-columns>
            </e-sheet>
            <e-sheet name="CountryFrom" state="VeryHidden">
              <e-ranges>
                <e-range [dataSource]='countries'></e-range>
              </e-ranges>
            </e-sheet>
            <e-sheet name="HandlingType" state="VeryHidden">
              <e-ranges>
                <e-range [dataSource]='handlingTypes'></e-range>
              </e-ranges>
            </e-sheet>
            <e-sheet name="Terminals" state="VeryHidden">
              <e-ranges>
                <e-range [dataSource]='terminals'></e-range>
              </e-ranges>
            </e-sheet>
          </e-sheets>
        </ejs-spreadsheet>
      </div>
    </div>
    <!-- PRICES ↓ -->
    <div
      style="display:flex; margin-top:1%; flex-direction: row; justify-content: center; width:50%; margin-bottom:-15px;">
      <h2 class="header">{{'PRICES'|translate}}</h2>
    </div>
    <div style="width:68% !important; margin:1%;">
      <!-- Ignore errors Below-->
      <ejs-spreadsheet (dataSourceChanged)='dataSourceChangedPrice($event)' (created)="createdPrice()"
        (cellEdit)="cellEditPrice($event)" #spreadsheetPrice [showFormulaBar]='false' [showRibbon]='false'
        [showSheetTabs]="false" [allowNumberFormatting]="false" [allowConditionalFormat]="false">
        <e-sheets>
          <e-sheet name="Priser">
            <e-ranges>
              <e-range [dataSource]="prices">
              </e-range>
            </e-ranges>
            <e-columns>
              <e-column [width]=1></e-column> <!-- ID -->
              <e-column [width]=120></e-column> <!-- Product -->
              <e-column [width]=120></e-column> <!-- Colli Type -->
              <e-column [width]=60></e-column> <!-- Currency -->
              <e-column [width]=80></e-column> <!-- IntervalFrom -->
              <e-column [width]=80></e-column> <!-- IntervalTo -->
              <e-column [width]=120></e-column> <!-- MeasureUnit -->
              <e-column [width]=110></e-column> <!-- PriceCalcUnit -->
              <e-column [width]=80></e-column> <!-- Price -->
              <e-column [width]=80></e-column> <!-- PriceCode -->
              <e-column [width]=50></e-column> <!-- ZoneNo -->
              <e-column [width]=120></e-column> <!-- Text -->
            </e-columns>
          </e-sheet>
          <e-sheet name="currencies" state="VeryHidden">
            <e-ranges>
              <e-range [dataSource]="currencies"></e-range>
            </e-ranges>
          </e-sheet>
          <e-sheet name="colliTypes" state="VeryHidden">
            <e-ranges>
              <e-range [dataSource]="colliTypes"></e-range>
            </e-ranges>
          </e-sheet>
          <e-sheet name="measureUnit" state="VeryHidden">
            <e-ranges>
              <e-range [dataSource]="measureUnits">
              </e-range>
            </e-ranges>
          </e-sheet>
          <e-sheet name="products" state="VeryHidden">
            <e-ranges>
              <e-range [dataSource]='products'></e-range>
            </e-ranges>
          </e-sheet>
          <e-sheet name="priceCalcUnit" state="VeryHidden">
            <e-ranges>
              <e-range [dataSource]='priceCalcUnits'></e-range>
            </e-ranges>
          </e-sheet>
        </e-sheets>
      </ejs-spreadsheet>
    </div>
  </div>
</div>

<!-- Ignore error Below ↓ -->
<app-mat-spinner-overlay *ngIf="loading === true" [overlay]="true">
</app-mat-spinner-overlay>