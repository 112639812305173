<div class="customersInfo">
  <div class="customers" style="width:73vw;">
    <div class="buttonGrid">
      <mat-form-field style="width:23vw; margin-top:1%;" appearance="outline">
        <mat-label>{{'CHOOSECUSTOMER' | translate}}</mat-label>
        <input style="font-weight: 400;" type="text" autofocus aria-label="Kunde" matInput [formControl]="customControl"
          [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option disabled *ngIf="customerLoading">
            <mat-spinner style="align-content: center; justify-content: center; margin-left:50px;" diameter="45"></mat-spinner>
          </mat-option>
          <mat-option *ngFor="let option of filteredOptions | async" [value]="(option.clientObject!.number + ' | ' + option.clientObject?.name)"
            (click)="selectCustomer(option)">
            {{option.clientObject?.number}} | <span>{{option.clientObject?.name}}</span> |
            <small>{{option.clientObject?.address}} {{option.clientObject?.zip}} {{option.clientObject?.city}}
              {{option.clientObject?.country}} </small>
          </mat-option>
        </mat-autocomplete>
        <mat-icon *ngIf="!selectedCustomer" class="primaryBlue" matSuffix>account_circle</mat-icon>
        <mat-icon *ngIf="selectedCustomer" (click)="resetCustomer()" matTooltip="Ryd kunde" class="primaryBlue"
          matSuffix><i class="far fa-times-circle"></i></mat-icon>
      </mat-form-field>
      <div class="customerPricesheetActions" *ngIf="selectedCustomer">
        <mat-form-field style="width:15vw;" appearance="outline">
          <mat-label>{{'CHOOSEPRICESHEET' | translate}}</mat-label>
          <input style="font-weight: 400;" type="text" autofocus aria-label="Prisark" matInput
            [formControl]="pricesheetControl" [matAutocomplete]="auto">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let pricesheet of data.pricesheets" [value]="pricesheet.name"
              (click)="selectPriceSheet(pricesheet)">
              {{pricesheet.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matTooltip="Redigér Prisark" class="primaryBlue"
            [ngClass]="{'pointer': this.data.selectedPricesheet != null}" (click)="editPricesheet($event)" matSuffix>
            description</mat-icon>
        </mat-form-field>
        <button matTooltip="Nyt Prisark" mat-mini-fab class="stdButton" (click)="newPricesheet()">
          <mat-icon>add</mat-icon>
        </button>
        <button *ngIf="data.selectedPricesheet" matTooltip="Gem valgte Prisark" mat-mini-fab class="stdButton"
          (click)="savePricesheet()">
          <mat-icon>save</mat-icon>
        </button>
        <button *ngIf="data.selectedPricesheet" matTooltip="Opret en Kopi af Valgte Prisark" mat-mini-fab
          class="stdButton" (click)="copyPricesheet()">
          <mat-icon>file_copy</mat-icon>
        </button>
        <button *ngIf="data.selectedPricesheet" matTooltip="Slet Valgte Prisark" mat-mini-fab color="warn"
          style="color:white; border-radius: 10px;" (click)="deletePricesheet(data.selectedPricesheet!)">
          <mat-icon>delete</mat-icon>
        </button>
        <button class="stdButton" mat-raised-button (click)="openCustomerSettings()">{{'SETUP' | translate}}</button>
      </div>
    </div>

    <div class="dateFlex">
      <mat-form-field *ngIf="data.selectedPricesheet" style="width:120px; margin:4px;">
        <mat-label>{{'CHOOSEDATE' | translate}}</mat-label>
        <mat-select [value]="data.selectedDate" style="font-weight:450;">
          <mat-option *ngFor="let zoneDate of data.ZoneDates" [value]="zoneDate" (click)="chooseDate(zoneDate)">{{
            zoneDate |
            date: 'dd/MM/yyyy'}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button *ngIf="data.selectedPricesheet" class="stdButton" mat-raised-button (click)="AddNewDate()">{{'NEWDATE' |
        translate}}</button>
    </div>
    <div *ngIf="selectedCustomer">
      <app-pricesheet [customer]="selectedCustomer"></app-pricesheet>
    </div>
  </div>
  <div *ngIf="selectedCustomer" class="mat-elevation-z5 customerRules" style="overflow-x:hidden;">
    <mat-tab-group mat-stretch-tabs style="max-width:40vw; width:40vw;">
      <mat-tab [label]="'PRODUCTS' | translate">
        <div class="mat-elevation-z1 tab">
          <app-products style="min-height:300px;">
          </app-products>
        </div>
      </mat-tab>
      <mat-tab [label]="'ADDITIONS' | translate">
        <div class="mat-elevation-z1 tab">
          <app-additions style="width:65%;" [customer]="selectedCustomer"></app-additions>
        </div>
      </mat-tab>
      <mat-tab *ngIf="data.selectedPricesheet && data.selectedPricesheet.fk_pricesheetTypeId != 1"
        [label]="'RETURNCALC' | translate">
        <div class="mat-elevation-z1">
          <app-price-calc [customer]="selectedCustomer"></app-price-calc>
        </div>
      </mat-tab>
      <mat-tab *ngIf="data.selectedPricesheet" [label]="'RECALCRULES' | translate">
        <div class="mat-elevation-z4">
          <app-customer-rules [customer]="selectedCustomer" [pricesheet]="data.selectedPricesheet"></app-customer-rules>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<app-mat-spinner-overlay *ngIf="loading === true" [overlay]="true">
</app-mat-spinner-overlay>